@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,700;1,400&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* FONT
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #000;
    background: #FFF;
    font-size: 15.5px;
    line-height: 1.8;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #b33e23; }
    a:hover {
      text-decoration: none;
      color: #d75132; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: 1120px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #000;
    background: #FFF;
    font-size: 13px;
    line-height: 1.8;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #b33e23; }
    a:hover {
      text-decoration: none;
      color: #d75132; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#HEADER {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  padding: 0 30px;
  z-index: 100; }
  @media screen and (max-width: 768px) {
    #HEADER {
      height: 60px;
      padding: 0 12px; } }
  #HEADER .logo {
    float: left;
    margin: 15px 0 0 24px; }
    @media screen and (max-width: 768px) {
      #HEADER .logo {
        margin: 12px 0 0; } }
    #HEADER .logo img {
      width: 266px;
      height: auto; }
  #HEADER .fullscreenmenu .menu, #HEADER .fullscreenmenu .menu span {
    display: inline-block;
    -webkit-transition: all 400ms 0ms ease-in-out;
    -moz-transition: all 400ms 0ms ease-in-out;
    -ms-transition: all 400ms 0ms ease-in-out;
    -o-transition: all 400ms 0ms ease-in-out;
    transition: all 400ms 0ms ease-in-out;
    box-sizing: border-box; }
  #HEADER .fullscreenmenu .menu {
    position: fixed;
    top: 20px;
    right: 30px;
    width: 37px;
    height: 22px;
    z-index: 10;
    background: none;
    border: none;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      #HEADER .fullscreenmenu .menu {
        width: 28px;
        right: 15px; } }
    #HEADER .fullscreenmenu .menu span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background: #FFF;
      z-index: 10; }
      #HEADER .fullscreenmenu .menu span:nth-of-type(1) {
        top: 0px; }
      #HEADER .fullscreenmenu .menu span:nth-child(2) {
        top: 10px; }
      #HEADER .fullscreenmenu .menu span:nth-child(3) {
        bottom: 0px; }
    #HEADER .fullscreenmenu .menu.active span:nth-of-type(1) {
      transform: translateY(10px) rotate(45deg); }
    #HEADER .fullscreenmenu .menu.active span:nth-child(2) {
      opacity: 0; }
    #HEADER .fullscreenmenu .menu.active span:nth-child(3) {
      transform: translateY(-10px) rotate(-45deg); }
  #HEADER .fullscreenmenu .gnavi {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    width: 500px;
    height: 100vh;
    transform: translateX(100%);
    background: url("../../../images/common/back_01.png") repeat center rgba(13, 59, 85, 0.98);
    -webkit-transition: all 400ms 0ms ease-in-out;
    -moz-transition: all 400ms 0ms ease-in-out;
    -ms-transition: all 400ms 0ms ease-in-out;
    -o-transition: all 400ms 0ms ease-in-out;
    transition: all 400ms 0ms ease-in-out;
    visibility: hidden;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      #HEADER .fullscreenmenu .gnavi {
        width: 100%; } }
    #HEADER .fullscreenmenu .gnavi.active {
      right: 0;
      opacity: 1;
      transform: translateX(0);
      visibility: visible; }
    #HEADER .fullscreenmenu .gnavi ul {
      padding: 15% 0; }
      #HEADER .fullscreenmenu .gnavi ul > li {
        list-style: none; }
        #HEADER .fullscreenmenu .gnavi ul > li a {
          display: block;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out;
          text-align: center;
          text-decoration: none;
          color: #FFF;
          font-size: 25px;
          margin: 25px 0; }
          @media screen and (max-width: 768px) {
            #HEADER .fullscreenmenu .gnavi ul > li a {
              font-size: 18px;
              margin: 22px 0;
              white-space: nowrap; } }
          #HEADER .fullscreenmenu .gnavi ul > li a span {
            background: url("../../../images/common/icon_sasa_w.png") no-repeat left center;
            background-size: 38px 24px;
            padding-left: 50px;
            display: inline-block;
            text-align: left; }
            @media screen and (max-width: 768px) {
              #HEADER .fullscreenmenu .gnavi ul > li a span {
                width: auto;
                min-width: 50%;
                max-width: 100%; } }
          #HEADER .fullscreenmenu .gnavi ul > li a:hover span {
            background: url("../../../images/common/icon_sasa.png") no-repeat left center;
            background-size: 38px 24px; }
  #HEADER.lp .logo img {
    width: 210px;
    height: auto; }
  #HEADER.lp .fullscreenmenu .menu span {
    background: #000; }
  #HEADER.lp .fullscreenmenu .gnavi {
    background: #3ec1d2; }

#FOOTER {
  position: relative;
  background: #0d3b55;
  color: #FFF;
  overflow: hidden;
  z-index: 3; }
  #FOOTER:after {
    content: "";
    background: url("../../../images/common/ft_logo.png") no-repeat;
    width: 362px;
    height: 370px;
    position: absolute;
    top: -25%;
    right: 10%; }
    @media screen and (max-width: 768px) {
      #FOOTER:after {
        width: 181px;
        height: 185px;
        background-size: cover;
        top: 0px;
        right: auto;
        left: -20px; } }
  @media screen and (max-width: 768px) {
    #FOOTER .display {
      position: static; } }
  #FOOTER .logo {
    position: absolute;
    top: 130px;
    left: 30px; }
    @media screen and (max-width: 768px) {
      #FOOTER .logo {
        top: 12px;
        left: auto;
        right: 12px; } }
    #FOOTER .logo img {
      width: 110px;
      height: auto; }
      @media screen and (max-width: 768px) {
        #FOOTER .logo img {
          width: 90px; } }
  #FOOTER ul.mushi {
    width: 215px;
    height: 240px;
    position: absolute;
    bottom: 20px;
    left: 0;
    transform-origin: bottom right;
    transform: rotate(0deg);
    animation: wind 3s cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite; }

@keyframes wind {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-16deg); } }
    @media screen and (max-width: 768px) {
      #FOOTER ul.mushi {
        width: 145px;
        height: 140px;
        bottom: 12px;
        z-index: 5; } }
    #FOOTER ul.mushi li {
      list-style: none;
      background: #FFF;
      border-radius: 50%;
      position: absolute; }
      #FOOTER ul.mushi li:nth-child(1) {
        animation: mushi1 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite; }

@keyframes mushi1 {
  0% {
    transform: scale(1) translateX(0) translateY(0) skewX(0) skewY(0); }
  50% {
    transform: scale(1) translateX(3px) translateY(3px) skewX(-3deg) skewY(-3deg); }
  100% {
    transform: scale(0.88) translateX(3px) translateY(3px) skewX(5deg) skewY(5deg); } }
      #FOOTER ul.mushi li:nth-child(2) {
        animation: mushi2 400ms cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite; }

@keyframes mushi2 {
  0% {
    transform: scale(1) translateX(0) translateY(0) skewX(0) skewY(0); }
  50% {
    transform: scale(1) translateX(4px) translateY(4px) skewX(-2deg) skewY(-2deg); }
  100% {
    transform: scale(0.88) translateX(4px) translateY(4px) skewX(6deg) skewY(6deg); } }
      #FOOTER ul.mushi li:nth-child(3) {
        animation: mushi3 600ms cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite; }

@keyframes mushi3 {
  0% {
    transform: scale(1) translateX(0) translateY(0) skewX(0) skewY(0); }
  50% {
    transform: scale(1) translateX(5px) translateY(5px) skewX(-1deg) skewY(-1deg); }
  100% {
    transform: scale(0.88) translateX(5px) translateY(5px) skewX(7deg) skewY(7deg); } }
      #FOOTER ul.mushi li:nth-child(4) {
        animation: mushi4 800ms cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite; }

@keyframes mushi4 {
  0% {
    transform: scale(1) translateX(0) translateY(0) skewX(0) skewY(0); }
  50% {
    transform: scale(1) translateX(6px) translateY(6px) skewX(0deg) skewY(0deg); }
  100% {
    transform: scale(0.88) translateX(6px) translateY(6px) skewX(8deg) skewY(8deg); } }
      #FOOTER ul.mushi li:nth-child(5) {
        animation: mushi5 1000ms cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite; }

@keyframes mushi5 {
  0% {
    transform: scale(1) translateX(0) translateY(0) skewX(0) skewY(0); }
  50% {
    transform: scale(1) translateX(7px) translateY(7px) skewX(1deg) skewY(1deg); }
  100% {
    transform: scale(0.88) translateX(7px) translateY(7px) skewX(9deg) skewY(9deg); } }
      #FOOTER ul.mushi li:nth-child(6) {
        animation: mushi6 1200ms cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite; }

@keyframes mushi6 {
  0% {
    transform: scale(1) translateX(0) translateY(0) skewX(0) skewY(0); }
  50% {
    transform: scale(1) translateX(8px) translateY(8px) skewX(2deg) skewY(2deg); }
  100% {
    transform: scale(0.88) translateX(8px) translateY(8px) skewX(10deg) skewY(10deg); } }
      #FOOTER ul.mushi li:nth-child(7) {
        animation: mushi7 1400ms cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite; }

@keyframes mushi7 {
  0% {
    transform: scale(1) translateX(0) translateY(0) skewX(0) skewY(0); }
  50% {
    transform: scale(1) translateX(9px) translateY(9px) skewX(3deg) skewY(3deg); }
  100% {
    transform: scale(0.88) translateX(9px) translateY(9px) skewX(11deg) skewY(11deg); } }
      #FOOTER ul.mushi li:nth-child(1) {
        width: 12px;
        height: 12px;
        right: 0;
        bottom: 0;
        background: #602a76; }
        @media screen and (max-width: 768px) {
          #FOOTER ul.mushi li:nth-child(1) {
            width: 6px;
            height: 6px; } }
      #FOOTER ul.mushi li:nth-child(2) {
        width: 19px;
        height: 19px;
        right: 15px;
        bottom: 2px;
        background: #003d64; }
        @media screen and (max-width: 768px) {
          #FOOTER ul.mushi li:nth-child(2) {
            width: 9px;
            height: 9px;
            right: 9px; } }
      #FOOTER ul.mushi li:nth-child(3) {
        width: 25px;
        height: 25px;
        right: 42px;
        bottom: 8px;
        background: #4894d2; }
        @media screen and (max-width: 768px) {
          #FOOTER ul.mushi li:nth-child(3) {
            width: 12px;
            height: 12px;
            right: 24px; } }
      #FOOTER ul.mushi li:nth-child(4) {
        width: 34px;
        height: 34px;
        right: 64px;
        bottom: 28px;
        background: #66b82a; }
        @media screen and (max-width: 768px) {
          #FOOTER ul.mushi li:nth-child(4) {
            width: 17px;
            height: 17px;
            right: 38px;
            bottom: 18px; } }
      #FOOTER ul.mushi li:nth-child(5) {
        width: 47px;
        height: 47px;
        right: 77px;
        bottom: 64px;
        background: #e3ed00; }
        @media screen and (max-width: 768px) {
          #FOOTER ul.mushi li:nth-child(5) {
            width: 23px;
            height: 23px;
            right: 50px;
            bottom: 38px; } }
      #FOOTER ul.mushi li:nth-child(6) {
        width: 61px;
        height: 61px;
        right: 91px;
        bottom: 113px;
        background: #dc6019; }
        @media screen and (max-width: 768px) {
          #FOOTER ul.mushi li:nth-child(6) {
            width: 30px;
            height: 30px;
            right: 64px;
            bottom: 68px; } }
      #FOOTER ul.mushi li:nth-child(7) {
        width: 82px;
        height: 82px;
        top: 0;
        left: 0;
        background: #d50424; }
        @media screen and (max-width: 768px) {
          #FOOTER ul.mushi li:nth-child(7) {
            width: 41px;
            height: 41px; } }
  #FOOTER .kaza {
    padding-bottom: 55px;
    position: absolute;
    bottom: 0;
    right: 30px;
    z-index: 91; }
    @media screen and (max-width: 768px) {
      #FOOTER .kaza {
        padding-bottom: 30px;
        right: 0; } }
    #FOOTER .kaza .kuruma {
      max-width: 125px;
      height: auto;
      animation: spin 3.5s linear infinite; }
      @media screen and (max-width: 768px) {
        #FOOTER .kaza .kuruma {
          max-width: 90px; } }
    #FOOTER .kaza:before {
      content: "";
      background: url("../../../images/common/illust_kazaguruma_b.png") no-repeat;
      background-size: 4px 84px;
      width: 4px;
      height: 84px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -3px; }
      @media screen and (max-width: 768px) {
        #FOOTER .kaza:before {
          height: 54px; } }
  #FOOTER dl {
    padding: 88px 0;
    width: 30%;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      #FOOTER dl {
        width: 100%;
        padding: 32px 12px; } }
    #FOOTER dl dt {
      font-size: 20px;
      font-weight: 700;
      padding: 0 0 18px; }
    #FOOTER dl dd {
      font-size: 12px; }
  #FOOTER .fnav {
    background: #082232;
    position: relative; }
    @media screen and (max-width: 768px) {
      #FOOTER .fnav {
        padding: 12px 12px 0; } }
    #FOOTER .fnav .piyo {
      width: 80px;
      height: 90px;
      position: absolute;
      top: -88px;
      left: 100%;
      z-index: 90;
      transform-origin: 50% bottom;
      transform: scale(0.5);
      animation: walk 12s linear infinite; }

@keyframes walk {
  0% {
    left: 100%; }
  49% {
    left: -80px;
    transform: rotateY(0deg) scale(0.5); }
  50% {
    transform: rotateY(180deg) scale(0.5); }
  51% {
    left: -80px; }
  99% {
    left: 100%;
    transform: rotateY(180deg) scale(0.5); }
  100% {
    transform: rotateY(0deg) scale(0.5); } }
      #FOOTER .fnav .piyo:before, #FOOTER .fnav .piyo:after {
        content: '';
        position: absolute;
        width: 13px;
        height: 38px;
        background: url(../../images/common/piyo_back.png) no-repeat;
        transform-origin: top center;
        z-index: 1; }
      #FOOTER .fnav .piyo:before {
        left: 44px;
        bottom: 0;
        animation: foot-a .2s linear infinite; }

@keyframes foot-a {
  0% {
    bottom: 2px;
    transform: rotate(10deg); }
  25% {
    bottom: 0;
    transform: rotate(0deg); }
  50% {
    bottom: 2px;
    transform: rotate(-10deg); }
  75% {
    bottom: 0;
    transform: rotate(0deg); }
  100% {
    bottom: 2px;
    transform: rotate(10deg); } }
      #FOOTER .fnav .piyo:after {
        left: 28px;
        bottom: 1px;
        animation: foot-b .2s linear infinite; }

@keyframes foot-b {
  0% {
    bottom: 1px;
    transform: rotate(-10deg); }
  25% {
    bottom: 3px;
    transform: rotate(0deg); }
  50% {
    bottom: 1px;
    transform: rotate(10deg); }
  75% {
    bottom: 3px;
    transform: rotate(0deg); }
  100% {
    bottom: 1px;
    transform: rotate(-10deg); } }
      #FOOTER .fnav .piyo .piyo-body {
        background: url(../../images/common/piyo_body.png) no-repeat;
        width: 80px;
        height: 76px;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        animation: body .1s linear alternate infinite; }

@keyframes body {
  0% {
    top: 0px; }
  100% {
    top: 4px; } }
    #FOOTER .fnav ul {
      padding: 16px 0;
      text-align: center; }
      @media screen and (max-width: 768px) {
        #FOOTER .fnav ul {
          background: #FFF;
          border-radius: 8px;
          padding: 0; } }
      #FOOTER .fnav ul > li {
        display: inline-block; }
        @media screen and (max-width: 768px) {
          #FOOTER .fnav ul > li {
            display: block;
            border-bottom: 1px solid #DDD; }
            #FOOTER .fnav ul > li:last-child {
              border-bottom: none; } }
        #FOOTER .fnav ul > li a {
          color: #FFF;
          font-size: 12px; }
          @media screen and (max-width: 768px) {
            #FOOTER .fnav ul > li a {
              color: #000;
              display: block;
              padding: 12px;
              text-align: center; } }
        #FOOTER .fnav ul > li:after {
          content: "/";
          display: inline-block;
          padding: 0 20px; }
          @media screen and (max-width: 768px) {
            #FOOTER .fnav ul > li:after {
              content: none; } }
        #FOOTER .fnav ul > li:last-child:after {
          content: none; }
    #FOOTER .fnav .copy {
      display: block;
      font-size: 9px;
      text-align: center;
      padding: 0 0 15px; }
      @media screen and (max-width: 768px) {
        #FOOTER .fnav .copy {
          padding: 12px 0; } }
  #FOOTER.lp {
    background: #1b1b1b;
    text-align: center;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
    #FOOTER.lp:after {
      content: none; }
    #FOOTER.lp .pageup {
      background: #313131;
      padding: 16px 0;
      text-align: center;
      color: #fff;
      display: block;
      -webkit-transition: all 400ms 0ms ease-in-out;
      -moz-transition: all 400ms 0ms ease-in-out;
      -ms-transition: all 400ms 0ms ease-in-out;
      -o-transition: all 400ms 0ms ease-in-out;
      transition: all 400ms 0ms ease-in-out; }
    #FOOTER.lp dl dt {
      color: #FFF;
      font-size: 18px;
      padding: 0; }
      #FOOTER.lp dl dt span {
        font-size: 14px; }
      #FOOTER.lp dl dt small {
        font-size: 12px; }
    #FOOTER.lp dl dd {
      padding: 12px 0; }
      #FOOTER.lp dl dd figure img {
        width: 56px;
        height: auto; }
    #FOOTER.lp .copy {
      display: block;
      font-size: 9px;
      padding: 0 0 52px; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg); } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.firstview {
  width: 100%;
  height: 100vh !important;
  background: #000;
  position: relative;
  z-index: 2; }
  .firstview:before {
    content: '';
    background: rgba(0, 0, 0, 0.64);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
  .firstview .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    .firstview .content:before {
      content: "";
      background: url("../../../images/index/firstView_left.png") no-repeat;
      width: 480px;
      height: 726px;
      position: absolute;
      bottom: 0;
      left: 0; }
      @media screen and (max-width: 768px) {
        .firstview .content:before {
          content: none; } }
    .firstview .content:after {
      content: "";
      background: url("../../../images/index/firstView_right.png") no-repeat;
      width: 531px;
      height: 735px;
      position: absolute;
      bottom: 0;
      right: 0; }
      @media screen and (max-width: 768px) {
        .firstview .content:after {
          content: none; } }
    .firstview .content .motion {
      max-width: 1200px;
      min-width: 800px;
      text-align: center;
      position: relative; }
      @media screen and (max-width: 768px) {
        .firstview .content .motion {
          max-width: 100%;
          min-width: 90%;
          width: 95%; } }
      .firstview .content .motion:before, .firstview .content .motion:after {
        content: "";
        background: url("../../../images/index/waku.png") no-repeat;
        width: 266px;
        height: 54px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0); }
      .firstview .content .motion:before {
        top: -60px; }
      .firstview .content .motion:after {
        bottom: -80px; }
      .firstview .content .motion h2 {
        font-size: 42px;
        font-weight: 700;
        line-height: 1.5;
        color: #FFF;
        padding: 45px 0 25px; }
        @media screen and (max-width: 768px) {
          .firstview .content .motion h2 {
            font-size: 28px;
            padding: 20px 0; } }
      .firstview .content .motion p {
        font-size: 21px;
        color: #FFF; }
        @media screen and (max-width: 768px) {
          .firstview .content .motion p {
            font-size: 18px; } }
      .firstview .content .motion .logo img {
        width: 80px;
        height: auto; }
      .firstview .content .motion .illust {
        position: absolute;
        bottom: -80px;
        right: 220px;
        z-index: 3; }
  .firstview #three {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .64; }
  .firstview .scroll {
    display: block;
    width: 64px;
    position: absolute;
    left: 50%;
    bottom: 40px;
    margin-left: -32px;
    color: #FFF;
    font-size: 10px;
    letter-spacing: 0.24em;
    text-align: center;
    /* SP style */ }
    @media screen and (max-width: 768px) {
      .firstview .scroll {
        font-size: 8px; } }
    .firstview .scroll:before, .firstview .scroll:after {
      content: '';
      display: block;
      margin: 0 auto; }
    .firstview .scroll:before {
      width: 23px;
      height: 44px;
      border-radius: 40px;
      border: #FFF 1px solid;
      margin-bottom: 12px;
      /* SP style */ }
      @media screen and (max-width: 768px) {
        .firstview .scroll:before {
          width: 20px;
          height: 34px;
          margin-bottom: 6px; } }
    .firstview .scroll:after {
      width: 1px;
      height: 48px;
      position: absolute;
      bottom: 48px;
      left: 49%;
      background: #FFF;
      transform: rotate(180deg);
      animation: scroll 1.6s ease 0s infinite; }

@keyframes scroll {
  0% {
    height: 1px;
    bottom: 96px; }
  40% {
    height: 48px;
    bottom: 48px; }
  80% {
    height: 0;
    bottom: 48px; }
  100% {
    height: 0;
    bottom: 48px; } }
  .firstview .hide {
    display: none; }

.INDEX.first, .INDEX.SERVICE.third {
  position: relative;
  background: url("../../images/common/back_01.png") repeat center #214975;
  width: 100%;
  height: 390vh;
  padding: 25vh 0;
  overflow: hidden;
  z-index: 1; }
  @media screen and (max-width: 768px) {
    .INDEX.first, .INDEX.SERVICE.third {
      background: none;
      height: 430vh; } }
  .INDEX.first:before, .INDEX.SERVICE.third:before {
    content: url("../../../images/index/illust_wa_02.png");
    position: absolute;
    bottom: 30vh;
    left: 0; }
    @media screen and (max-width: 768px) {
      .INDEX.first:before, .INDEX.SERVICE.third:before {
        content: none; } }
  .INDEX.first:after, .INDEX.SERVICE.third:after {
    content: url("../../../images/index/illust_wa_01.png");
    position: absolute;
    top: 30vh;
    right: 0; }
    @media screen and (max-width: 768px) {
      .INDEX.first:after, .INDEX.SERVICE.third:after {
        background-size: cover;
        width: 371px;
        height: 467px; } }
  .INDEX.first .bugfix-bg, .INDEX.SERVICE.third .bugfix-bg {
    color: rgba(255, 255, 255, 0.04);
    font-size: 200px;
    line-height: 1;
    position: fixed;
    top: 20%;
    left: 20px; }
    @media screen and (max-width: 768px) {
      .INDEX.first .bugfix-bg, .INDEX.SERVICE.third .bugfix-bg {
        font-size: 70px;
        width: 100%;
        height: 100%;
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        background: url("../../images/common/back_01.png") repeat center #214975;
        z-index: -1; } }
  .INDEX.first #index-circle, .INDEX.SERVICE.third #index-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .INDEX.first #index-circle, .INDEX.SERVICE.third #index-circle {
        display: none; } }
    .INDEX.first #index-circle .act, .INDEX.SERVICE.third #index-circle .act {
      opacity: 0;
      position: absolute;
      filter: blur(10px);
      transform: translateY(-50%);
      transition: opacity 800ms ease, filter 600ms ease; }
      .INDEX.first #index-circle .act.rp1, .INDEX.SERVICE.third #index-circle .act.rp1 {
        top: 0vh;
        left: 40px; }
      .INDEX.first #index-circle .act.rp2, .INDEX.SERVICE.third #index-circle .act.rp2 {
        top: 100vh;
        right: 40px; }
      .INDEX.first #index-circle .act.rp3, .INDEX.SERVICE.third #index-circle .act.rp3 {
        top: 200vh;
        left: 40px; }
      .INDEX.first #index-circle .act.rp4, .INDEX.SERVICE.third #index-circle .act.rp4 {
        top: 300vh;
        right: 40px; }
      .INDEX.first #index-circle .act.active, .INDEX.SERVICE.third #index-circle .act.active {
        opacity: 1;
        filter: blur(0); }
      .INDEX.first #index-circle .act > img, .INDEX.SERVICE.third #index-circle .act > img {
        width: 640px;
        height: 640px; }
  .INDEX.first #index-pins, .INDEX.SERVICE.third #index-pins {
    z-index: 2; }
  .INDEX.first p, .INDEX.SERVICE.third p {
    text-align: center;
    font-size: 48px;
    color: #FFF;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 500ms ease; }
    @media screen and (max-width: 768px) {
      .INDEX.first p, .INDEX.SERVICE.third p {
        padding: 0 15px;
        font-size: 20px;
        white-space: nowrap; } }
    .INDEX.first p.active, .INDEX.SERVICE.third p.active {
      opacity: 1; }

.INDEX.second, .INDEX.SERVICE.four {
  position: relative;
  z-index: 2; }
  .INDEX.second .flex, .INDEX.SERVICE.four .flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    position: relative; }
    .INDEX.second .flex > li, .INDEX.SERVICE.four .flex > li {
      list-style: none;
      width: 23%; }
      @media screen and (max-width: 768px) {
        .INDEX.second .flex > li, .INDEX.SERVICE.four .flex > li {
          width: 49%;
          height: 180px !important;
          margin: 0 0 2%;
          position: static !important; } }
      .INDEX.second .flex > li a, .INDEX.SERVICE.four .flex > li a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
        position: relative; }
        .INDEX.second .flex > li a:hover:before, .INDEX.SERVICE.four .flex > li a:hover:before {
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.55);
          border-radius: 10px;
          position: absolute;
          top: 0;
          left: 0; }
        .INDEX.second .flex > li a.over:hover:before, .INDEX.SERVICE.four .flex > li a.over:hover:before {
          content: "Coming Soon";
          background: rgba(0, 0, 0, 0.85);
          color: #FFF;
          font-size: 24px;
          z-index: 2;
          width: 100%;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          justify-content: center;
          align-items: center; }
      .INDEX.second .flex > li span, .INDEX.SERVICE.four .flex > li span {
        display: block;
        text-align: center;
        color: #FFF;
        font-size: 24px;
        padding: 0 0 50px;
        background: url("../../../images/common/arw_01.png") no-repeat center bottom;
        background-size: 40px;
        position: relative; }
        @media screen and (max-width: 768px) {
          .INDEX.second .flex > li span, .INDEX.SERVICE.four .flex > li span {
            font-size: 18px;
            padding: 0 0 40px; } }
        .INDEX.second .flex > li span:after, .INDEX.SERVICE.four .flex > li span:after {
          content: attr(data-eng);
          display: block;
          font-size: 12px; }
          @media screen and (max-width: 768px) {
            .INDEX.second .flex > li span:after, .INDEX.SERVICE.four .flex > li span:after {
              font-size: 10px; } }
      .INDEX.second .flex > li:nth-child(1) a, .INDEX.SERVICE.four .flex > li:nth-child(1) a {
        background: url("../../../images/index/back_01.png") no-repeat center;
        background-size: cover; }
      .INDEX.second .flex > li:nth-child(2) a, .INDEX.SERVICE.four .flex > li:nth-child(2) a {
        background: url("../../../images/index/back_02.png") no-repeat center;
        background-size: cover; }
      .INDEX.second .flex > li:nth-child(3) a, .INDEX.SERVICE.four .flex > li:nth-child(3) a {
        background: url("../../../images/index/back_03.png") no-repeat center;
        background-size: cover; }
      .INDEX.second .flex > li:nth-child(4) a, .INDEX.SERVICE.four .flex > li:nth-child(4) a {
        background: url("../../../images/index/back_04.png") no-repeat center;
        background-size: cover; }
      .INDEX.second .flex > li:nth-child(5) a, .INDEX.SERVICE.four .flex > li:nth-child(5) a {
        background: url("../../../images/index/back_05.png") no-repeat center;
        background-size: cover; }
      .INDEX.second .flex > li:nth-child(6) a, .INDEX.SERVICE.four .flex > li:nth-child(6) a {
        background: url("../../../images/index/back_06.png") no-repeat center;
        background-size: cover; }
      .INDEX.second .flex > li:nth-child(1), .INDEX.SERVICE.four .flex > li:nth-child(1) {
        height: 278px; }
        .INDEX.second .flex > li:nth-child(1) a, .INDEX.SERVICE.four .flex > li:nth-child(1) a {
          background: url("../../../images/index/back_01_b.png") no-repeat center;
          background-size: cover; }
      .INDEX.second .flex > li:nth-child(2), .INDEX.SERVICE.four .flex > li:nth-child(2) {
        height: 205px; }
      .INDEX.second .flex > li:nth-child(3), .INDEX.SERVICE.four .flex > li:nth-child(3) {
        height: 205px; }
      .INDEX.second .flex > li:nth-child(4), .INDEX.SERVICE.four .flex > li:nth-child(4) {
        height: 470px; }
      .INDEX.second .flex > li:nth-child(5), .INDEX.SERVICE.four .flex > li:nth-child(5) {
        height: 166px;
        position: absolute;
        bottom: 0;
        left: 0; }
      .INDEX.second .flex > li:nth-child(6), .INDEX.SERVICE.four .flex > li:nth-child(6) {
        height: 235px;
        width: 48.6%;
        position: absolute;
        bottom: 0;
        left: 25.6%; }

.INDEX.third {
  position: relative;
  z-index: 3;
  overflow: hidden; }
  .INDEX.third ul > li {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    background: url("../../../images/index/recruit.jpg") no-repeat center;
    background-size: cover;
    padding: 30px 20px 75px;
    color: #FFF;
    position: relative; }
    @media screen and (max-width: 768px) {
      .INDEX.third ul > li {
        padding: 20px 20px 65px; } }
    .INDEX.third ul > li span {
      display: inline-block;
      width: 70px;
      height: 70px;
      position: absolute;
      top: 0;
      left: 0;
      background: url("../../../images/index/recruit_new.png"); }
      .INDEX.third ul > li span i {
        display: inline-block;
        transform: rotate(-45deg);
        position: absolute;
        top: 12px;
        left: 4px; }
    .INDEX.third ul > li dl {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      .INDEX.third ul > li dl dt {
        width: 30%;
        text-align: right;
        border-right: 1px solid #FFF;
        padding-right: 20px;
        font-size: 12px;
        font-weight: 600;
        margin: 0 0 5px; }
        @media screen and (max-width: 768px) {
          .INDEX.third ul > li dl dt {
            font-size: 11px; } }
      .INDEX.third ul > li dl dd {
        width: 70%;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 5px; }
        @media screen and (max-width: 768px) {
          .INDEX.third ul > li dl dd {
            font-size: 15px; } }
    .INDEX.third ul > li a {
      display: inline-block;
      font-size: 12px;
      color: #000;
      background: url("../../../images/index/icon_right.png") no-repeat right 14px center #FFF;
      padding: 10px 56px 10px 37px;
      position: absolute;
      bottom: 20px;
      right: 20px; }
  .INDEX.third .swiper-container {
    overflow: inherit !important; }
  .INDEX.third .swiper-pagination {
    bottom: -40px !important; }
    @media screen and (max-width: 768px) {
      .INDEX.third .swiper-pagination {
        display: none; } }
  .INDEX.third .btnarea {
    text-align: center; }
    .INDEX.third .btnarea a {
      background: #FFF;
      color: #0d3b55;
      display: inline-block;
      padding: 14px 80px;
      font-size: 22px;
      font-weight: 700;
      position: relative;
      transition: transform 200ms; }
      @media screen and (max-width: 768px) {
        .INDEX.third .btnarea a {
          width: 92%;
          margin: 0 auto;
          display: block;
          padding: 14px 0;
          font-size: 18px; } }
      .INDEX.third .btnarea a:after {
        content: '';
        width: 100%;
        height: 100%;
        border: #FFF 1px solid;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(6px) translateY(6px);
        pointer-events: none;
        transition: transform 200ms; }
      .INDEX.third .btnarea a i {
        display: inline-block;
        margin: 0 0 0 8px;
        font-size: 14px;
        transform: translateY(-4px); }
        @media screen and (max-width: 768px) {
          .INDEX.third .btnarea a i {
            transform: translateY(-2px); } }
      .INDEX.third .btnarea a .syachi {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        pointer-events: none; }
        .INDEX.third .btnarea a .syachi__left, .INDEX.third .btnarea a .syachi__right {
          position: absolute;
          top: -36px;
          width: 64px; }
          @media screen and (max-width: 768px) {
            .INDEX.third .btnarea a .syachi__left, .INDEX.third .btnarea a .syachi__right {
              width: 40px;
              top: -24px; } }
        .INDEX.third .btnarea a .syachi__left {
          left: -20px;
          transform-origin: right bottom;
          animation: syachi-left 300ms infinite; }
          @media screen and (max-width: 768px) {
            .INDEX.third .btnarea a .syachi__left {
              left: -8px; } }

@keyframes syachi-left {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(8deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-8deg); }
  100% {
    transform: rotate(0deg); } }
        .INDEX.third .btnarea a .syachi__right {
          right: -20px;
          transform-origin: left bottom;
          animation: syachi-right 300ms infinite; }
          @media screen and (max-width: 768px) {
            .INDEX.third .btnarea a .syachi__right {
              right: -8px; } }

@keyframes syachi-right {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(-8deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(8deg); }
  100% {
    transform: rotate(0deg); } }
      .INDEX.third .btnarea a:hover {
        transform: translateX(4px) translateY(4px); }
        .INDEX.third .btnarea a:hover .syachi img {
          opacity: 1; }
        .INDEX.third .btnarea a:hover .syachi .syachi__left {
          animation: syachi-left 100ms infinite; }
        .INDEX.third .btnarea a:hover .syachi .syachi__right {
          animation: syachi-right 100ms infinite; }
        .INDEX.third .btnarea a:hover:after {
          transform: translateX(0) translateY(0); }

.INDEX.news {
  position: relative;
  z-index: 3; }
  .INDEX.news .flex {
    position: relative;
    z-index: 2; }
    @media screen and (min-width: 769px) {
      .INDEX.news .flex {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between; } }
  .INDEX.news .newsbox {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    width: calc(100% - 320px); }
    @media screen and (max-width: 768px) {
      .INDEX.news .newsbox {
        width: auto;
        display: block; } }
  .INDEX.news .twitterbox {
    width: 280px; }
    @media screen and (max-width: 768px) {
      .INDEX.news .twitterbox {
        width: auto;
        margin: 24px 0 0; } }
  .INDEX.news .list {
    padding: 0 40px;
    height: 240px;
    width: calc(100% - 130px);
    overflow: auto;
    background: rgba(255, 255, 255, 0.8);
    padding: 24px; }
    @media screen and (max-width: 768px) {
      .INDEX.news .list {
        padding: 12px;
        width: 100%;
        border-left: none;
        height: 200px; } }
    .INDEX.news .list li {
      list-style: none;
      border-bottom: #DDD 1px solid;
      padding: 0 0 13px;
      margin: 0 0 13px; }
      @media screen and (max-width: 768px) {
        .INDEX.news .list li {
          padding: 0 0 8px;
          margin: 0 0 8px; } }
      .INDEX.news .list li a {
        color: #000; }
        .INDEX.news .list li a:hover {
          text-decoration: underline; }
      .INDEX.news .list li time {
        background: #e2deb1;
        padding: 5px 16px;
        font-size: 10px;
        border-radius: 4px;
        margin: 0 16px 0 0;
        display: inline-block; }
        @media screen and (max-width: 768px) {
          .INDEX.news .list li time {
            display: block;
            width: 25%;
            padding: 2px 10px;
            text-align: center;
            margin-bottom: 3px; } }
      .INDEX.news .list li:last-child {
        margin: 0;
        padding: 0;
        border: none; }
  .INDEX.news .more {
    height: 240px;
    width: 110px;
    background: #0d3b55;
    display: table;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    color: #FFF;
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }
    @media screen and (max-width: 768px) {
      .INDEX.news .more {
        width: 100%;
        height: auto;
        margin: 22px 0 0;
        padding: 20px 0; } }
    .INDEX.news .more .icon {
      display: table-cell;
      vertical-align: middle; }
      .INDEX.news .more .icon .icon_list {
        background: url("../../../images/index/news_icon.png") no-repeat center top;
        padding: 24px 0 0;
        background-size: 24px;
        display: block; }
        @media screen and (max-width: 768px) {
          .INDEX.news .more .icon .icon_list {
            display: inline-block;
            background-position: left center;
            padding: 0 0 0 34px; } }
    .INDEX.news .more:hover {
      background: rgba(13, 59, 85, 0.85); }

.RECRUIT.list .flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .RECRUIT.list .flex > li {
    list-style: none;
    width: 49%;
    background: #FFF;
    padding: 15px;
    margin: 0 0 2%;
    border-radius: 10px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .RECRUIT.list .flex > li {
        width: 100%;
        padding: 8px 12px; } }
    .RECRUIT.list .flex > li span {
      width: 45%;
      font-size: 16px;
      font-weight: 700;
      padding-left: 10%; }
      @media screen and (max-width: 768px) {
        .RECRUIT.list .flex > li span {
          padding-left: 0;
          font-size: 13px; } }
    .RECRUIT.list .flex > li a {
      width: 50%;
      display: block;
      border-radius: 6px;
      background: url("../../../images/common/arw_02_2x.png") no-repeat right 30px center #6c9dd3;
      background-size: 19px 6px;
      color: #FFF;
      padding: 8px;
      text-align: center; }

.RECRUIT.detail .oneClum > li {
  list-style: none;
  margin: 0 0 10px; }
  .RECRUIT.detail .oneClum > li .flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    .RECRUIT.detail .oneClum > li .flex > dt {
      width: 20%;
      background: #b9dff4;
      padding: 12px;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .RECRUIT.detail .oneClum > li .flex > dt {
          width: 100%;
          padding: 8px; } }
    .RECRUIT.detail .oneClum > li .flex > dd {
      width: 80%;
      background: #FFF;
      padding: 12px 24px; }
      @media screen and (max-width: 768px) {
        .RECRUIT.detail .oneClum > li .flex > dd {
          width: 100%;
          padding: 8px 12px; } }
      .RECRUIT.detail .oneClum > li .flex > dd span {
        display: block;
        padding-left: 50px; }

.ABOUTUS.first .msg, .ABOUTUS.SERVICE.third .msg {
  width: 70%;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .ABOUTUS.first .msg, .ABOUTUS.SERVICE.third .msg {
      width: 100%; } }
  .ABOUTUS.first .msg p, .ABOUTUS.SERVICE.third .msg p {
    color: #FFF;
    margin: 0 0 24px; }
  .ABOUTUS.first .msg dl, .ABOUTUS.SERVICE.third .msg dl {
    text-align: right;
    color: #FFF; }
    .ABOUTUS.first .msg dl dt, .ABOUTUS.SERVICE.third .msg dl dt {
      font-size: 16px; }
      @media screen and (max-width: 768px) {
        .ABOUTUS.first .msg dl dt, .ABOUTUS.SERVICE.third .msg dl dt {
          font-size: 14px; } }
    .ABOUTUS.first .msg dl dd, .ABOUTUS.SERVICE.third .msg dl dd {
      font-size: 24px; }
      @media screen and (max-width: 768px) {
        .ABOUTUS.first .msg dl dd, .ABOUTUS.SERVICE.third .msg dl dd {
          font-size: 18px; } }

.ABOUTUS.first .msg-L, .ABOUTUS.SERVICE.third .msg-L {
  position: relative;
  color: #FFF;
  padding: 0 0 10px; }
  .ABOUTUS.first .msg-L .syu, .ABOUTUS.SERVICE.third .msg-L .syu {
    float: right; }
    @media screen and (max-width: 768px) {
      .ABOUTUS.first .msg-L .syu, .ABOUTUS.SERVICE.third .msg-L .syu {
        float: none;
        text-align: center;
        margin: 24px 0 0; } }
    .ABOUTUS.first .msg-L .syu img, .ABOUTUS.SERVICE.third .msg-L .syu img {
      max-width: 100%; }
      @media screen and (max-width: 768px) {
        .ABOUTUS.first .msg-L .syu img, .ABOUTUS.SERVICE.third .msg-L .syu img {
          width: 72%; } }
  .ABOUTUS.first .msg-L .text, .ABOUTUS.SERVICE.third .msg-L .text {
    width: 43%;
    text-align: justify;
    float: left; }
    @media screen and (max-width: 768px) {
      .ABOUTUS.first .msg-L .text, .ABOUTUS.SERVICE.third .msg-L .text {
        float: none;
        width: auto; } }
    .ABOUTUS.first .msg-L .text p, .ABOUTUS.SERVICE.third .msg-L .text p {
      margin: 0 0 24px;
      text-align: justify;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .ABOUTUS.first .msg-L .text p, .ABOUTUS.SERVICE.third .msg-L .text p {
          font-size: 14.5px;
          margin: 0 8px 20px; } }
      .ABOUTUS.first .msg-L .text p:last-of-type, .ABOUTUS.SERVICE.third .msg-L .text p:last-of-type {
        margin: 0; }
  .ABOUTUS.first .msg-L .name, .ABOUTUS.SERVICE.third .msg-L .name {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 32px;
    text-align: right;
    line-height: 1; }
    @media screen and (max-width: 768px) {
      .ABOUTUS.first .msg-L .name, .ABOUTUS.SERVICE.third .msg-L .name {
        position: relative;
        font-size: 24px;
        margin: 16px 0 0; } }
    .ABOUTUS.first .msg-L .name dt, .ABOUTUS.SERVICE.third .msg-L .name dt {
      font-size: 17px;
      margin: 0 0 10px; }
      @media screen and (max-width: 768px) {
        .ABOUTUS.first .msg-L .name dt, .ABOUTUS.SERVICE.third .msg-L .name dt {
          font-size: 15px; } }

.ABOUTUS.second dl, .ABOUTUS.SERVICE.four dl {
  margin: 0 0 12px;
  background: #FFF;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .ABOUTUS.second dl dt, .ABOUTUS.SERVICE.four dl dt {
    width: 20%;
    padding: 25px;
    background: #b33e23;
    color: #FFF;
    text-align: center;
    position: relative; }
    .ABOUTUS.second dl dt:before, .ABOUTUS.SERVICE.four dl dt:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12px 0 0;
      border-color: #ffffff transparent transparent transparent;
      position: absolute;
      top: 1px;
      left: 1px; }
  .ABOUTUS.second dl dd, .ABOUTUS.SERVICE.four dl dd {
    width: 80%;
    padding: 25px; }
    .ABOUTUS.second dl dd .gmap, .ABOUTUS.SERVICE.four dl dd .gmap,
    .ABOUTUS.second dl dd .map,
    .ABOUTUS.SERVICE.four dl dd .map {
      margin: 24px 0 0; }
      .ABOUTUS.second dl dd .gmap iframe, .ABOUTUS.SERVICE.four dl dd .gmap iframe,
      .ABOUTUS.second dl dd .map iframe,
      .ABOUTUS.SERVICE.four dl dd .map iframe {
        width: 100%;
        height: 300px;
        vertical-align: bottom; }
        @media screen and (max-width: 768px) {
          .ABOUTUS.second dl dd .gmap iframe, .ABOUTUS.SERVICE.four dl dd .gmap iframe,
          .ABOUTUS.second dl dd .map iframe,
          .ABOUTUS.SERVICE.four dl dd .map iframe {
            height: 200px; } }
    .ABOUTUS.second dl dd p, .ABOUTUS.SERVICE.four dl dd p {
      margin: 0 0 16px; }
      .ABOUTUS.second dl dd p:last-of-type, .ABOUTUS.SERVICE.four dl dd p:last-of-type {
        margin-bottom: 0; }
    .ABOUTUS.second dl dd ul > li, .ABOUTUS.SERVICE.four dl dd ul > li {
      list-style: none;
      float: left;
      margin: 0 20px 0 0; }
      @media screen and (max-width: 768px) {
        .ABOUTUS.second dl dd ul > li, .ABOUTUS.SERVICE.four dl dd ul > li {
          float: none;
          margin: 0 0 8px; } }
      .ABOUTUS.second dl dd ul > li:last-child, .ABOUTUS.SERVICE.four dl dd ul > li:last-child {
        margin: 0; }
      .ABOUTUS.second dl dd ul > li a, .ABOUTUS.SERVICE.four dl dd ul > li a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: flex-start;
        color: #000; }
        .ABOUTUS.second dl dd ul > li a img, .ABOUTUS.SERVICE.four dl dd ul > li a img {
          width: auto;
          height: 28px; }
        .ABOUTUS.second dl dd ul > li a span, .ABOUTUS.SERVICE.four dl dd ul > li a span {
          display: inline-block;
          margin: 0 0 0 6px; }
  .ABOUTUS.second dl.skel, .ABOUTUS.SERVICE.four dl.skel {
    background: #f9f9f9; }
    .ABOUTUS.second dl.skel > dt, .ABOUTUS.SERVICE.four dl.skel > dt {
      background: #eed0ca;
      color: #000; }
      .ABOUTUS.second dl.skel > dt:before, .ABOUTUS.SERVICE.four dl.skel > dt:before {
        content: none; }
  .ABOUTUS.second dl.no-bottom, .ABOUTUS.SERVICE.four dl.no-bottom {
    margin-bottom: 0; }

.ABOUTUS.third .btn {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  width: 697px;
  height: 123px;
  background: url("../../../images/aboutus/btn_01.png") no-repeat;
  box-shadow: 0px 0px 28px 0.58px rgba(0, 0, 0, 0.17);
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .ABOUTUS.third .btn {
      width: 100%;
      height: auto;
      padding: 25px; } }
  .ABOUTUS.third .btn span {
    color: #FFF;
    background: url("../../../images/common/arw_02_2x.png") no-repeat right 32px center;
    background-size: 19px 6px;
    display: block;
    width: 100%;
    text-align: right;
    padding: 0 84px 0 0;
    font-size: 20px; }
    @media screen and (max-width: 768px) {
      .ABOUTUS.third .btn span {
        font-size: 14px; } }

.SERVICE.first, .SERVICE.third {
  position: relative; }
  .SERVICE.first:before, .SERVICE.third:before {
    content: "";
    background: url("../../../images/service/back_01.png") no-repeat;
    background-size: cover;
    width: 300px;
    height: 300px;
    position: absolute;
    top: 42px;
    left: 0; }
  @media screen and (max-width: 768px) {
    .SERVICE.first .inBox, .SERVICE.third .inBox {
      background: rgba(0, 0, 0, 0.65);
      padding: 25px;
      margin: 12px 0 32px; } }
  .SERVICE.first p, .SERVICE.third p {
    margin: 0 0 62px;
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .SERVICE.first p, .SERVICE.third p {
        font-size: 13px;
        margin: 0;
        color: #FFF; } }
  .SERVICE.first .flex, .SERVICE.third .flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    .SERVICE.first .flex > li, .SERVICE.third .flex > li {
      list-style: none;
      width: 24%;
      background: url("../../../images/index/back_01.png") no-repeat center;
      background-size: cover;
      padding: 60px 0 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      .SERVICE.first .flex > li:nth-child(1), .SERVICE.third .flex > li:nth-child(1) {
        background: url("../../../images/index/back_01.png") no-repeat center;
        background-size: cover; }
        .SERVICE.first .flex > li:nth-child(1) dl:before, .SERVICE.third .flex > li:nth-child(1) dl:before {
          content: url("../../../images/service/icon_01.png");
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translate(-50%, 0); }
      .SERVICE.first .flex > li:nth-child(2), .SERVICE.third .flex > li:nth-child(2) {
        background: url("../../../images/index/back_02.png") no-repeat center;
        background-size: cover; }
        .SERVICE.first .flex > li:nth-child(2) dl:before, .SERVICE.third .flex > li:nth-child(2) dl:before {
          content: url("../../../images/service/icon_02.png");
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translate(-50%, 0); }
      .SERVICE.first .flex > li:nth-child(3), .SERVICE.third .flex > li:nth-child(3) {
        background: url("../../../images/index/back_03.png") no-repeat center;
        background-size: cover; }
        .SERVICE.first .flex > li:nth-child(3) dl:before, .SERVICE.third .flex > li:nth-child(3) dl:before {
          content: url("../../../images/service/icon_03.png");
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translate(-50%, 0); }
      .SERVICE.first .flex > li:nth-child(4), .SERVICE.third .flex > li:nth-child(4) {
        background: url("../../../images/index/back_04.png") no-repeat center;
        background-size: cover; }
        .SERVICE.first .flex > li:nth-child(4) dl:before, .SERVICE.third .flex > li:nth-child(4) dl:before {
          content: url("../../../images/service/icon_04.png");
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translate(-50%, 0); }
      @media screen and (max-width: 768px) {
        .SERVICE.first .flex > li, .SERVICE.third .flex > li {
          width: 100%;
          margin: 0 0 2%; }
          .SERVICE.first .flex > li:last-child, .SERVICE.third .flex > li:last-child {
            margin-bottom: 0; } }
      .SERVICE.first .flex > li dl, .SERVICE.third .flex > li dl {
        background: #FFF;
        padding: 60px 30px 50px;
        position: relative;
        width: 100%; }
        @media screen and (max-width: 768px) {
          .SERVICE.first .flex > li dl, .SERVICE.third .flex > li dl {
            padding: 40px 25px 25px; } }
        .SERVICE.first .flex > li dl dt, .SERVICE.third .flex > li dl dt {
          text-align: center;
          color: #0d3b55;
          font-size: 20px;
          font-weight: 700;
          border-bottom: 1px solid #DDD;
          padding: 0 0 8px;
          margin: 0 0 18px; }
          @media screen and (max-width: 768px) {
            .SERVICE.first .flex > li dl dt, .SERVICE.third .flex > li dl dt {
              font-size: 18px; } }
        .SERVICE.first .flex > li dl dd, .SERVICE.third .flex > li dl dd {
          font-size: 16px;
          text-align: justify; }
          @media screen and (max-width: 768px) {
            .SERVICE.first .flex > li dl dd, .SERVICE.third .flex > li dl dd {
              font-size: 13px; } }

.SERVICE.second .flex, .SERVICE.four .flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .SERVICE.second .flex > li, .SERVICE.four .flex > li {
    width: 496px;
    height: 504px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .SERVICE.second .flex > li, .SERVICE.four .flex > li {
        width: 100%;
        height: auto;
        min-height: 342px;
        margin: 0 0 4%; } }
    .SERVICE.second .flex > li:first-child, .SERVICE.four .flex > li:first-child {
      background: url("../../../images/service/sec_01.png") no-repeat center; }
      @media screen and (max-width: 768px) {
        .SERVICE.second .flex > li:first-child, .SERVICE.four .flex > li:first-child {
          background-size: 350px 342px; } }
      .SERVICE.second .flex > li:first-child dl dt, .SERVICE.four .flex > li:first-child dl dt {
        color: #0d3b55; }
    .SERVICE.second .flex > li:last-child, .SERVICE.four .flex > li:last-child {
      background: url("../../../images/service/sec_02.png") no-repeat center; }
      @media screen and (max-width: 768px) {
        .SERVICE.second .flex > li:last-child, .SERVICE.four .flex > li:last-child {
          background-size: 370px 361px; } }
    .SERVICE.second .flex > li dl, .SERVICE.four .flex > li dl {
      width: 50%;
      padding-top: 25%;
      text-align: center; }
      .SERVICE.second .flex > li dl dt, .SERVICE.four .flex > li dl dt {
        font-size: 30px;
        font-weight: 700;
        margin: 0 0 24px;
        border-bottom: 1px solid #DDD; }
        @media screen and (max-width: 768px) {
          .SERVICE.second .flex > li dl dt, .SERVICE.four .flex > li dl dt {
            font-size: 20px;
            line-height: 1.5;
            padding: 0 0 6px;
            margin: 0 0 12px; } }
        .SERVICE.second .flex > li dl dt:before, .SERVICE.four .flex > li dl dt:before {
          content: attr(data-eng);
          font-size: 10px;
          display: block; }
      @media screen and (max-width: 768px) {
        .SERVICE.second .flex > li dl dd, .SERVICE.four .flex > li dl dd {
          font-size: 11px; } }

.SERVICE.third:before {
  content: "";
  background: url("../../../images/service/back_02.png") no-repeat;
  background-size: cover; }

.SERVICE.third h3.left {
  color: #b33e23; }
  @media screen and (max-width: 768px) {
    .SERVICE.third h3.left {
      color: #FFF; } }

.SERVICE.third .flex {
  counter-reset: num; }
  .SERVICE.third .flex > li {
    width: 32%;
    background: #FFF;
    padding-top: 60px; }
    @media screen and (max-width: 768px) {
      .SERVICE.third .flex > li {
        width: 100%;
        margin: 0 0 2%;
        padding-top: 20px; } }
    .SERVICE.third .flex > li dl:before {
      content: "plan" !important;
      color: #b33e23;
      font-size: 13px;
      top: 12px !important; }
    .SERVICE.third .flex > li dl:after {
      counter-increment: num;
      content: counter(num);
      color: #b33e23;
      font-size: 36px;
      font-weight: 700;
      position: absolute;
      top: 24px;
      left: 50%;
      transform: translate(-50%, 0); }
      @media screen and (max-width: 768px) {
        .SERVICE.third .flex > li dl:after {
          font-size: 28px; } }
    .SERVICE.third .flex > li dl dt {
      color: #b33e23;
      padding-top: 24px; }
      @media screen and (max-width: 768px) {
        .SERVICE.third .flex > li dl dt {
          padding-top: 32px; } }

.SERVICE.four .flex {
  justify-content: center; }
  .SERVICE.four .flex > li {
    width: 574px;
    height: 550px; }
    @media screen and (max-width: 768px) {
      .SERVICE.four .flex > li {
        width: 100%;
        height: auto;
        min-height: 335px; } }
    .SERVICE.four .flex > li:first-child {
      background: url("../../../images/service/sec_03.png") no-repeat center; }
      @media screen and (max-width: 768px) {
        .SERVICE.four .flex > li:first-child {
          background-size: 350px 335px; } }
    .SERVICE.four .flex > li dl dt {
      color: #b33e23 !important; }

.CONTACT.first p.center, .CONTACT.SERVICE.third p.center {
  text-align: center; }

.CONTACT.first .form, .CONTACT.SERVICE.third .form {
  background: #FFF;
  padding: 45px;
  border-radius: 10px; }
  @media screen and (max-width: 768px) {
    .CONTACT.first .form, .CONTACT.SERVICE.third .form {
      padding: 25px; } }
  .CONTACT.first .form .tableStyle th.hiss, .CONTACT.SERVICE.third .form .tableStyle th.hiss {
    position: relative; }
    .CONTACT.first .form .tableStyle th.hiss:after, .CONTACT.SERVICE.third .form .tableStyle th.hiss:after {
      content: "必須項目";
      display: inline-block;
      color: #FFF;
      background: #b33e23;
      padding: 2px 10px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%); }
  .CONTACT.first .form .btnArea, .CONTACT.SERVICE.third .form .btnArea {
    margin: 32px 0 0;
    text-align: center; }
  .CONTACT.first .form input[type=text], .CONTACT.SERVICE.third .form input[type=text],
  .CONTACT.first .form input[type=tel],
  .CONTACT.SERVICE.third .form input[type=tel],
  .CONTACT.first .form input[type=email],
  .CONTACT.SERVICE.third .form input[type=email],
  .CONTACT.first .form textarea,
  .CONTACT.SERVICE.third .form textarea, .CONTACT.first .form select, .CONTACT.SERVICE.third .form select {
    font-size: 15px;
    background: #f5f5f5;
    width: 100%;
    border: none;
    padding: 5px 10px;
    line-height: 1.8; }
    @media screen and (max-width: 768px) {
      .CONTACT.first .form input[type=text], .CONTACT.SERVICE.third .form input[type=text],
      .CONTACT.first .form input[type=tel],
      .CONTACT.SERVICE.third .form input[type=tel],
      .CONTACT.first .form input[type=email],
      .CONTACT.SERVICE.third .form input[type=email],
      .CONTACT.first .form textarea,
      .CONTACT.SERVICE.third .form textarea, .CONTACT.first .form select, .CONTACT.SERVICE.third .form select {
        display: block;
        margin: 0 0 5px;
        width: 100% !important;
        background: none;
        border: 1px solid #DDD; } }
  
  .CONTACT.first .form textarea,
  .CONTACT.SERVICE.third .form textarea {
    height: 200px; }
  .CONTACT.first .form input[type="submit"], .CONTACT.SERVICE.third .form input[type="submit"], .CONTACT.first .form input[type="reset"], .CONTACT.SERVICE.third .form input[type="reset"] {
    border: none;
    color: #ffffff;
    background: #0d3b55;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    font-size: 18px;
    display: inline-block;
    padding: 10px 0;
    margin: 0 1%;
    width: 20%;
    cursor: pointer;
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }
    .CONTACT.first .form input[type="submit"]:hover, .CONTACT.SERVICE.third .form input[type="submit"]:hover, .CONTACT.first .form input[type="reset"]:hover, .CONTACT.SERVICE.third .form input[type="reset"]:hover {
      background: #104a6b; }
    @media screen and (max-width: 768px) {
      .CONTACT.first .form input[type="submit"], .CONTACT.SERVICE.third .form input[type="submit"], .CONTACT.first .form input[type="reset"], .CONTACT.SERVICE.third .form input[type="reset"] {
        display: block;
        padding: 12px 0;
        margin: 0 0 10px;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
        text-align: center; } }

.SINGLE.list .oneClum > li {
  list-style: none;
  background: #FFF;
  padding: 15px;
  margin: 0 0 2%;
  border-radius: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start; }
  @media screen and (max-width: 768px) {
    .SINGLE.list .oneClum > li {
      width: 100%;
      padding: 15px;
      display: block; } }
  .SINGLE.list .oneClum > li time {
    width: 10%;
    text-align: center;
    background: #0d3b55;
    font-size: 12px;
    padding: 3px 15px;
    color: #FFF;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      .SINGLE.list .oneClum > li time {
        display: block;
        width: 30%; } }
  .SINGLE.list .oneClum > li span {
    width: 70%;
    padding: 0 12px;
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .SINGLE.list .oneClum > li span {
        display: block;
        width: 100%;
        padding: 12px 0;
        font-size: 14px; } }
  .SINGLE.list .oneClum > li a {
    width: 20%;
    display: block;
    border-radius: 6px;
    background: url("../../../images/common/arw_02_2x.png") no-repeat right 30px center #6c9dd3;
    background-size: 19px 6px;
    color: #FFF;
    padding: 8px;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .SINGLE.list .oneClum > li a {
        width: 100%; } }

.SINGLE.detail .postData {
  background: #FFF;
  padding: 45px;
  border-radius: 10px;
  font-weight: 400; }
  @media screen and (max-width: 768px) {
    .SINGLE.detail .postData {
      padding: 25px; } }
  .SINGLE.detail .postData strong {
    font-weight: 700; }

.site-privacy .text {
  margin: 0 0 48px; }

.site-privacy .margin {
  margin: 0 0 24px; }

.site-privacy ol li {
  list-style: inside decimal;
  margin: 0 0 3px; }
  .site-privacy ol li:last-child {
    margin: 0; }
  .site-privacy ol li > ol {
    padding: 12px 0 0 2em; }

.site-privacy .table-res-style {
  display: block; }
  .site-privacy .table-res-style tbody, .site-privacy .table-res-style tr, .site-privacy .table-res-style th, .site-privacy .table-res-style td {
    display: block; }
  .site-privacy .table-res-style tr {
    margin: 0 0 64px; }
    .site-privacy .table-res-style tr:last-child {
      margin: 0; }
    .site-privacy .table-res-style tr th, .site-privacy .table-res-style tr td {
      text-align: left; }
    .site-privacy .table-res-style tr th {
      background: #f2f2f2;
      padding: 12px;
      font-weight: 700;
      margin: 0 0 12px;
      border-radius: 6px; }

.LP {
  font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.024em; }
  .LP h2 {
    margin: 0 0 42px; }
    @media screen and (max-width: 768px) {
      .LP h2 {
        margin: 0 0 24px; }
        .LP h2 figure img {
          max-width: 100%;
          height: auto; } }
    .LP h2.center {
      text-align: center;
      font-family: "メイリオ", Meiryo,"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-size: 46px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .LP h2.center {
          font-size: 22px; }
          .LP h2.center figure img {
            max-width: 100%;
            height: auto; } }
      .LP h2.center strong {
        font-size: 60px; }
        @media screen and (max-width: 768px) {
          .LP h2.center strong {
            font-size: 28px; } }
        .LP h2.center strong.line {
          position: relative; }
          .LP h2.center strong.line:after {
            content: "";
            width: 100%;
            height: 9px;
            background: yellow;
            position: absolute;
            bottom: 17px;
            left: 0;
            z-index: -1; }
            @media screen and (max-width: 768px) {
              .LP h2.center strong.line:after {
                bottom: 7px; } }
      .LP h2.center.w {
        color: #FFF; }
  .LP a {
    display: inline-block; }
    @media screen and (max-width: 768px) {
      .LP a {
        display: block; } }
    .LP a.arw {
      background: url("../../images/lp/arw_01_2x.png") no-repeat right 23px center #ff9a02;
      background-size: 9px 16px;
      color: #FFF;
      font-size: 20px;
      font-weight: 700;
      padding: 16px 82px;
      border-radius: 99px;
      -webkit-transition: all 400ms 0ms ease-in-out;
      -moz-transition: all 400ms 0ms ease-in-out;
      -ms-transition: all 400ms 0ms ease-in-out;
      -o-transition: all 400ms 0ms ease-in-out;
      transition: all 400ms 0ms ease-in-out; }
      @media screen and (max-width: 768px) {
        .LP a.arw {
          padding: 12px;
          text-align: center;
          font-size: 15px; } }
      .LP a.arw:hover {
        background-color: #ffae35; }
  .LP.view {
    width: 100%;
    height: 768px;
    background: url("../../../images/lp/firstview.jpg") no-repeat center;
    background-size: cover;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    @media screen and (max-width: 768px) {
      .LP.view {
        height: 460px; } }
    .LP.view:before {
      content: "";
      background: linear-gradient(to top left, rgba(255, 255, 255, 0) 50%, #3ec1d2 50.5%) no-repeat top left/100% 100%;
      width: 80%;
      height: 90px;
      position: absolute;
      top: 0;
      left: 0; }
    .LP.view:after {
      content: "";
      background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 50%, #3ec1d2 50.5%) no-repeat top left/100% 100%;
      width: 100%;
      height: 115px;
      position: absolute;
      bottom: 0;
      right: 0; }
    .LP.view .catch {
      position: relative;
      z-index: 10; }
      @media screen and (max-width: 768px) {
        .LP.view .catch {
          padding: 20% 12px 0; } }
      .LP.view .catch > dt figure img {
        max-width: 774px;
        height: auto; }
        @media screen and (max-width: 768px) {
          .LP.view .catch > dt figure img {
            max-width: inherit;
            width: 100%; } }
      .LP.view .catch > dd {
        font-size: 18px;
        font-weight: 700;
        color: #FFF; }
        .LP.view .catch > dd:first-of-type {
          padding: 42px 0; }
          @media screen and (max-width: 768px) {
            .LP.view .catch > dd:first-of-type {
              padding: 22px 0; } }
        @media screen and (max-width: 768px) {
          .LP.view .catch > dd {
            font-size: 14px; } }
        .LP.view .catch > dd i {
          font-size: 32px;
          color: yellow;
          line-height: 1.4; }
          @media screen and (max-width: 768px) {
            .LP.view .catch > dd i {
              font-size: 26px; } }
    .LP.view .contact {
      width: 40%;
      background: #FFF;
      border: 4px solid yellow;
      padding: 30px 45px;
      position: absolute;
      bottom: -45px;
      right: 0;
      z-index: 10; }
      @media screen and (max-width: 768px) {
        .LP.view .contact {
          display: none; } }
      .LP.view .contact > dt {
        background: url("../../../images/lp/icon_tel.png") no-repeat left center;
        background-size: 54px;
        padding-left: 70px;
        margin: 0 0 12px; }
        .LP.view .contact > dt a.tel {
          display: block;
          color: #000;
          font-family: 'Open Sans', sans-serif;
          font-size: 40px;
          font-weight: 700;
          line-height: 1.2; }
        .LP.view .contact > dt span {
          display: block;
          font-size: 14px; }
      .LP.view .contact > dd a.line {
        display: block;
        background: url("../../../images/lp/icon_line.png") no-repeat left 20px center #00b900;
        font-size: 20px;
        font-weight: 700;
        color: #FFF;
        padding: 10px 24px 10px 85px; }
  .LP.first, .LP.SERVICE.third {
    background: url("../../../images/lp/1st_back.png") no-repeat bottom center;
    background-size: cover;
    position: relative; }
    .LP.first .flex, .LP.SERVICE.third .flex {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      width: 100%;
      padding: 0 0 32px;
      position: relative; }
      .LP.first .flex .list, .LP.SERVICE.third .flex .list {
        width: 65%;
        background: #FFF;
        padding: 50px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        position: relative; }
        @media screen and (max-width: 768px) {
          .LP.first .flex .list, .LP.SERVICE.third .flex .list {
            width: 100%;
            padding: 20px; } }
        .LP.first .flex .list:after, .LP.SERVICE.third .flex .list:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 12.5px 0 12.5px 25px;
          border-color: transparent transparent transparent #ffffff;
          position: absolute;
          top: 50%;
          right: -25px;
          transform: translate(0, -50%); }
          @media screen and (max-width: 768px) {
            .LP.first .flex .list:after, .LP.SERVICE.third .flex .list:after {
              content: none; } }
        .LP.first .flex .list > li, .LP.SERVICE.third .flex .list > li {
          list-style: none;
          width: 46%;
          border-bottom: 1px solid #d6f0f3;
          font-size: 24px;
          font-weight: 700;
          background: url("../../../images/lp/icon_batu.png") no-repeat left center;
          background-size: 23px;
          padding: 0 0 5px 32px;
          margin: 0 0 5px; }
          @media screen and (max-width: 768px) {
            .LP.first .flex .list > li, .LP.SERVICE.third .flex .list > li {
              width: 100%;
              font-size: 18px; } }
          .LP.first .flex .list > li span, .LP.SERVICE.third .flex .list > li span {
            color: #ff155d; }
      .LP.first .flex figure, .LP.SERVICE.third .flex figure {
        width: 30%; }
        @media screen and (max-width: 768px) {
          .LP.first .flex figure, .LP.SERVICE.third .flex figure {
            display: none; } }
        .LP.first .flex figure img, .LP.SERVICE.third .flex figure img {
          max-width: 100%;
          height: auto; }
  .LP.second, .LP.SERVICE.four {
    position: relative; }
    .LP.second:before, .LP.SERVICE.four:before {
      content: "";
      width: 100%;
      height: 49%;
      background: #3ec1d2;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1; }
    .LP.second:after, .LP.SERVICE.four:after {
      content: "";
      background: linear-gradient(to top left, rgba(255, 255, 255, 0) 50%, #3ec1d2 50.5%) no-repeat top left/100% 100%;
      width: 100%;
      height: 80px;
      position: absolute;
      bottom: -80px;
      left: 0; }
      @media screen and (max-width: 768px) {
        .LP.second:after, .LP.SERVICE.four:after {
          height: 40px;
          bottom: -40px; } }
    .LP.second .flex, .LP.SERVICE.four .flex {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      .LP.second .flex > li, .LP.SERVICE.four .flex > li {
        list-style: none;
        width: 32%;
        background: #FFF;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.25); }
        @media screen and (max-width: 768px) {
          .LP.second .flex > li, .LP.SERVICE.four .flex > li {
            width: 100%;
            margin: 0 0 2%; } }
        .LP.second .flex > li figure img, .LP.SERVICE.four .flex > li figure img {
          max-width: 100%;
          height: auto; }
        .LP.second .flex > li dl, .LP.SERVICE.four .flex > li dl {
          padding: 25px;
          position: relative; }
          @media screen and (max-width: 768px) {
            .LP.second .flex > li dl, .LP.SERVICE.four .flex > li dl {
              padding: 20px; } }
          .LP.second .flex > li dl span, .LP.SERVICE.four .flex > li dl span {
            background: url("../../../images/lp/2nd_illust_01.png") no-repeat;
            background-size: 94px;
            width: 94px;
            height: 94px;
            display: -webkit-flex;
            display: -moz-flex;
            display: -o-flex;
            display: -ms-flex;
            display: flex;
            -moz-flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -o-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-justify-content: space-between;
            -o-justify-content: space-between;
            -ms-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 1.5;
            font-weight: 700;
            position: absolute;
            top: -47px;
            left: 8px; }
          .LP.second .flex > li dl dt, .LP.SERVICE.four .flex > li dl dt {
            font-size: 36px;
            font-weight: 700;
            text-align: center;
            border-bottom: 1px solid #DDD;
            padding: 0 0 8px;
            margin: 0 0 16px; }
            @media screen and (max-width: 768px) {
              .LP.second .flex > li dl dt, .LP.SERVICE.four .flex > li dl dt {
                font-size: 24px; } }
    .LP.second .btnArea, .LP.SERVICE.four .btnArea {
      margin: 52px 0 0;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .LP.second .btnArea, .LP.SERVICE.four .btnArea {
          margin: 32px 0 0; } }
  .LP.third {
    padding-top: 100px !important;
    position: relative; }
    @media screen and (max-width: 768px) {
      .LP.third {
        padding-top: 50px !important; } }
    .LP.third:before {
      content: "";
      background: url("../../../images/lp/3rd_step.png") no-repeat;
      width: 907px;
      height: 317px;
      position: absolute;
      top: 110px;
      left: 0;
      z-index: -2; }
      @media screen and (max-width: 768px) {
        .LP.third:before {
          width: 100%;
          background-size: cover; } }
    .LP.third:after {
      content: "";
      background: url("../../../images/lp/3rd_illust_01.png") no-repeat;
      width: 412px;
      height: 412px;
      position: absolute;
      top: 3%;
      right: 0; }
      @media screen and (max-width: 768px) {
        .LP.third:after {
          position: static;
          display: block;
          width: 350px;
          height: 350px;
          background-size: cover; } }
    .LP.third .oneClum > li {
      list-style: none;
      margin: 0 0 32px; }
      @media screen and (max-width: 768px) {
        .LP.third .oneClum > li {
          margin: 0 0 4%; } }
      .LP.third .oneClum > li dl {
        position: relative; }
        .LP.third .oneClum > li dl dt {
          width: 35%;
          color: #FFF;
          font-size: 36px;
          font-weight: 700;
          padding: 15px 40px;
          position: relative; }
          @media screen and (max-width: 768px) {
            .LP.third .oneClum > li dl dt {
              width: 100%;
              font-size: 24px;
              padding: 12px;
              background: #3ec1d2; } }
          .LP.third .oneClum > li dl dt:before {
            content: '';
            /*ボックス作成*/
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            transform: skew(-15deg);
            background: #3ec1d2; }
            @media screen and (max-width: 768px) {
              .LP.third .oneClum > li dl dt:before {
                content: none; } }
          .LP.third .oneClum > li dl dt span {
            display: block;
            font-family: 'Open Sans', sans-serif;
            font-size: 10px;
            font-weight: 700;
            border-bottom: 1px solid rgba(221, 221, 221, 0.25);
            padding: 0 0 8px;
            margin: 0 0 12px; }
            @media screen and (max-width: 768px) {
              .LP.third .oneClum > li dl dt span {
                display: inline-block;
                margin: 0 16px 0 0;
                border-bottom: none;
                padding: 0; } }
            .LP.third .oneClum > li dl dt span i {
              display: block;
              color: #ff9a02;
              font-size: 22px;
              line-height: 1.2; }
        .LP.third .oneClum > li dl dd {
          width: 75%;
          min-height: 160px;
          padding: 30px 50px;
          position: absolute;
          top: 20px;
          right: 0;
          z-index: 1; }
          @media screen and (max-width: 768px) {
            .LP.third .oneClum > li dl dd {
              width: 100%;
              position: static;
              padding: 15px; } }
          .LP.third .oneClum > li dl dd:before {
            content: '';
            /*ボックス作成*/
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            transform: skew(-15deg);
            background: #FFF;
            box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.22); }
            @media screen and (max-width: 768px) {
              .LP.third .oneClum > li dl dd:before {
                content: none; } }
  .LP.four {
    position: relative;
    padding-bottom: 120px !important; }
    .LP.four:before {
      content: "";
      background: url("../../../images/lp/4th_faq.png") no-repeat;
      width: 660px;
      height: 267px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1; }
      @media screen and (max-width: 768px) {
        .LP.four:before {
          width: 100%;
          height: 200px;
          background-size: cover; } }
    .LP.four .faq > li {
      list-style: none;
      margin: 0 0 24px; }
      .LP.four .faq > li:last-child {
        margin-bottom: 0; }
      .LP.four .faq > li dl {
        -webkit-transition: all 120ms 0ms ease-in-out;
        -moz-transition: all 120ms 0ms ease-in-out;
        -ms-transition: all 120ms 0ms ease-in-out;
        -o-transition: all 120ms 0ms ease-in-out;
        transition: all 120ms 0ms ease-in-out; }
        .LP.four .faq > li dl:hover {
          transform: scale(1.02);
          box-shadow: 0 4px 4px #DDD;
          border-radius: 99px; }
          @media screen and (max-width: 768px) {
            .LP.four .faq > li dl:hover {
              border-radius: 0; } }
          .LP.four .faq > li dl:hover dt {
            background: #e5e5e5; }
        .LP.four .faq > li dl dt {
          background: #fff3de;
          background-size: 24px 14px;
          font-size: 18px;
          font-weight: 700;
          position: relative;
          border-radius: 99px;
          cursor: pointer; }
          @media screen and (max-width: 768px) {
            .LP.four .faq > li dl dt {
              font-size: 15px; } }
          .LP.four .faq > li dl dt:before {
            content: "Q";
            width: 56px;
            height: 56px;
            display: inline-block;
            border-radius: 50%;
            font-size: 28px;
            text-align: center;
            padding-top: 3px;
            margin-right: 12px;
            background: #ff9a02;
            color: #FFF;
            font-family: Meiryo, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "sans-serif"; }
            @media screen and (max-width: 768px) {
              .LP.four .faq > li dl dt:before {
                font-size: 22px;
                padding-top: 8px; } }
          .LP.four .faq > li dl dt:after {
            content: "";
            background: url("../../../images/lp/arw_faq.png") no-repeat 0 0;
            width: 24px;
            height: 14px;
            position: absolute;
            top: 50%;
            right: 24px;
            transform: translate(0, -50%); }
            @media screen and (max-width: 768px) {
              .LP.four .faq > li dl dt:after {
                width: 12px;
                height: 7px;
                background-size: cover; } }
          .LP.four .faq > li dl dt.active:after {
            background-position: 0 -14px; }
            @media screen and (max-width: 768px) {
              .LP.four .faq > li dl dt.active:after {
                background-position: 0 -7px; } }
        .LP.four .faq > li dl dd {
          display: none;
          padding: 15px 15px 15px 35px;
          font-size: 16px; }
          @media screen and (max-width: 768px) {
            .LP.four .faq > li dl dd {
              padding: 15px;
              font-size: 12px; } }
          .LP.four .faq > li dl dd:before {
            content: "A";
            width: 35px;
            height: 35px;
            display: inline-block;
            border: 1px solid #ff9a02;
            border-radius: 50%;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            padding-top: 3px;
            margin-right: 12px;
            color: #ff9a02;
            font-family: Meiryo, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "sans-serif"; }
    .LP.four .btnArea {
      margin: 52px 0 0;
      text-align: center; }
      .LP.four .btnArea a.arw {
        background: url("../../../images/lp/arw_02_2x.png") no-repeat right 22px center #3ec1d2;
        background-size: 16px 9px; }
        .LP.four .btnArea a.arw:hover {
          opacity: .8; }
  .LP.five {
    background: #3ec1d2;
    position: relative; }
    .LP.five:before {
      content: "";
      background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 50%, #3ec1d2 50.5%) no-repeat top left/100% 100%;
      width: 100%;
      height: 120px;
      position: absolute;
      top: -120px;
      left: 0; }
    .LP.five:after {
      content: "";
      background: url("../../../images/lp/5th_contct.png") no-repeat;
      background-size: cover;
      width: 100%;
      height: 390px;
      position: absolute;
      top: -115px;
      left: 0; }
    .LP.five .display {
      z-index: 2; }
    .LP.five .illust {
      position: absolute;
      top: -200px;
      left: 0; }
      @media screen and (max-width: 768px) {
        .LP.five .illust {
          top: -100px;
          left: -10px; }
          .LP.five .illust img {
            max-width: 100px;
            height: auto; } }
    .LP.five .flex {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      margin: 0 0 52px; }
      @media screen and (max-width: 768px) {
        .LP.five .flex {
          margin: 0; } }
      .LP.five .flex dl {
        width: 48%; }
        @media screen and (max-width: 768px) {
          .LP.five .flex dl {
            width: 100%;
            margin: 0 0 4%; } }
    .LP.five .form dl dd {
      padding: 52px 52px 32px; }
      @media screen and (max-width: 768px) {
        .LP.five .form dl dd {
          padding: 52px 25px 25px; } }
    .LP.five .form .btnArea {
      margin-top: 24px; }
    .LP.five .form .tableStyle th.hiss {
      position: relative; }
      .LP.five .form .tableStyle th.hiss:after {
        content: "必須";
        font-size: 12px;
        color: #FFF;
        background: #ff155c;
        display: inline-block;
        border-radius: 3px;
        padding: 0 8px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%); }
    .LP.five .form .tableStyle td span.tel {
      background: none;
      padding: 0; }
    .LP.five .form label {
      display: inline-block;
      margin-right: 18px; }
    .LP.five .form input[type=text],
    .LP.five .form input[type=tel],
    .LP.five .form input[type=email],
    .LP.five .form textarea {
      font-size: 15px;
      background: #f5f5f5;
      width: 100%;
      border: none;
      padding: 5px 10px;
      line-height: 1.8;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2); }
      @media screen and (max-width: 768px) {
        .LP.five .form input[type=text],
        .LP.five .form input[type=tel],
        .LP.five .form input[type=email],
        .LP.five .form textarea {
          display: block;
          margin: 0 0 5px;
          width: 100%;
          background: none;
          border: 1px solid #DDD; } }
      .LP.five .form input[type=text].s,
      .LP.five .form input[type=tel].s,
      .LP.five .form input[type=email].s,
      .LP.five .form textarea.s {
        width: 30%; }
        @media screen and (max-width: 768px) {
          .LP.five .form input[type=text].s,
          .LP.five .form input[type=tel].s,
          .LP.five .form input[type=email].s,
          .LP.five .form textarea.s {
            display: inline-block;
            margin-right: 10px; } }
    .LP.five .form input[type=date] {
      width: 40%;
      background: #f5f5f5;
      padding: 5px 10px;
      border: none; }
    .LP.five .form select {
      width: 40%;
      background: #f5f5f5;
      padding: 5px 10px;
      border: none; }
    .LP.five .form textarea {
      min-height: 100px; }
    .LP.five .form input[type="submit"], .LP.five .form input[type="reset"] {
      border: none;
      color: #ffffff;
      font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.024em;
      font-size: 18px;
      display: inline-block;
      padding: 15px 0;
      margin: 0 1%;
      width: 30%;
      border-radius: 99px;
      cursor: pointer;
      background: #ff9a02;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
      .LP.five .form input[type="submit"]:hover, .LP.five .form input[type="reset"]:hover {
        background: #ffae35; }
      @media screen and (max-width: 768px) {
        .LP.five .form input[type="submit"], .LP.five .form input[type="reset"] {
          display: block;
          padding: 12px 0;
          margin: 0 0 10px;
          font-size: 17px;
          font-weight: 700;
          width: 100%;
          text-align: center; } }
    .LP.five dl {
      background: #FFF;
      text-align: center;
      position: relative;
      z-index: 2; }
      .LP.five dl dt {
        background: yellow;
        font-size: 20px;
        font-weight: 700;
        padding: 12px 12px 0;
        position: relative; }
        @media screen and (max-width: 768px) {
          .LP.five dl dt {
            font-size: 18px; } }
        .LP.five dl dt:after {
          content: "";
          background: linear-gradient(to top right, rgba(255, 255, 255, 0) 50%, yellow 50%) no-repeat top left/50% 100%, linear-gradient(to top left, rgba(255, 255, 255, 0) 50%, yellow 50%) no-repeat top right/50% 100%;
          width: 100%;
          height: 21px;
          position: absolute;
          bottom: -21px;
          left: 0; }
      .LP.five dl dd {
        padding: 52px 12px 32px; }
        @media screen and (max-width: 768px) {
          .LP.five dl dd {
            padding: 32px 12px 24px; } }
        .LP.five dl dd .line {
          background: url("../../../images/lp/icon_line.png") no-repeat left center;
          font-size: 30px;
          padding-left: 48px; }
          @media screen and (max-width: 768px) {
            .LP.five dl dd .line {
              font-size: 22px;
              background-size: 28px; } }
        .LP.five dl dd .tel {
          background: url("../../../images/lp/icon_tel.png") no-repeat left center;
          font-family: 'Open Sans', sans-serif;
          font-size: 40px;
          font-weight: 700;
          line-height: 1;
          padding-left: 65px; }
          @media screen and (max-width: 768px) {
            .LP.five dl dd .tel {
              background-size: 40px;
              font-size: 30px; } }
          .LP.five dl dd .tel small {
            display: block;
            font-size: 14px;
            padding-right: 6%; }

.MERIT h3.bamboo {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  background: url("../../../images/common/icon_sasa.png") no-repeat center bottom;
  padding: 0 0 32px;
  margin: 0 0 32px;
  background-size: 38px; }
  @media screen and (max-width: 768px) {
    .MERIT h3.bamboo {
      font-size: 16px;
      margin: 0 0 24px; } }
  .MERIT h3.bamboo strong {
    color: #b33e23; }

.MERIT.first p.context, .MERIT.SERVICE.third p.context {
  text-align: center;
  color: #FFF;
  font-size: 32px;
  font-weight: 700;
  padding: 40px 0 24px; }
  @media screen and (max-width: 768px) {
    .MERIT.first p.context, .MERIT.SERVICE.third p.context {
      font-size: 18px;
      padding: 32px 16px 8px; }
      .MERIT.first p.context br, .MERIT.SERVICE.third p.context br {
        display: none; } }

.MERIT.second.border, .MERIT.border.SERVICE.four {
  border-top: #FFF 1px solid;
  border-bottom: #FFF 1px solid; }

.MERIT.second .meritcatch, .MERIT.SERVICE.four .meritcatch {
  margin: 0 0 32px;
  border: #DDD 1px solid;
  background: #FFF;
  padding: 8px; }
  @media screen and (max-width: 768px) {
    .MERIT.second .meritcatch, .MERIT.SERVICE.four .meritcatch {
      margin: 0 0 24px;
      border: none;
      padding: 0; } }
  .MERIT.second .meritcatch img, .MERIT.SERVICE.four .meritcatch img {
    width: 100%; }

.MERIT.second ul.questions, .MERIT.SERVICE.four ul.questions {
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .MERIT.second ul.questions, .MERIT.SERVICE.four ul.questions {
      margin: 0 0 24px; } }
  .MERIT.second ul.questions > li, .MERIT.SERVICE.four ul.questions > li {
    list-style: none;
    margin: 0 0 24px;
    background: rgba(255, 255, 255, 0.64);
    border: rgba(0, 0, 0, 0.12) 1px solid;
    border-radius: 3px;
    padding: 24px; }
    @media screen and (max-width: 768px) {
      .MERIT.second ul.questions > li, .MERIT.SERVICE.four ul.questions > li {
        padding: 16px; } }
    .MERIT.second ul.questions > li:last-child, .MERIT.SERVICE.four ul.questions > li:last-child {
      margin: 0; }
    .MERIT.second ul.questions > li dl dt, .MERIT.SERVICE.four ul.questions > li dl dt, .MERIT.second ul.questions > li dl dd, .MERIT.SERVICE.four ul.questions > li dl dd {
      position: relative;
      padding-left: 48px; }
      .MERIT.second ul.questions > li dl dt:before, .MERIT.SERVICE.four ul.questions > li dl dt:before, .MERIT.second ul.questions > li dl dd:before, .MERIT.SERVICE.four ul.questions > li dl dd:before {
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 50%;
        text-align: center;
        font-size: 22px;
        color: #FFF;
        font-weight: 700;
        position: absolute;
        top: 0;
        left: 0; }
        @media screen and (max-width: 768px) {
          .MERIT.second ul.questions > li dl dt:before, .MERIT.SERVICE.four ul.questions > li dl dt:before, .MERIT.second ul.questions > li dl dd:before, .MERIT.SERVICE.four ul.questions > li dl dd:before {
            width: 28px;
            height: 28px;
            line-height: 28px;
            font-size: 16px; } }
    .MERIT.second ul.questions > li dl dt, .MERIT.SERVICE.four ul.questions > li dl dt {
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 16px;
      margin: 0 0 16px;
      border-bottom: rgba(0, 0, 0, 0.12) 1px solid; }
      @media screen and (max-width: 768px) {
        .MERIT.second ul.questions > li dl dt, .MERIT.SERVICE.four ul.questions > li dl dt {
          font-size: 16px; } }
      .MERIT.second ul.questions > li dl dt:before, .MERIT.SERVICE.four ul.questions > li dl dt:before {
        content: 'Q';
        background: #0d3b55; }
    .MERIT.second ul.questions > li dl dd, .MERIT.SERVICE.four ul.questions > li dl dd {
      text-align: justify; }
      .MERIT.second ul.questions > li dl dd:before, .MERIT.SERVICE.four ul.questions > li dl dd:before {
        content: 'A';
        background: #b33e23; }

.MERIT.second ul.points, .MERIT.SERVICE.four ul.points {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .MERIT.second ul.points, .MERIT.SERVICE.four ul.points {
      display: block; } }
  .MERIT.second ul.points > li, .MERIT.SERVICE.four ul.points > li {
    list-style: none;
    width: 32%;
    padding: 60px 0 0;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .MERIT.second ul.points > li, .MERIT.SERVICE.four ul.points > li {
        width: auto;
        margin: 0 0 16px; }
        .MERIT.second ul.points > li:last-child, .MERIT.SERVICE.four ul.points > li:last-child {
          margin: 0; } }
    .MERIT.second ul.points > li:nth-child(1), .MERIT.SERVICE.four ul.points > li:nth-child(1) {
      background: url("../../../images/index/back_01.png") no-repeat center;
      background-size: cover; }
    .MERIT.second ul.points > li:nth-child(2), .MERIT.SERVICE.four ul.points > li:nth-child(2) {
      background: url("../../../images/index/back_02.png") no-repeat center;
      background-size: cover; }
    .MERIT.second ul.points > li:nth-child(3), .MERIT.SERVICE.four ul.points > li:nth-child(3) {
      background: url("../../../images/index/back_03.png") no-repeat center;
      background-size: cover; }
    .MERIT.second ul.points > li dl, .MERIT.SERVICE.four ul.points > li dl {
      background: #FFF;
      padding: 24px;
      width: 100%;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .MERIT.second ul.points > li dl, .MERIT.SERVICE.four ul.points > li dl {
          padding: 15px; } }
      .MERIT.second ul.points > li dl dt, .MERIT.SERVICE.four ul.points > li dl dt {
        color: #b33e23;
        font-size: 20px;
        font-weight: 700; }

.MERIT.second .mes, .MERIT.SERVICE.four .mes {
  text-align: center;
  margin: 48px 0 0;
  font-size: 17px; }
  @media screen and (max-width: 768px) {
    .MERIT.second .mes, .MERIT.SERVICE.four .mes {
      font-size: 14px;
      margin: 24px 0 0;
      text-align: left; } }
  .MERIT.second .mes strong, .MERIT.SERVICE.four .mes strong {
    color: #b33e23; }

.MERIT .str {
  text-align: center;
  margin: 24px 0 0;
  background: #b33e23;
  color: #FFF;
  font-size: 24px;
  padding: 8px 0;
  font-weight: 700;
  margin: 0 0 16px; }
  @media screen and (max-width: 768px) {
    .MERIT .str {
      padding: 8px 15px;
      font-size: 18px; } }

.MERIT .table-merit {
  width: 100%;
  border-collapse: collapse;
  border: rgba(0, 0, 0, 0.12) 1px solid;
  background: #FFF; }
  .MERIT .table-merit thead tr td {
    color: #b33e23;
    font-size: 20px;
    font-weight: 700; }
  .MERIT .table-merit thead tr td, .MERIT .table-merit tbody tr td {
    width: 50%;
    border: rgba(0, 0, 0, 0.12) 1px solid;
    padding: 8px 15px;
    text-align: center; }

.MERIT dl.desc dt {
  text-align: center;
  background: #b33e23;
  color: #FFF;
  font-size: 24px;
  padding: 8px 0;
  font-weight: 700; }
  @media screen and (max-width: 768px) {
    .MERIT dl.desc dt {
      padding: 8px 15px;
      font-size: 16px; } }

.MERIT dl.desc dd {
  background: #FFF;
  padding: 32px 64px; }
  @media screen and (max-width: 768px) {
    .MERIT dl.desc dd {
      padding: 15px;
      font-size: 14px; } }
  .MERIT dl.desc dd .mb-4 {
    margin: 0 0 16px; }

.VISION {
  position: relative; }
  .VISION:before {
    content: '';
    width: 100%;
    height: 34px;
    background: rgba(13, 59, 85, 0.36);
    position: absolute;
    top: 0;
    left: 0; }
  .VISION .bg_sikkui {
    background: url("../../../images/vision/bg.jpg") repeat;
    background-size: 256px; }
  .VISION .bg_sikkui_light {
    background: url("../../../images/vision/bg_light.jpg") repeat;
    background-size: 256px; }
  .VISION .vision-text {
    position: relative; }
    @media screen and (max-width: 768px) {
      .VISION .vision-text {
        margin: 0 8px; } }
    .VISION .vision-text:before, .VISION .vision-text:after {
      content: '';
      width: 1px;
      height: 1px;
      background: #0d3b55;
      position: absolute;
      top: 0;
      left: 0;
      animation: tate ease-in-out 1200ms infinite;
      z-index: 2; }
    .VISION .vision-text:after {
      left: auto;
      right: 0;
      animation: tate ease-in-out reverse 1200ms infinite; }
    .VISION .vision-text__crone {
      position: relative;
      padding: 12px; }
      .VISION .vision-text__crone:before, .VISION .vision-text__crone:after {
        content: '';
        width: 1px;
        height: 1px;
        background: #0d3b55;
        position: absolute;
        top: 0;
        left: 0;
        animation: yoko ease-in-out 1600ms infinite;
        z-index: 2; }
      .VISION .vision-text__crone:after {
        top: auto;
        bottom: 0;
        animation: yoko ease-in-out reverse 1600ms infinite; }
    .VISION .vision-text__dl {
      text-align: center;
      font-weight: 700;
      font-size: 28px;
      background: rgba(255, 255, 255, 0.64);
      padding: 40px;
      color: #082232; }
      @media screen and (max-width: 768px) {
        .VISION .vision-text__dl {
          font-size: 15px;
          padding: 15px 24px; } }

@keyframes yoko {
  0% {
    width: 1px;
    left: 0; }
  50% {
    width: 100%;
    left: 0; }
  100% {
    width: 0%;
    left: 100%; } }

@keyframes tate {
  0% {
    height: 1px;
    top: 0; }
  50% {
    height: 100%;
    top: 0; }
  100% {
    height: 0%;
    top: 100%; } }
  .VISION .visionbox {
    position: relative; }
    .VISION .visionbox:before {
      content: '';
      background: url("../../../images/index/illust_wa_01.png") no-repeat right bottom;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: .48; }
      @media screen and (max-width: 768px) {
        .VISION .visionbox:before {
          display: none; } }
    .VISION .visionbox--rvs:before {
      right: auto;
      left: 0;
      background: url("../../../images/index/illust_wa_01.png") no-repeat right bottom;
      background-size: contain;
      transform: rotateY(180deg);
      opacity: .24; }
    .VISION .visionbox__bg {
      width: 50%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      background-position: center;
      top: 0;
      left: 0; }
      @media screen and (max-width: 768px) {
        .VISION .visionbox__bg {
          position: relative;
          width: auto;
          height: 200px;
          display: block; } }
      .VISION .visionbox__bg--rvs {
        left: auto;
        right: 0; }
    .VISION .visionbox__txt {
      padding: 80px 0;
      padding-left: 50px;
      margin: 0 0 0 50%; }
      @media screen and (max-width: 768px) {
        .VISION .visionbox__txt {
          padding: 0;
          margin: 0;
          padding: 0; } }
      .VISION .visionbox__txt--rvs {
        margin: 0 50% 0 0;
        padding-left: 0;
        padding-right: 50px; }
        @media screen and (max-width: 768px) {
          .VISION .visionbox__txt--rvs {
            margin: 0;
            padding: 0; } }
      .VISION .visionbox__txt dt {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: .08em; }
        @media screen and (max-width: 768px) {
          .VISION .visionbox__txt dt {
            font-size: 17px;
            text-align: center;
            margin: 20px 0 0; } }
        .VISION .visionbox__txt dt:before {
          content: attr(data-eng);
          font-size: 48px;
          display: inline-block;
          margin: 0 16px 0 0; }
          @media screen and (max-width: 768px) {
            .VISION .visionbox__txt dt:before {
              display: block;
              margin: 0 0 2px;
              font-size: 28px;
              line-height: 1; } }
      .VISION .visionbox__txt dd {
        background: rgba(255, 255, 255, 0.88);
        padding: 20px;
        padding-left: 80px;
        margin: 20px 0 0;
        font-weight: 700;
        font-size: 17px;
        line-height: 1.6;
        position: relative;
        overflow: hidden; }
        @media screen and (max-width: 768px) {
          .VISION .visionbox__txt dd {
            padding: 15px;
            margin: 15px 0 0;
            font-size: 15px;
            padding-left: 72px; } }
        .VISION .visionbox__txt dd:after {
          content: '';
          width: 16px;
          height: 16px;
          background: #bed097;
          position: absolute;
          top: -8px;
          left: -8px;
          transform: rotate(45deg); }
        .VISION .visionbox__txt dd:before {
          content: '';
          background: url("../../../images/common/icon_sasa.png") no-repeat;
          background-size: 37px;
          width: 37px;
          height: 24px;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%); }
          @media screen and (max-width: 768px) {
            .VISION .visionbox__txt dd:before {
              left: 15px; } }
  .VISION .glowttl {
    margin: 0 0 32px;
    text-align: center;
    font-weight: 700;
    font-size: 32px; }
    @media screen and (max-width: 768px) {
      .VISION .glowttl {
        font-size: 20px; } }
  .VISION .glowlist {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .VISION .glowlist {
        display: block; } }
    .VISION .glowlist li {
      width: 23.5%;
      padding: 20px;
      font-size: 16px;
      background: rgba(255, 255, 255, 0.88);
      text-align: center;
      font-weight: 700;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      border-top: #EEE 4px solid; }
      @media screen and (max-width: 768px) {
        .VISION .glowlist li {
          width: auto;
          padding: 15px 24px;
          margin: 0 0 15px; }
          .VISION .glowlist li:last-child {
            margin: 0; } }
      .VISION .glowlist li:nth-child(1) {
        border-color: #d50424; }
      .VISION .glowlist li:nth-child(2) {
        border-color: #e3ed00; }
      .VISION .glowlist li:nth-child(3) {
        border-color: #66b82a; }
      .VISION .glowlist li:nth-child(4) {
        border-color: #4894d2; }

.BLOG .wrap {
  padding: 88px 0 56px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .BLOG .wrap {
      padding: 56px 15px 32px; } }
  .BLOG .wrap:before {
    content: '';
    width: 100%;
    height: 36px;
    position: absolute;
    top: 0;
    left: 0;
    background: #0d3b55; }

.BLOG h2.borders, .BLOG h3.borders, .BLOG h4.borders {
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
  position: relative;
  padding: 12px;
  padding-left: 20px;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 24px; }
  @media screen and (max-width: 768px) {
    .BLOG h2.borders, .BLOG h3.borders, .BLOG h4.borders {
      font-size: 17px;
      padding-left: 16px;
      margin: 0 0 16px; } }
  .BLOG h2.borders:after, .BLOG h3.borders:after, .BLOG h4.borders:after {
    content: '';
    width: 5px;
    height: 60%;
    background: #0d3b55;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 20%;
    left: 0; }

.BLOG h2 small, .BLOG h3 small, .BLOG h4 small {
  display: inline-block;
  margin: 0 0 0 20px;
  font-size: 12px; }

.BLOG .flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .BLOG .flex {
      display: block; } }

.BLOG .container {
  width: calc(100% - 360px); }
  @media screen and (max-width: 768px) {
    .BLOG .container {
      width: auto; } }

.BLOG .sidebar {
  width: 360px;
  padding: 0 0 0 64px; }
  @media screen and (max-width: 768px) {
    .BLOG .sidebar {
      width: auto;
      padding: 32px 0 0; } }
  .BLOG .sidebar__box {
    margin: 0 0 56px; }
    @media screen and (max-width: 768px) {
      .BLOG .sidebar__box {
        margin: 0 0 32px; } }
    .BLOG .sidebar__box:last-of-type {
      margin: 0; }

.BLOG .bloglist > li {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 32px;
  padding: 0 0 32px;
  border-bottom: #DDD 1px solid; }
  .BLOG .bloglist > li a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
    color: #000;
    position: relative; }
  .BLOG .bloglist > li figure {
    width: 220px;
    height: 140px; }
    @media screen and (max-width: 768px) {
      .BLOG .bloglist > li figure {
        width: 120px;
        height: 80px; } }
    .BLOG .bloglist > li figure img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .BLOG .bloglist > li dl {
    width: calc(100% - 220px);
    padding: 0 0 0 32px; }
    @media screen and (max-width: 768px) {
      .BLOG .bloglist > li dl {
        width: calc(100% - 120px);
        padding: 0 0 0 15px; } }
    .BLOG .bloglist > li dl .meta {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px; }
      .BLOG .bloglist > li dl .meta .category {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: flex-start; }
        @media screen and (max-width: 768px) {
          .BLOG .bloglist > li dl .meta .category {
            margin: 6px 0 0; } }
      .BLOG .bloglist > li dl .meta time,
      .BLOG .bloglist > li dl .meta li {
        background: #0d3b55;
        color: #FFF;
        padding: 2px 0;
        display: inline-block;
        margin: 0 10px 0 0;
        width: 120px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .BLOG .bloglist > li dl .meta time,
          .BLOG .bloglist > li dl .meta li {
            width: 80px;
            font-size: 10px; } }
      .BLOG .bloglist > li dl .meta li {
        background: #f2f2f2;
        color: #000;
        padding: 2px 16px;
        width: auto; }
        @media screen and (max-width: 768px) {
          .BLOG .bloglist > li dl .meta li {
            font-size: 10px; } }
    .BLOG .bloglist > li dl dt {
      font-size: 20px;
      font-weight: 700;
      margin: 10px 0; }
      @media screen and (max-width: 768px) {
        .BLOG .bloglist > li dl dt {
          font-size: 15px;
          margin: 6px 0 0; } }
    .BLOG .bloglist > li dl dd.desc {
      text-align: justify;
      font-size: 15px;
      line-height: 1.66; }
      @media screen and (max-width: 768px) {
        .BLOG .bloglist > li dl dd.desc {
          display: none; } }

.BLOG .bloglist.mini > li {
  margin: 0 0 20px;
  padding: 0 0 20px; }
  @media screen and (max-width: 768px) {
    .BLOG .bloglist.mini > li {
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .BLOG .bloglist.mini > li a figure {
    width: 80px;
    height: 50px; }
  .BLOG .bloglist.mini > li a dl {
    width: calc(100% - 80px);
    padding: 0 0 0 20px; }
    .BLOG .bloglist.mini > li a dl .meta {
      font-size: 10px; }
    .BLOG .bloglist.mini > li a dl dt {
      font-size: 14px;
      margin: 4px 0 0; }

.BLOG .newslist > li {
  margin: 0 0 32px;
  padding: 0 0 32px;
  border-bottom: #DDD 1px solid; }
  @media screen and (max-width: 768px) {
    .BLOG .newslist > li {
      margin: 0 0 16px;
      padding: 0 0 16px; } }
  .BLOG .newslist > li a {
    color: #000;
    position: relative;
    padding: 0 32px 0 0;
    display: block; }
    .BLOG .newslist > li a:hover {
      opacity: .64; }
    .BLOG .newslist > li a:after {
      content: "\f061";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      font-size: 11px; }
  .BLOG .newslist > li dl {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .BLOG .newslist > li dl {
        align-items: flex-start; } }
    .BLOG .newslist > li dl dd {
      border: #000 1px solid;
      padding: 2px 0;
      display: inline-block;
      width: 120px;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .BLOG .newslist > li dl dd {
          width: 80px;
          font-size: 10px;
          padding: 3px 0; } }
    .BLOG .newslist > li dl dt {
      font-weight: 700;
      font-size: 16px;
      width: calc(100% - 120px);
      padding-left: 24px; }
      @media screen and (max-width: 768px) {
        .BLOG .newslist > li dl dt {
          width: calc(100% - 80px);
          padding-left: 15px;
          font-size: 14px;
          line-height: 1.5; } }

.BLOG .categorylist {
  transform: translateY(-15px); }
  .BLOG .categorylist li {
    border-bottom: #DDD 1px solid; }
    .BLOG .categorylist li a {
      padding: 15px 4px;
      padding-right: 32px;
      display: block;
      color: #000;
      position: relative; }
      .BLOG .categorylist li a dl dd {
        border: #000 1px solid;
        padding: 1px 0;
        display: inline-block;
        width: 120px;
        text-align: center;
        margin: 0 0 4px; }
      .BLOG .categorylist li a dl dt {
        font-weight: 700;
        font-size: 14px; }
      .BLOG .categorylist li a:hover {
        opacity: .64; }
      .BLOG .categorylist li a:after {
        content: "\f061";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 11px; }

.BLOG .single__title {
  border: #DDD 1px solid;
  padding: 24px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .BLOG .single__title {
      padding: 15px; } }
  .BLOG .single__title:before {
    content: '';
    width: calc(100% + 1px);
    height: 5px;
    background: #0d3b55;
    position: absolute;
    top: -1px;
    left: -1px; }
  .BLOG .single__title h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.66;
    margin: 0 0 12px; }
    @media screen and (max-width: 768px) {
      .BLOG .single__title h2 {
        font-size: 16px;
        padding: 0 40px 0 0; } }

.BLOG .single__meta {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px; }
  .BLOG .single__meta .category {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start; }
  .BLOG .single__meta time,
  .BLOG .single__meta li {
    border: #000 1px solid;
    padding: 2px 16px;
    display: inline-block;
    margin: 0 10px 0 0; }
    @media screen and (max-width: 768px) {
      .BLOG .single__meta time,
      .BLOG .single__meta li {
        font-size: 10px; } }
  .BLOG .single__meta li {
    background: #f2f2f2;
    border-color: #f2f2f2; }

.BLOG .single__catch {
  margin: 24px 0 0; }
  .BLOG .single__catch img {
    width: 100%; }

.BLOG .single .index {
  background: #efefef;
  border-radius: 2px;
  padding: 24px;
  margin: 24px 0 0; }
  @media screen and (max-width: 768px) {
    .BLOG .single .index {
      padding: 15px; } }
  .BLOG .single .index h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    padding: 0 0 16px;
    margin: 0 0 16px;
    border-bottom: rgba(0, 0, 0, 0.08) 1px solid; }
    @media screen and (max-width: 768px) {
      .BLOG .single .index h3 {
        font-size: 16px; } }
    .BLOG .single .index h3 i {
      display: inline-block;
      margin: 0 12px 0 0; }
  .BLOG .single .index ol li {
    list-style: inside decimal;
    font-size: 16px;
    margin: 0 0 4px; }
    @media screen and (max-width: 768px) {
      .BLOG .single .index ol li {
        font-size: 14px; } }
    .BLOG .single .index ol li:last-child {
      margin: 0; }
    .BLOG .single .index ol li a {
      color: #000;
      text-decoration: underline; }

.BLOG .pager {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  margin: 32px 0 0; }
  .BLOG .pager a,
  .BLOG .pager span {
    width: 36px;
    height: 36px;
    background: #f2f2f2;
    color: #000;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 6px; }
  .BLOG .pager a:hover {
    background: #FFF;
    box-shadow: inset 0 8px 32px rgba(0, 0, 0, 0.24); }
  .BLOG .pager span {
    color: #FFF;
    background: #0d3b55; }

.site-edit {
  margin: 40px 0 0;
  font-size: 15px;
  line-height: 1.8; }
  @media screen and (max-width: 768px) {
    .site-edit {
      margin: 24px 0 0;
      font-size: 14px; } }
  .site-edit h3 {
    font-size: 24px;
    font-weight: 700;
    border-top: #000 2px solid;
    border-bottom: #000 2px solid;
    font-weight: 700;
    padding: 8px 2px;
    margin: 0 0 20px; }
    @media screen and (max-width: 768px) {
      .site-edit h3 {
        font-size: 18px; } }
  .site-edit h4 {
    font-size: 22px;
    border-bottom: #DDD 2px solid;
    font-weight: 700;
    padding: 0 0 0 16px;
    margin: 0 0 16px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .site-edit h4 {
        font-size: 16px; } }
    .site-edit h4:before {
      content: '';
      width: 4px;
      height: 80%;
      position: absolute;
      top: 10%;
      left: 0;
      background: #000;
      border-radius: 2px; }
  .site-edit h5 {
    font-size: 20px;
    font-weight: 700;
    background: #f2f2f2;
    padding: 4px 15px;
    margin: 0 0 16px; }
    @media screen and (max-width: 768px) {
      .site-edit h5 {
        font-size: 16px; } }
  .site-edit h6 {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 0 18px;
    margin: 0 0 8px; }
    @media screen and (max-width: 768px) {
      .site-edit h6 {
        font-size: 15px;
        padding: 0 0 0 12px;
        margin: 0 0 6px; } }
    .site-edit h6:before {
      content: '';
      width: 12px;
      height: 12px;
      border: #000 4px solid;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .site-edit table {
    border: #DDD 1px solid;
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 40px; }
    @media screen and (max-width: 768px) {
      .site-edit table {
        margin: 0 0 24px; } }
    .site-edit table tbody tr td, .site-edit table tbody tr th,
    .site-edit table thead tr td,
    .site-edit table thead tr th {
      padding: 15px;
      text-align: left;
      vertical-align: middle;
      border: #DDD 1px solid; }
    .site-edit table tbody tr th,
    .site-edit table thead tr th {
      background: #f2f2f2; }
      @media screen and (max-width: 768px) {
        .site-edit table tbody tr th,
        .site-edit table thead tr th {
          min-width: 100px; } }
  .site-edit img {
    max-width: 100%; }
  .site-edit .avatar {
    width: 88px;
    margin: 0 15px; }
    .site-edit .avatar img {
      width: 100%;
      border-radius: 50%; }
    .site-edit .avatar figcaption {
      text-align: center;
      margin: 15px 0 0;
      font-size: 14px; }
  .site-edit iframe {
    width: 100%;
    height: 300px;
    vertical-align: bottom;
    margin: 0 0 40px; }
    @media screen and (max-width: 768px) {
      .site-edit iframe {
        height: 200px;
        margin: 0 0 24px; } }
  .site-edit p,
  .site-edit ul,
  .site-edit ol {
    margin: 0 0 40px; }
    @media screen and (max-width: 768px) {
      .site-edit p,
      .site-edit ul,
      .site-edit ol {
        margin: 0 0 24px; } }
  .site-edit .charactor li {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    margin: 0 0 24px; }
    @media screen and (max-width: 768px) {
      .site-edit .charactor li {
        margin: 0 0 16px; } }
    .site-edit .charactor li:last-child {
      margin: 0; }
    .site-edit .charactor li.right .txt:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 20px;
      left: -24px;
      border-style: solid;
      border-width: 8px 24px 8px 0;
      border-color: transparent #DDD transparent transparent; }
    .site-edit .charactor li.left figure {
      order: 2; }
    .site-edit .charactor li.left .txt {
      order: 1; }
      .site-edit .charactor li.left .txt:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 20px;
        right: -24px;
        border-style: solid;
        border-width: 8px 0 8px 24px;
        border-color: transparent transparent transparent #DDD; }
    .site-edit .charactor li figure {
      width: 50px;
      height: 50px;
      background: #ededed;
      border-radius: 50%;
      overflow: hidden;
      margin: 8px 0 0; }
      .site-edit .charactor li figure img {
        width: 100%; }
    .site-edit .charactor li .txt {
      width: calc(100% - 50px - 32px);
      border: #DDD 4px solid;
      border-radius: 8px;
      padding: 20px;
      font-size: 15px;
      margin: 0;
      position: relative; }
      @media screen and (max-width: 768px) {
        .site-edit .charactor li .txt {
          padding: 15px;
          font-size: 14px; } }

.site-lp {
  font-family: 'Noto Sans JP', "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 400;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.024em;
  color: #323436;
  line-height: 1.92; }
  @media screen and (max-width: 768px) {
    .site-lp {
      font-size: 13.5px; } }
  .site-lp .lpart {
    position: relative;
    padding: 100vh 0 0; }
  .site-lp .lphead {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    padding: 30px 60px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .site-lp .lphead {
        padding: 15px; } }
    .site-lp .lphead__logo {
      height: 36px;
      transition: opacity 200ms; }
      @media screen and (max-width: 768px) {
        .site-lp .lphead__logo {
          height: 32px; } }
      .site-lp .lphead__logo.active {
        opacity: 0; }
      .site-lp .lphead__logo img {
        height: 100%; }
    .site-lp .lphead__menu .open {
      width: 42px;
      height: 42px;
      background: #FFF;
      border-radius: 50%;
      display: block;
      box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.32);
      animation: wave 2000ms linear infinite;
      position: relative;
      cursor: pointer;
      transition: transform 200ms; }
      .site-lp .lphead__menu .open:hover {
        transform: scale(1.1); }

@keyframes wave {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); }
  70% {
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.32); }
  100% {
    box-shadow: 0 0 0 12px rgba(255, 255, 255, 0); } }
      .site-lp .lphead__menu .open:before, .site-lp .lphead__menu .open:after {
        content: '';
        width: 16px;
        height: 2px;
        background: #003f98;
        position: absolute;
        left: 50%;
        top: calc(50% - 3.5px);
        transform: translateX(-50%);
        border-radius: 999px; }
      .site-lp .lphead__menu .open:after {
        top: auto;
        bottom: calc(50% - 3.5px); }
  .site-lp .globalmenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #FFF;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    visibility: hidden; }
    .site-lp .globalmenu.active {
      visibility: visible; }
    .site-lp .globalmenu__close {
      position: absolute;
      width: 42px;
      height: 42px;
      right: 60px;
      top: 30px;
      z-index: 2;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        .site-lp .globalmenu__close {
          right: 15px;
          top: 15px; } }
      .site-lp .globalmenu__close:before, .site-lp .globalmenu__close:after {
        content: '';
        width: 36px;
        height: 1px;
        background: #323436;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transition: transform 200ms; }
      .site-lp .globalmenu__close:after {
        transform: translateX(-50%) translateY(-50%) rotate(-45deg); }
      .site-lp .globalmenu__close:hover:before, .site-lp .globalmenu__close:hover:after {
        transform: translateX(-50%) translateY(-50%) rotate(0deg); }
    .site-lp .globalmenu__list {
      text-align: center; }
      .site-lp .globalmenu__list li {
        font-size: 17px;
        margin: 0 0 6vh; }
        @media screen and (max-width: 768px) {
          .site-lp .globalmenu__list li {
            font-size: 15px; } }
        .site-lp .globalmenu__list li:last-child {
          margin: 0; }
        .site-lp .globalmenu__list li a {
          color: #323436;
          line-height: 1;
          display: block;
          position: relative; }
          .site-lp .globalmenu__list li a:before {
            content: attr(data-eng);
            font-size: 26px;
            font-weight: 700;
            letter-spacing: .06em;
            display: block;
            margin: 0 0 6px; }
            @media screen and (max-width: 768px) {
              .site-lp .globalmenu__list li a:before {
                font-size: 20px; } }
          .site-lp .globalmenu__list li a:after {
            content: '';
            width: 0;
            height: 8px;
            border-radius: 4px;
            top: 50%;
            left: 50%;
            background: #fbf10c;
            position: absolute;
            transform: translateY(-50%);
            z-index: -1;
            transition: width 200ms cubic-bezier(0.85, 0, 0.15, 1), left 200ms cubic-bezier(0.85, 0, 0.15, 1); }
          .site-lp .globalmenu__list li a:hover:after {
            width: 120%;
            left: -10%; }
  .site-lp .fv {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden; }
    .site-lp .fv:before {
      content: '';
      width: 100%;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .24;
      background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.99) 1%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.99) 1%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.99) 1%, rgba(0, 0, 0, 0) 100%);
      z-index: 2; }
    .site-lp .fv__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 1;
      transform: scale(1.1);
      animation: bg 4000ms forwards; }

@keyframes bg {
  0% {
    transform: scale(1.04); }
  100% {
    transform: scale(1); } }
    .site-lp .fv__content {
      margin: 0 0 0 9.6vw;
      transform: translateY(-4%);
      position: relative;
      z-index: 2;
      color: #FFF; }
      .site-lp .fv__content .eng,
      .site-lp .fv__content h1 {
        opacity: 0;
        transform: translateY(20px); }
      .site-lp .fv__content .eng {
        font-family: 'Noto Serif', serif;
        font-weight: 700;
        letter-spacing: 0em;
        font-style: italic;
        line-height: 1.24;
        font-size: 88px;
        text-shadow: 0 0 32px rgba(0, 0, 0, 0.24), 0 0 32px rgba(0, 0, 0, 0.08);
        transition: opacity 800ms 400ms linear, transform 800ms 400ms ease-in; }
        @media screen and (max-width: 768px) {
          .site-lp .fv__content .eng {
            font-size: 40px; } }
        .site-lp .fv__content .eng .indent {
          text-indent: -.04em;
          display: inline-block;
          letter-spacing: .012em; }
      .site-lp .fv__content h1 {
        font-weight: 700;
        font-size: 18px;
        letter-spacing: .2em;
        margin: 24px 0 0;
        padding: 0 0 3px;
        border-bottom: #FFF 3px solid;
        display: inline-block;
        transform: translateY(10px);
        transition: opacity 800ms 600ms linear, transform 800ms 600ms ease-in; }
        @media screen and (max-width: 768px) {
          .site-lp .fv__content h1 {
            font-size: 15px;
            margin: 16px 0 0; } }
    .site-lp .fv__copy {
      position: absolute;
      left: 60px;
      bottom: 30px;
      color: #FFF;
      font-size: 10px;
      letter-spacing: .08em;
      z-index: 2; }
      @media screen and (max-width: 768px) {
        .site-lp .fv__copy {
          left: 15px;
          bottom: 15px; } }
    .site-lp .fv__scroll {
      -moz-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      white-space: nowrap;
      font-size: 10px;
      letter-spacing: .24em;
      position: absolute;
      bottom: 0;
      right: 60px;
      line-height: 1;
      padding: 0 16px 52px 0;
      color: #FFF;
      z-index: 2;
      text-align: right; }
      @media screen and (max-width: 768px) {
        .site-lp .fv__scroll {
          right: 15px; } }
      .site-lp .fv__scroll:before, .site-lp .fv__scroll:after {
        content: '';
        width: 1px;
        height: 104px;
        background: #FFF;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateX(-50%); }
      .site-lp .fv__scroll:before {
        opacity: .64; }
      .site-lp .fv__scroll:after {
        animation: sc 1600ms infinite; }

@keyframes sc {
  0% {
    height: 104px;
    bottom: 0; }
  33% {
    height: 0;
    bottom: 0; }
  65% {
    height: 0;
    bottom: 104px; }
  66% {
    height: 0;
    bottom: 104px; }
  100% {
    height: 104px;
    bottom: 0; } }
    .site-lp .fv.active .fv__content__catch .eng,
    .site-lp .fv.active .fv__content__catch h1 {
      transform: translateY(0);
      opacity: 1; }
  .site-lp .lp-module {
    position: relative;
    z-index: 3;
    background: #FFF; }
    .site-lp .lp-module.wrap {
      padding: 88px 0; }
      @media screen and (max-width: 768px) {
        .site-lp .lp-module.wrap {
          padding: 40px 15px; } }
      .site-lp .lp-module.wrap.no-top {
        padding-top: 0; }
      .site-lp .lp-module.wrap.no-bottom {
        padding-bottom: 0; }
    .site-lp .lp-module h2.large {
      text-align: center;
      font-weight: 700;
      margin: 0 0 28px;
      font-size: 17px;
      letter-spacing: .2em; }
      @media screen and (max-width: 768px) {
        .site-lp .lp-module h2.large {
          font-size: 15px;
          margin: 0 0 16px; } }
      .site-lp .lp-module h2.large:before {
        content: attr(data-eng);
        font-size: 42px;
        display: block;
        line-height: 1;
        margin: 0 0 6px;
        letter-spacing: .02em; }
        @media screen and (max-width: 768px) {
          .site-lp .lp-module h2.large:before {
            font-size: 28px; } }
    .site-lp .lp-module .marker {
      display: inline-block;
      background: linear-gradient(transparent 60%, #fbf10c 60%); }
      @media screen and (max-width: 768px) {
        .site-lp .lp-module .marker {
          background: none; } }
    .site-lp .lp-module .tablestyle {
      border: #DDD 1px solid;
      border-collapse: collapse;
      width: 100%;
      font-size: 15.5px; }
      @media screen and (max-width: 768px) {
        .site-lp .lp-module .tablestyle {
          display: block;
          font-size: 13.5px; } }
      @media screen and (max-width: 768px) {
        .site-lp .lp-module .tablestyle tbody {
          display: block; } }
      .site-lp .lp-module .tablestyle tbody tr {
        border-bottom: #e0e4e6 1px solid; }
        @media screen and (max-width: 768px) {
          .site-lp .lp-module .tablestyle tbody tr {
            display: block; }
            .site-lp .lp-module .tablestyle tbody tr:last-child {
              border-bottom: none; } }
        .site-lp .lp-module .tablestyle tbody tr th,
        .site-lp .lp-module .tablestyle tbody tr td {
          padding: 24px;
          text-align: left;
          vertical-align: middle; }
          @media screen and (max-width: 768px) {
            .site-lp .lp-module .tablestyle tbody tr th,
            .site-lp .lp-module .tablestyle tbody tr td {
              display: block;
              padding: 10px 15px; } }
          .site-lp .lp-module .tablestyle tbody tr th a,
          .site-lp .lp-module .tablestyle tbody tr td a {
            color: #003f98;
            text-decoration: underline;
            font-weight: 700; }
            .site-lp .lp-module .tablestyle tbody tr th a i,
            .site-lp .lp-module .tablestyle tbody tr td a i {
              display: inline-block;
              margin: 0 0 0 12px;
              font-size: 12px; }
        .site-lp .lp-module .tablestyle tbody tr th {
          font-weight: 700; }
          @media screen and (max-width: 768px) {
            .site-lp .lp-module .tablestyle tbody tr th {
              border-bottom: #DDD 1px solid;
              background: #e8f1f5; } }
        .site-lp .lp-module .tablestyle tbody tr dl {
          margin: 0 0 24px; }
          .site-lp .lp-module .tablestyle tbody tr dl:last-of-type {
            margin: 0; }
          .site-lp .lp-module .tablestyle tbody tr dl dt {
            display: inline-block;
            width: 140px;
            background: #003f98;
            color: #FFF;
            text-align: center;
            border-radius: 2px; }
    .site-lp .lp-module .btnarea {
      text-align: center;
      margin: 32px 0 0; }
      @media screen and (max-width: 768px) {
        .site-lp .lp-module .btnarea {
          margin: 24px 0 0; } }
      .site-lp .lp-module .btnarea a {
        background: #003f98;
        border: #003f98 2px solid;
        padding: 16px 120px;
        display: inline-block;
        color: #FFF;
        font-size: 24px;
        font-weight: 700;
        border-radius: 999px;
        -webkit-transition: all 200ms 0ms ease-in-out;
        -moz-transition: all 200ms 0ms ease-in-out;
        -ms-transition: all 200ms 0ms ease-in-out;
        -o-transition: all 200ms 0ms ease-in-out;
        transition: all 200ms 0ms ease-in-out; }
        @media screen and (max-width: 768px) {
          .site-lp .lp-module .btnarea a {
            padding: 10px 0;
            display: block;
            font-size: 18px; } }
        .site-lp .lp-module .btnarea a:hover {
          background: #FFF;
          color: #003f98; }
        .site-lp .lp-module .btnarea a i {
          display: inline-block;
          margin: 0 12px 0 0; }
  .site-lp .first, .site-lp .SERVICE.third {
    overflow: hidden;
    position: relative;
    background: #FFF; }
    .site-lp .first__bg {
      padding: 64px 0;
      position: relative; }
      @media screen and (max-width: 768px) {
        .site-lp .first__bg {
          padding: 0; } }
      .site-lp .first__bg:before {
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #e8f1f5;
        z-index: -1; }
        @media screen and (max-width: 768px) {
          .site-lp .first__bg:before {
            top: -40px;
            left: -15px;
            width: calc(50% + 15px);
            height: 60vw; } }
      .site-lp .first__bg__circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
        @media screen and (max-width: 768px) {
          .site-lp .first__bg__circle {
            display: none; } }
        .site-lp .first__bg__circle:before, .site-lp .first__bg__circle:after {
          content: '';
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background: #e8f1f5;
          position: absolute; }
        .site-lp .first__bg__circle:before {
          right: 5%;
          top: -22px; }
        .site-lp .first__bg__circle:after {
          transform: scale(0.64);
          right: 9.5%;
          top: 32px;
          opacity: .56;
          background: #fbf10c; }
    .site-lp .first__clum {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 768px) {
        .site-lp .first__clum {
          display: block; } }
      .site-lp .first__clum__left {
        width: calc(56%);
        height: 470px;
        background-position: center;
        background-size: cover;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.04);
        position: relative; }
        @media screen and (max-width: 768px) {
          .site-lp .first__clum__left {
            width: auto;
            height: 60vw; } }
        .site-lp .first__clum__left img {
          display: none; }
      .site-lp .first__clum__right {
        width: calc(44%); }
        @media screen and (max-width: 768px) {
          .site-lp .first__clum__right {
            width: auto;
            padding: 24px 0 0; } }
        .site-lp .first__clum__right__box {
          max-width: 620px;
          padding: 0 64px;
          margin: 0 auto; }
          @media screen and (max-width: 768px) {
            .site-lp .first__clum__right__box {
              padding: 0;
              max-width: 100%; } }
        .site-lp .first__clum__right h2 {
          font-size: 28px;
          letter-spacing: .08em;
          font-weight: 700;
          line-height: 1.5; }
          @media screen and (max-width: 768px) {
            .site-lp .first__clum__right h2 {
              font-size: 20px;
              text-align: center; } }
          .site-lp .first__clum__right h2:before {
            content: attr(data-eng);
            color: #003f98;
            font-size: 14px;
            font-weight: 700;
            margin: 0 0 8px;
            display: block;
            letter-spacing: .12em; }
        .site-lp .first__clum__right p.txt {
          margin: 16px 0 0;
          font-size: 15px;
          text-align: justify; }
          @media screen and (max-width: 768px) {
            .site-lp .first__clum__right p.txt {
              font-size: 13.5px; } }
  .site-lp .second, .site-lp .SERVICE.four {
    background: white;
    position: relative;
    overflow: hidden; }
    .site-lp .second__bg {
      content: '';
      position: absolute;
      top: -5%;
      left: -5%;
      width: 110%;
      height: 110%;
      z-index: 1;
      background: url(/"../../../images/sub/second_bg.jpg") center fixed;
      background-size: cover;
      opacity: .24; }
      @media screen and (max-width: 768px) {
        .site-lp .second__bg {
          background: url(/"../../../images/sub/second_bg.jpg") center;
          background-size: cover; } }
    .site-lp .second .display, .site-lp .SERVICE.four .display {
      z-index: 2; }
    .site-lp .second .context, .site-lp .SERVICE.four .context {
      text-align: center;
      font-size: 17px;
      font-weight: 700;
      opacity: .88;
      letter-spacing: .08em;
      margin: 0 0 16px; }
      @media screen and (max-width: 768px) {
        .site-lp .second .context, .site-lp .SERVICE.four .context {
          font-size: 16px; } }
    .site-lp .second__box {
      background: #FFF;
      padding: 48px;
      box-shadow: 0 0 32px rgba(0, 0, 0, 0.08);
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        .site-lp .second__box {
          padding: 15px;
          display: block; } }
      .site-lp .second__box h3 {
        width: 100%;
        color: #003f98;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 24px; }
        @media screen and (max-width: 768px) {
          .site-lp .second__box h3 {
            font-size: 17px; } }
      .site-lp .second__box__pic {
        width: 400px; }
        @media screen and (max-width: 768px) {
          .site-lp .second__box__pic {
            width: 100%; } }
        .site-lp .second__box__pic img {
          width: 100%; }
      .site-lp .second__box__data {
        width: calc(100% - 400px);
        padding: 0 0 0 48px; }
        @media screen and (max-width: 768px) {
          .site-lp .second__box__data {
            width: auto;
            padding: 15px 0 0; } }
        .site-lp .second__box__data dl dt {
          background: #003f98;
          padding: 2px 24px;
          color: #FFF;
          display: inline-block;
          font-weight: 700;
          margin: 0 0 10px; }
        .site-lp .second__box__data dl dd {
          margin: 2px 0 0;
          font-weight: 700; }
          .site-lp .second__box__data dl dd:before {
            content: "\f00c";
            font-weight: 900;
            font-family: "Font Awesome 5 Free";
            display: inline-block;
            margin: 0 8px 0 0;
            font-size: 13px;
            color: #003f98; }
        .site-lp .second__box__data .txt {
          margin: 15px 0 0;
          padding: 12px 0 0;
          border-top: #e0e4e6 1px solid;
          font-size: 14.5px; }
          @media screen and (max-width: 768px) {
            .site-lp .second__box__data .txt {
              font-size: 13.5px;
              text-align: justify; } }
  .site-lp .third {
    background: url("../../../images/sub/texture.png") repeat;
    background-size: 17px;
    overflow: hidden; }
    .site-lp .third__list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      position: relative;
      background: #FFF;
      padding: 40px 0;
      border: #e8f1f5 2px solid;
      border-radius: 8px; }
      @media screen and (max-width: 768px) {
        .site-lp .third__list {
          display: block;
          padding: 0;
          border-radius: 0; } }
      .site-lp .third__list:before, .site-lp .third__list:after {
        content: '';
        width: 2px;
        height: 100%;
        background: #e8f1f5;
        position: absolute;
        top: 0; }
        @media screen and (max-width: 768px) {
          .site-lp .third__list:before, .site-lp .third__list:after {
            display: none; } }
      .site-lp .third__list:before {
        left: 33.3%; }
      .site-lp .third__list:after {
        right: 33.3%; }
      .site-lp .third__list li {
        width: 33.3%;
        padding: 0 20px;
        position: relative; }
        @media screen and (max-width: 768px) {
          .site-lp .third__list li {
            width: auto;
            padding: 20px 15px;
            border-bottom: #e8f1f5 2px solid; }
            .site-lp .third__list li:last-child {
              border: none; } }
        .site-lp .third__list li:nth-child(1):before {
          content: "1";
          position: absolute;
          top: -32px;
          right: 10px;
          width: 40px;
          height: 40px;
          font-size: 17px;
          color: #FFF;
          background: #003f98;
          border-radius: 0 8px;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          z-index: 2; }
          @media screen and (max-width: 768px) {
            .site-lp .third__list li:nth-child(1):before {
              top: 0;
              right: 0;
              border-radius: 0; } }
        .site-lp .third__list li:nth-child(2):before {
          content: "2";
          position: absolute;
          top: -32px;
          right: 10px;
          width: 40px;
          height: 40px;
          font-size: 17px;
          color: #FFF;
          background: #003f98;
          border-radius: 0 8px;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          z-index: 2; }
          @media screen and (max-width: 768px) {
            .site-lp .third__list li:nth-child(2):before {
              top: 0;
              right: 0;
              border-radius: 0; } }
        .site-lp .third__list li:nth-child(3):before {
          content: "3";
          position: absolute;
          top: -32px;
          right: 10px;
          width: 40px;
          height: 40px;
          font-size: 17px;
          color: #FFF;
          background: #003f98;
          border-radius: 0 8px;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          z-index: 2; }
          @media screen and (max-width: 768px) {
            .site-lp .third__list li:nth-child(3):before {
              top: 0;
              right: 0;
              border-radius: 0; } }
        .site-lp .third__list li i {
          width: 88px;
          height: 88px;
          background: #eff5f8;
          border-radius: 50%;
          font-size: 32px;
          color: #003f98;
          display: block;
          text-align: center;
          margin: 0 auto 20px;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          position: relative; }
          .site-lp .third__list li i:after {
            content: '';
            position: absolute;
            top: 5px;
            right: 5px;
            width: 16px;
            height: 16px;
            background: #fbf10c;
            border-radius: 50%; }
        .site-lp .third__list li dl {
          text-align: center; }
          .site-lp .third__list li dl dt {
            text-align: center;
            display: inline-block;
            font-size: 18px;
            font-weight: 700;
            margin: 0 0 8px;
            position: relative; }
          .site-lp .third__list li dl dd {
            text-align: justify;
            font-size: 14.5px;
            margin: 0 15px; }
  .site-lp .four {
    background: #e8f1f5 url("../../../images/sub/texture_w.png") repeat;
    background-size: 17px; }
    .site-lp .four__list li {
      background: #FFF;
      border-radius: 6px;
      padding: 24px 32px;
      margin: 0 0 20px; }
      .site-lp .four__list li:last-child {
        margin: 0; }
      .site-lp .four__list li dl dt, .site-lp .four__list li dl dd {
        position: relative;
        padding: 0 0 0 40px; }
        .site-lp .four__list li dl dt:before, .site-lp .four__list li dl dd:before {
          content: 'Q';
          position: absolute;
          top: -4px;
          left: 0;
          font-size: 20px;
          color: #003f98; }
      .site-lp .four__list li dl dt {
        font-weight: 700;
        font-size: 17px;
        padding-bottom: 12px;
        margin: 0 0 12px;
        border-bottom: #e0e4e6 1px dashed; }
        @media screen and (max-width: 768px) {
          .site-lp .four__list li dl dt {
            font-size: 15px; } }
      .site-lp .four__list li dl dd:before {
        content: 'A';
        font-weight: 700;
        left: 2px;
        color: #c3a87b; }
  .site-lp .fifth .twitter {
    margin: 40px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    border: #e8f1f5 8px solid;
    padding: 24px; }
    @media screen and (max-width: 768px) {
      .site-lp .fifth .twitter {
        margin: 24px 0 0;
        display: block;
        border-width: 2px;
        padding: 15px; } }
    .site-lp .fifth .twitter figure {
      width: 400px; }
      @media screen and (max-width: 768px) {
        .site-lp .fifth .twitter figure {
          width: auto; } }
      .site-lp .fifth .twitter figure img {
        width: 100%; }
    .site-lp .fifth .twitter dl {
      width: calc(100% - 400px);
      padding: 0 0 0 48px; }
      @media screen and (max-width: 768px) {
        .site-lp .fifth .twitter dl {
          width: auto;
          padding: 16px 0 0; } }
      .site-lp .fifth .twitter dl dt {
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 8px; }
        @media screen and (max-width: 768px) {
          .site-lp .fifth .twitter dl dt {
            color: #003f98; } }
      .site-lp .fifth .twitter dl dd {
        font-size: 14px; }
        @media screen and (max-width: 768px) {
          .site-lp .fifth .twitter dl dd {
            font-size: 13.5px; } }
  .site-lp .six iframe {
    vertical-align: bottom;
    height: 340px;
    filter: grayscale(1);
    width: 100%; }
    @media screen and (max-width: 768px) {
      .site-lp .six iframe {
        height: 200px; } }
  .site-lp .lpfoot {
    background: #003f98;
    color: #FFF;
    position: relative;
    z-index: 10;
    padding: 0 0 48px; }
    @media screen and (max-width: 768px) {
      .site-lp .lpfoot {
        padding: 0 0 24px; } }
    .site-lp .lpfoot a {
      color: #FFF; }
    .site-lp .lpfoot .pagetop {
      padding: 15px;
      display: block;
      text-align: center;
      background: #003784; }
    .site-lp .lpfoot__menu {
      padding: 48px 0; }
      @media screen and (max-width: 768px) {
        .site-lp .lpfoot__menu {
          padding: 24px 15px; } }
      .site-lp .lpfoot__menu ul {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center; }
        @media screen and (max-width: 768px) {
          .site-lp .lpfoot__menu ul {
            display: block;
            border: rgba(255, 255, 255, 0.12) 1px solid;
            border-radius: 4px; } }
        .site-lp .lpfoot__menu ul li {
          margin: 0 24px 0 0;
          padding: 0 24px 0 0;
          border-right: rgba(255, 255, 255, 0.12) 1px solid;
          line-height: 1; }
          @media screen and (max-width: 768px) {
            .site-lp .lpfoot__menu ul li {
              margin: 0;
              padding: 0;
              border: none;
              border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
              .site-lp .lpfoot__menu ul li a {
                display: block;
                padding: 15px;
                position: relative; }
                .site-lp .lpfoot__menu ul li a:before {
                  font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                  content: "\f054";
                  position: absolute;
                  top: 50%;
                  right: 15px;
                  transform: translateY(-50%);
                  font-size: 11px;
                  opacity: .32; } }
          .site-lp .lpfoot__menu ul li:last-child {
            margin: 0;
            padding: 0;
            border: none; }
    .site-lp .lpfoot .copy {
      padding: 0 15px;
      text-align: center;
      font-size: 9px;
      display: block; }
  .site-lp .scale-enter-active, .site-lp .scale-leave-active {
    transition: 800ms cubic-bezier(0.85, 0, 0.15, 1); }
  .site-lp .scale-enter, .site-lp .scale-leave-to {
    opacity: 0;
    transform: scale(1.04); }

#WRAPPER, #FOOTER {
  position: relative; }

.motion-animate {
  opacity: 0;
  transform: translateY(-40px);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .motion-animate.active {
    transform: translateY(0);
    opacity: 1; }

.MODULE.wrap {
  padding: 62px 0; }
  @media screen and (max-width: 768px) {
    .MODULE.wrap {
      padding: 42px 12px; } }
  .MODULE.wrap.no-top {
    padding-top: 0; }

.MODULE.bg {
  background: url("../../images/common/back_01.png") repeat center #214975; }

.MODULE.bg2 {
  background: url("../../images/common/back_02.jpg") repeat center;
  position: relative;
  overflow: hidden; }

.MODULE.bg4 {
  background: url("../../images/common/back_03.jpg") repeat center;
  position: relative;
  overflow: hidden; }

.MODULE.bg3 {
  background: rgba(151, 160, 172, 0.75);
  position: relative;
  overflow: hidden; }

.MODULE.takeL:before {
  content: "";
  background: url("../../images/common/back_02_1.png") no-repeat;
  width: 226px;
  height: 182px;
  position: absolute;
  top: 0;
  left: 0; }

.MODULE.takeR:after {
  content: "";
  background: url("../../images/common/back_02_2.png") no-repeat;
  width: 182px;
  height: 546px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1; }

.MODULE h3 {
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .MODULE h3 {
      margin: 0 0 24px; } }
  .MODULE h3.center {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    padding: 0 0 32px;
    background: url("../../images/common/icon_sasa.png") no-repeat bottom center;
    background-size: 38px 24px; }
    @media screen and (max-width: 768px) {
      .MODULE h3.center {
        font-size: 22px;
        padding: 0 0 24px; } }
    .MODULE h3.center.white {
      color: #FFF;
      background: url("../../images/common/icon_sasa_w.png") no-repeat bottom center; }
    .MODULE h3.center.w {
      background: url("../../images/common/icon_sasa_w.png") no-repeat bottom center; }
    .MODULE h3.center.eng {
      font-size: 14px; }
      .MODULE h3.center.eng:before {
        content: attr(data-eng);
        display: block;
        font-size: 30px; }
  .MODULE h3.triangle {
    color: #FFF;
    font-size: 26px;
    font-weight: 600;
    padding: 10px 0 0 23px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .MODULE h3.triangle {
        font-size: 20px;
        padding: 2px 0 0 16px; } }
    .MODULE h3.triangle:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 23px 23px 0 0;
      border-color: #0d3b55 transparent transparent transparent;
      position: absolute;
      top: 0;
      left: 0; }
      @media screen and (max-width: 768px) {
        .MODULE h3.triangle:before {
          border-width: 16px 16px 0 0; } }
    .MODULE h3.triangle.num:after {
      content: attr(data-num);
      display: block;
      color: #0d3b55;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: 700;
      padding: 8px 0 0; }
      @media screen and (max-width: 768px) {
        .MODULE h3.triangle.num:after {
          font-size: 14px;
          padding: 0;
          line-height: 1.2; } }
  .MODULE h3.left {
    color: #0d3b55;
    font-weight: 700;
    border-bottom: 1px solid #DDD; }
    @media screen and (max-width: 768px) {
      .MODULE h3.left {
        color: #FFF; } }
    .MODULE h3.left:after {
      content: attr(data-eng);
      display: block;
      color: #FFF;
      font-size: 40px;
      line-height: 1.5; }
      @media screen and (max-width: 768px) {
        .MODULE h3.left:after {
          font-size: 28px; } }

.MODULE .tableStyle {
  width: 100%;
  border-collapse: collapse;
  text-align: left; }
  .MODULE .tableStyle th {
    padding: 15px;
    vertical-align: middle;
    text-align: left;
    width: 25%; }
  .MODULE .tableStyle td {
    padding: 15px; }
  @media screen and (max-width: 768px) {
    .MODULE .tableStyle th, .MODULE .tableStyle td {
      padding: 10px 0;
      width: 100%;
      display: block; }
    .MODULE .tableStyle th {
      border-bottom: 1px solid #DDD; } }

#SecondView {
  width: 100%;
  height: 317px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  background: url("../../images/common/back_01.png") repeat center #214975;
  align-items: center;
  justify-content: center;
  position: relative; }
  @media screen and (max-width: 768px) {
    #SecondView {
      height: 210px; } }
  #SecondView h2 {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #FFF;
    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.44); }
    @media screen and (max-width: 768px) {
      #SecondView h2 {
        font-size: 12px;
        padding-top: 28px; } }
    #SecondView h2:before {
      content: attr(data-eng);
      font-size: 48px;
      display: block;
      text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.44); }
      @media screen and (max-width: 768px) {
        #SecondView h2:before {
          font-size: 28px;
          line-height: 1.5; } }
  #SecondView nav {
    width: 100%;
    position: absolute;
    bottom: -32px;
    left: 0;
    z-index: 10; }
    @media screen and (max-width: 768px) {
      #SecondView nav {
        bottom: -26px;
        padding: 0 12px; } }
    #SecondView nav ol li {
      list-style: none;
      color: #FFF;
      background: url("../../images/common/arrow_2x.png") no-repeat right center;
      background-size: 4px;
      padding: 0 16px 0 0;
      margin: 0 12px 0 0;
      float: left; }
      @media screen and (max-width: 768px) {
        #SecondView nav ol li {
          font-size: 10px; } }
      #SecondView nav ol li:last-child {
        margin: 0;
        padding: 0;
        background: none; }
      #SecondView nav ol li a {
        color: #FFF; }
        #SecondView nav ol li a:hover {
          opacity: .88; }
