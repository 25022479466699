.firstview {
	width: 100%;
	height: 100vh !important;
	background: #000;
	position: relative;
	z-index: 2;
	&:before{
	    content: '';
	    background: rgba(#000,.64);
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 2;
	}
    .content{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        @include flex();
        align-items: center;
        justify-content: center;
		&:before {
			content: "";
			background: url("../../../images/index/firstView_left.png") no-repeat;
			width: 480px;
			height: 726px;
			position: absolute;
			bottom: 0; left: 0;
			@media screen and (max-width:768px){
				content: none;
			}
		}
		&:after {
			content: "";
			background: url("../../../images/index/firstView_right.png") no-repeat;
			width: 531px;
			height: 735px;
			position: absolute;
			bottom: 0; right: 0;
			@media screen and (max-width:768px){
				content: none;
			}
		}
		.motion{
			max-width: 1200px;
			min-width: 800px;
			text-align: center;
			position: relative;
			@media screen and (max-width:768px){
				max-width: 100%;
				min-width: 90%;
				width: 95%;
			}
			&:before,&:after {
				content: "";
				background: url("../../../images/index/waku.png") no-repeat;
				width: 266px;
				height: 54px;
				position: absolute;
				left: 50%;
				transform: translate(-50%,0);
			}
			&:before {
				top: -60px;
			}
			&:after {
				bottom: -80px;
			}
			h2 {
				font-size: 42px;
				font-weight: 700;
				line-height: 1.5;
				color: #FFF;
				padding: 45px 0 25px;
				@media screen and (max-width:768px){
					font-size: 28px;
					padding: 20px 0;
				}
			}
			p {
				font-size: 21px;
				color: #FFF;
				@media screen and (max-width:768px){
					font-size: 18px;
				}
			}
			.logo {
				img {
					width: 80px;
					height: auto;
				}
			}
			.illust {
				position: absolute;
				bottom: -80px;
				right: 220px;
				z-index: 3;
			}
		}
    }
	#three {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		opacity: .64;
	}
	.scroll{
		display:block;
		width:64px;
		position:absolute;
		left:50%;
		bottom:40px;
		margin-left:-32px;
		color:#FFF;
		font-size:10px;
		letter-spacing:0.24em;
		text-align:center;
		/* SP style */
		@media screen and (max-width:768px){
			font-size:8px;
		}
		&:before,
		&:after{
			content:'';
			display:block;
			margin:0 auto;
		}
		&:before{
			width:23px;
			height:44px;
			border-radius: 40px;
			border:#FFF 1px solid;
			margin-bottom:12px;
			/* SP style */
			@media screen and (max-width:768px){
				width: 20px;
				height: 34px;
				margin-bottom: 6px;
			}			
		}
		&:after{
			width:1px;
			height:48px;
			position:absolute;
			bottom:48px;
			left:49%;
			background:#FFF;
			transform: rotate(180deg);
			animation: scroll 1.6s ease 0s infinite;
			@keyframes scroll {
				0% {height:1px; bottom:96px;}
				40% {height:48px; bottom:48px;}
				80% {height:0; bottom:48px;}
				100% {height:0; bottom:48px;}
			}

		}
	}
	
    .hide{
        display: none;
    }
}
