.MERIT{
    h3.bamboo{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        background: url("../../../images/common/icon_sasa.png") no-repeat center bottom;
        padding: 0 0 32px;
        margin: 0 0 32px;
        background-size: 38px;
        @media screen and (max-width:768px){
            font-size: 16px;
            margin: 0 0 24px;
        }
        strong{
            color: $link;
        }
    }
    &.first{
        p.context{
            text-align: center;
            color: #FFF;
            font-size: 32px;
            font-weight: 700;
            padding: 40px 0 24px;
            @media screen and (max-width:768px){
                font-size: 18px;
                padding: 32px 16px 8px;
                br{
                    display: none;
                }
            }
        }
    }
    &.second{
        &.border{
            border-top: #FFF 1px solid;
            border-bottom: #FFF 1px solid;
        }
        .meritcatch{
            margin: 0 0 32px;
            border: $border 1px solid;
            background: #FFF;
            padding: 8px;
            @media screen and (max-width:768px){
                margin: 0 0 24px;
                border: none;
                padding: 0;
            }
            img{
                width: 100%;
            }
        }
        ul.questions{
            margin: 0 0 32px;
            @media screen and (max-width:768px){
                margin: 0 0 24px;     
            }
            > li{
                list-style: none;
                margin: 0 0 24px;
                background: rgba(#FFF,.64);
                border: rgba(#000,.12) 1px solid;
                border-radius: 3px;
                padding: 24px;
                @media screen and (max-width:768px){
                    padding: 16px;     
                }
                &:last-child{
                    margin: 0;
                }
                dl{
                    dt,dd{
                        position: relative;
                        padding-left: 48px;
                        &:before{
                            $size:32px;
                            width: $size;
                            height: $size;
                            line-height: $size;
                            border-radius: 50%;
                            text-align: center;
                            font-size: 22px;
                            color: #FFF;
                            font-weight: 700;
                            position: absolute;
                            top: 0;
                            left: 0;
                            @media screen and (max-width:768px){
                                $sp: 28px;
                                width: $sp;
                                height: $sp;
                                line-height: $sp;
                                font-size: 16px;
                            }
                        }
                    }
                    dt{
                        font-size: 20px;
                        font-weight: 700;
                        padding-bottom: 16px;
                        margin: 0 0 16px;
                        border-bottom: rgba(#000,.12) 1px solid;
                        @media screen and (max-width:768px){
                            font-size: 16px;   
                        }
                        &:before{
                            content: 'Q';
                            background: $blue;
                        }
                    }
                    dd{
                        text-align: justify;
                        &:before{
                            content: 'A';
                            background: $link;
                        }
                    }
                }
            }
        }
        ul.points{
            @include flex();
            @media screen and (max-width:768px){
                display: block;     
            }
            > li{
                list-style: none;
                width: 32%;
                padding: 60px 0 0;
                box-shadow: 0 0 24px rgba(#000,.08);
                @include flex();
                @media screen and (max-width:768px){
                    width: auto;
                    margin: 0 0 16px;
                    &:last-child{
                        margin: 0;
                    }
                }
                @for $i from 1 through 3{
                    &:nth-child(#{$i}){
                        background: url("../../../images/index/back_0#{$i}.png") no-repeat center;
                        background-size: cover;
                    }
                }
                dl{
                    background: #FFF;
                    padding: 24px;
                    width: 100%;
                    text-align: center;
                    @media screen and (max-width:768px){
                        padding: 15px;     
                    }
                    dt{
                        color: $link;
                        font-size: 20px;
                        font-weight: 700;
                    }
                }
            }
        }
        .mes{
            text-align: center;
            margin: 48px 0 0;
            font-size: 17px;
            @media screen and (max-width:768px){
                font-size: 14px; 
                margin: 24px 0 0;
                text-align: left;
            }
            strong{
                color: $link;
            }
        }
    }
    .str{
        text-align: center;
        margin: 24px 0 0;
        background: $link;
        color: #FFF;
        font-size: 24px;
        padding: 8px 0;
        font-weight: 700;
        margin: 0 0 16px;
        @media screen and (max-width:768px){
            padding: 8px 15px;
            font-size: 18px;
        }
    }
    .table-merit{
        width: 100%;
        border-collapse: collapse;
        border: rgba(#000,.12) 1px solid;
        background: #FFF;
        thead{
            tr{
                td{
                    color: $link;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
        thead,tbody{
            tr{
                td{
                    width: 50%;
                    border: rgba(#000,.12) 1px solid;
                    padding: 8px 15px;
                    text-align: center;
                }
            }
        }
    }
    dl.desc{
        dt{
            text-align: center;
            background: $link;
            color: #FFF;
            font-size: 24px;
            padding: 8px 0;
            font-weight: 700;
            @media screen and (max-width:768px){
                padding: 8px 15px;
                font-size: 16px;
            }
        }
        dd{
            background: #FFF;
            padding: 32px 64px;
            @media screen and (max-width:768px){
                padding: 15px;
                font-size: 14px;
            }
            .mb-4{
                margin: 0 0 16px;
            }
        }
    }
}