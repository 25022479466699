.CONTACT {
	&.first {
		p.center {
			text-align: center;
		}
		.form {
			background: #FFF;
			padding: 45px;
			border-radius: 10px;
			@media screen and (max-width:768px){
				padding: 25px;
			}
			.tableStyle {
				th {
					&.hiss {
						position: relative;
						&:after {
							content: "必須項目";
							display: inline-block;
							color: #FFF;
							background: $link;
							padding: 2px 10px;
							position: absolute;
							top: 50%; right: 0;
							transform: translate(0,-50%);
						}
					}
				}
			}
			.btnArea {
				margin: 32px 0 0;
				text-align: center;
			}
			input[type=text],
			input[type=tel],
			input[type=email],
			textarea,select {
				font-size: 15px;
				background: #f5f5f5;
				width: 100%;
				border: none;
				padding: 5px 10px;
				line-height: 1.8;
				@media screen and (max-width:768px){
					display: block;
					margin: 0 0 5px;
					width: 100% !important;
					background: none;
					border: 1px solid $border;
				}
			}
			textarea {
				height: 200px;
			}
			input[type="submit"],input[type="reset"] {
				border: none;
				color: #ffffff;
				background: $blue;
				@include font;
				font-size: 18px;
				display: inline-block;
				padding: 10px 0;
				margin: 0 1%;
				width: 20%;
				cursor: pointer;
				@include transition(200ms);
				&:hover{
					background: lighten($blue, 5%);
				}
				@media screen and (max-width:768px){
					display: block;
					padding: 12px 0;
					margin: 0 0 10px;
					font-size: 17px;
					font-weight: 700;
					width: 100%;
					text-align: center;
				}
			}
			
		}
	}
}