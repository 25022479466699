.VISION{
    position: relative;
    &:before{
        content: '';
        width: 100%;
        height: 34px;
        background: rgba($blue,.36);
        position: absolute;
        top: 0;
        left: 0;
    }
    .bg_sikkui{
        background: url("../../../images/vision/bg.jpg") repeat;
        background-size: 256px;
    }
    .bg_sikkui_light{
        background: url("../../../images/vision/bg_light.jpg") repeat;
        background-size: 256px;
    }
    
    .vision-text{
        position: relative;
        @media screen and (max-width:768px){
            margin: 0 8px;   
        }
        $size: 1px;
        &:before,
        &:after{
            content: '';
            width: $size;
            height: 1px;
            background: $blue;
            position: absolute;
            top: 0;
            left: 0;
            animation: tate ease-in-out 1200ms infinite;
            z-index: 2;
        }
        &:after{
            left: auto;
            right: 0;
            animation: tate ease-in-out reverse 1200ms infinite;
        }
        &__crone{
            position: relative;
            padding: 12px;
            &:before,
            &:after{
                content: '';
                width: 1px;
                height: $size;
                background: $blue;
                position: absolute;
                top: 0;
                left: 0;
                animation: yoko ease-in-out 1600ms infinite;
                z-index: 2;
            }
            &:after{
                top: auto;
                bottom: 0;
                animation: yoko ease-in-out reverse 1600ms infinite;
            }
        }
        &__dl{
            text-align: center;
            font-weight: 700;
            font-size: 28px;
            background: rgba(#FFF,.64);
            padding: 40px;
            color: darken($blue, 8%);
            @media screen and (max-width:768px){
                font-size: 15px;
                padding: 15px 24px;
            }
        }
        @keyframes yoko{
            0%{
                width: 1px;
                left: 0;
            }
            50%{
                width: 100%;
                left: 0;
            }
            100%{
                width: 0%;
                left: 100%;
            }
        }
        @keyframes tate{
            0%{
                height: 1px;
                top: 0;
            }
            50%{
                height: 100%;
                top: 0;
            }
            100%{
                height: 0%;
                top: 100%;
            }
        }
    }
    
    .visionbox{
        position: relative;
        &:before{
            content: '';
            background: url("../../../images/index/illust_wa_01.png") no-repeat right bottom;
            background-size: contain;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: .48;
            @media screen and (max-width:768px){
                display: none;
            }
        }
        &--rvs{
            &:before{
                right: auto;
                left: 0;  
                background: url("../../../images/index/illust_wa_01.png") no-repeat right bottom;
                background-size: contain;
                transform: rotateY(180deg);
                opacity: .24;
            }
        }
        &__bg{
            width: 50%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            background-position: center;
            top: 0;
            left: 0;
            @media screen and (max-width:768px){
                position: relative;
                width: auto;
                height: 200px;
                display: block;
            }
            &--rvs{
                left: auto;
                right: 0;
            }
        }
        &__txt{
            padding: 80px 0;
            padding-left: 50px;
            margin: 0 0 0 50%;
            @media screen and (max-width:768px){
                padding: 0;
                margin: 0;
                padding: 0;
            }
            &--rvs{
                margin: 0 50% 0 0;
                padding-left: 0;
                padding-right: 50px;
                @media screen and (max-width:768px){
                    margin: 0;
                    padding: 0;
                }
            }
            dt{
                font-weight: 700;
                font-size: 20px;
                letter-spacing: .08em;
                @media screen and (max-width:768px){
                    font-size: 17px;
                    text-align: center;
                    margin: 20px 0 0;
                }
                &:before{
                    content: attr(data-eng);
                    font-size: 48px;
                    display: inline-block;
                    margin: 0 16px 0 0;
                    @media screen and (max-width:768px){
                        display: block;
                        margin: 0 0 2px;
                        font-size: 28px;
                        line-height: 1;
                    }
                }
            }
            dd{
                background: rgba(#FFF,.88);
                padding: 20px;
                padding-left: 80px;
                margin: 20px 0 0;
                font-weight: 700;
                font-size: 17px;
                line-height: 1.6;
                position: relative;
                overflow: hidden;
                @media screen and (max-width:768px){
                    padding: 15px;
                    margin: 15px 0 0;
                    font-size: 15px;
                    padding-left: 72px;
                }
                &:after{
                    content: '';
                    width: 16px;
                    height: 16px;
                    background: #bed097;
                    position: absolute;
                    top: -8px;
                    left: -8px;
                    transform: rotate(45deg);
                }
                &:before{
                    content: '';
                    background: url("../../../images/common/icon_sasa.png") no-repeat;
                    background-size: 37px;
                    width: 37px;
                    height: 24px;
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    transform: translateY(-50%);
                    @media screen and (max-width:768px){
                        left: 15px;   
                    }
                }
            }
        }
    }
        
    .glowttl{
        margin: 0 0 32px;
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        @media screen and (max-width:768px){
            font-size: 20px;   
        }
    }
    .glowlist{
        @include flex();
        @media screen and (max-width:768px){
            display: block;   
        }
        li{
            width: 23.5%;
            padding: 20px;
            font-size: 16px;
            background: rgba(#FFF,.88);
            text-align: center;
            font-weight: 700;
            @include flex();
            align-items: center;
            justify-content: center;
            border-top: #EEE 4px solid;
            @media screen and (max-width:768px){
                width: auto;
                padding: 15px 24px;
                margin: 0 0 15px;
                &:last-child{
                    margin: 0;
                }
            }
            &:nth-child(1){
                border-color: #d50424;
            }
            &:nth-child(2){
                border-color: #e3ed00;
            }
            &:nth-child(3){
                border-color: #66b82a;
            }
            &:nth-child(4){
                border-color: #4894d2;
            }
        }
    }
}