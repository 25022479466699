.SINGLE {
	&.list {
		.oneClum {
			>li {
				list-style: none;
				background: #FFF;
				padding: 15px;
				margin: 0 0 2%;
				border-radius: 10px;
				@include flex;
				align-items: center;
				justify-content: flex-start;
				@media screen and (max-width:768px){
					width: 100%;
					padding: 15px;
					display: block;
				}
				time {
					width: 10%;
					text-align: center;
					background: $blue;
					font-size: 12px;
					padding: 3px 15px;
					color: #FFF;
					font-weight: 700;
					@media screen and (max-width:768px){
						display: block;
						width: 30%;
					}
				}
				span {
					width: 70%;
					padding: 0 12px;
					font-size: 16px;
					@media screen and (max-width:768px){
						display: block;
						width: 100%;
						padding: 12px 0;
						font-size: 14px;
					}
				}
				a {
					width: 20%;
					display: block;
					border-radius: 6px;
					background: url("../../../images/common/arw_02_2x.png") no-repeat right 30px center #6c9dd3;
					background-size: 19px 6px;
					color: #FFF;
					padding: 8px;
					text-align: center;
					@media screen and (max-width:768px){
						width: 100%;
						
					}
				}
			}
		}
	}
	
	&.detail {
		.postData {
			background: #FFF;
			padding: 45px;
			border-radius: 10px;
            font-weight: 400;
			@media screen and (max-width:768px){
				padding: 25px;
			}
            strong{
                font-weight: 700;
            }
		}
	}
}
