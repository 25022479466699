.site-privacy{
	.text{
        margin: 0 0 48px;
	}
	.margin{
		margin: 0 0 24px;
	}
	ol{
		li{
			list-style: inside decimal;
			margin: 0 0 3px;
			&:last-child{
				margin: 0;
			}
			> ol{
				padding: 12px 0 0 2em;
			}
		}
	}
	.table-res-style{
		display: block;
		tbody,tr,th,td{
			display: block;
		}
		tr{
			margin: 0 0 64px;
			&:last-child{
				margin: 0;
			}
			th,td{
				text-align: left;
			}
			th{
				background: #f2f2f2;
				padding: 12px;
				font-weight: 700;
				margin: 0 0 12px;
				border-radius: 6px;
			}
		}
	}
}