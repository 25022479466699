.INDEX {
	&.first {
		position: relative;
		background: url("../../images/common/back_01.png") repeat center #214975;
		width: 100%;
		height: 390vh;
		padding: 25vh 0;
		overflow: hidden;
		z-index: 1;
		@media screen and (max-width:768px){
			background: none;
			height: 430vh;
		}
		&:before {
			content: url("../../../images/index/illust_wa_02.png");
			position: absolute;
			bottom: 30vh; left: 0;
			@media screen and (max-width:768px){
				content: none;
			}
		}
		&:after {
			content: url("../../../images/index/illust_wa_01.png");
			position: absolute;
			top: 30vh; right: 0;
			@media screen and (max-width:768px){
				background-size: cover;
				width: 371px; height: 467px;
			}
		}
		.bugfix-bg{
			color: rgba(#FFF,.04);
			font-size: 200px;
			line-height: 1;
			position: fixed;
			top: 20%;
			left: 20px;
			@media screen and (max-width:768px){
				font-size: 70px;
				width: 100%;
				height: 100%;
				position:fixed;
				display: block;
				top: 0;
				left: 0;
				background: url("../../images/common/back_01.png") repeat center #214975;
				z-index: -1;
			}
		}
		#index-circle{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			overflow: hidden;
			@media screen and (max-width:768px){
				display: none;
			}
			.act{
				opacity: 0;
                position: absolute;
				filter: blur(10px);
				transform: translateY(-50%);
				transition: opacity 800ms ease, filter 600ms ease;
				&.rp1{
					top: 0vh;
					left: 40px;
				}
				&.rp2{
					top: 100vh;
					right: 40px;
				}
				&.rp3{
					top: 200vh;
					left: 40px;
				}
				&.rp4{
					top: 300vh;
					right: 40px;
				}
				&.active{
					opacity: 1;
					filter: blur(0);
				}
				> img{
					width: 640px;
					height: 640px;
				}
			}
		}
		#index-pins{
			z-index: 2;
		}
		p {
			text-align: center;
			font-size: 48px;
			color: #FFF;
			transform: translateY(-50%);
			opacity: 0;
			transition: opacity 500ms ease;
			@media screen and (max-width:768px){
				padding: 0 15px;
				font-size: 20px;
				white-space: nowrap;
			}
			&.active{
				opacity: 1;
			}
		}
	}
	&.second {
		position: relative;
		z-index: 2;
		.flex {
			@include flex;
			align-items: flex-start;
			position: relative;
			>li {
				list-style: none;
				width: 23%;
				@media screen and (max-width:768px){
					width: 49%;
					height: 180px !important;
					margin: 0 0 2%;
					position: static !important;
				}
				a {
					@include flex;
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;
					border-radius: 10px;
					box-shadow: 0 0 15px rgba(#000,.35);
					position: relative;
					&:hover {
						&:before {
							content: "";
							width: 100%;
							height: 100%;
							background: rgba(#000,.55);
							border-radius: 10px;
							position: absolute;
							top: 0; left: 0;
						}
					}
					&.over {
						&:hover {
							&:before {
								content: "Coming Soon";
								background: rgba(#000,.85);
								color: #FFF;
								font-size: 24px;
								z-index: 2;
								width: 100%;
								@include flex;
								justify-content: center;
								align-items: center;
							}
						}
					}
				}
				span {
					display: block;
					text-align: center;
					color: #FFF;
					font-size: 24px;
					padding: 0 0 50px;
					background: url("../../../images/common/arw_01.png") no-repeat center bottom;
					background-size: 40px;
					position: relative;
					@media screen and (max-width:768px){
						font-size: 18px;
						padding: 0 0 40px;

					}
					&:after {
						content: attr(data-eng);
						display: block;
						font-size: 12px;
						@media screen and (max-width:768px){
							font-size: 10px;
						}
					}
				}

				@for $i from 1 through 6{
					&:nth-child(#{$i}){
						a {
							background: url("../../../images/index/back_0#{$i}.png") no-repeat center;
							background-size: cover;
						}
					}
				}
				&:nth-child(1) {
					height: 278px;
                    a{
                        background: url("../../../images/index/back_01_b.png") no-repeat center;
                        background-size: cover;
                    }
				}
				&:nth-child(2) {
					height: 205px;
				}
				&:nth-child(3) {
					height: 205px;
				}
				&:nth-child(4) {
					height: 470px;
				}
				&:nth-child(5) {
					height: 166px;
					position: absolute;
					bottom: 0; left: 0;
				}
				&:nth-child(6) {
					height: 235px;
					width: 48.6%;
					position: absolute;
					bottom: 0; left: 25.6%;
				}

			}
		}
	}
	&.third {
		position: relative;
		z-index: 3;
		overflow: hidden;
		ul {
			>li {
				box-shadow: 0 0 15px rgba(#000,.15);
				background: url("../../../images/index/recruit.jpg") no-repeat center;
				background-size: cover;
				padding: 30px 20px 75px;;
				color: #FFF;
				position: relative;
				@media screen and (max-width:768px){
					padding: 20px 20px 65px;
				}
				span {
					display: inline-block;
					width: 70px;
					height: 70px;
					position: absolute;
					top: 0; left: 0;
					background: url("../../../images/index/recruit_new.png");
					i {
						display: inline-block;
						transform: rotate(-45deg);
						position: absolute;
						top: 12px; left: 4px;
					}
				}
				dl {
					@include flex;
					align-items: center;
					dt {
						width: 30%;
						text-align: right;
						border-right: 1px solid #FFF;
						padding-right: 20px;
						font-size: 12px;
						font-weight: 600;
						margin: 0 0 5px;
						@media screen and (max-width:768px){
							font-size: 11px;
						}
					}
					dd {
						width: 70%;
						padding-left: 20px;
						font-size: 18px;
						font-weight: 700;
						margin: 0 0 5px;
						@media screen and (max-width:768px){
							font-size: 15px;
						}
					}

				}
				a {
					display: inline-block;
					font-size: 12px;
					color: $normal;
					background: url("../../../images/index/icon_right.png") no-repeat right 14px center #FFF;
					padding: 10px 56px 10px 37px;
					position: absolute;
					bottom: 20px; right: 20px;
				}
			}
		}
		.swiper-container {
			overflow: inherit !important;
		}
		.swiper-pagination {
			bottom: -40px !important;
			@media screen and (max-width:768px){
				display: none;
			}
		}
		.btnarea{
			text-align: center;
			a{
				background: #FFF;
				color: $blue;
				display: inline-block;
				padding: 14px 80px;
				font-size: 22px;
				font-weight: 700;
				position: relative;
				transition: transform 200ms;
				@media screen and (max-width:768px){
					width: 92%;
					margin: 0 auto;
					display: block;
					padding: 14px 0;
					font-size: 18px;
				}
				&:after{
					content: '';
					width: 100%;
					height: 100%;
					border: #FFF 1px solid;
					position: absolute;
					top: 0;
					left: 0;
					transform: translateX(6px) translateY(6px);
					pointer-events: none;
					transition: transform 200ms;
				}
				i{
					display: inline-block;
					margin: 0 0 0 8px;
					font-size: 14px;
					transform: translateY(-4px);
					@media screen and (max-width:768px){
						transform: translateY(-2px);
					}
				}
				.syachi{
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					z-index: 10;
					pointer-events: none;
					&__left,
					&__right{
						position: absolute;
						top: -36px;
						width: 64px;
						@media screen and (max-width:768px){
							width: 40px;
							top: -24px;
						}
					}
					&__left{
						left: -20px;
						transform-origin: right bottom;
						animation: syachi-left 300ms infinite;
						@media screen and (max-width:768px){
							left: -8px;	
						}
						@keyframes syachi-left{
							0%{
								transform: rotate(0deg);
							}
							25%{
								transform: rotate(8deg);
							}
							50%{
								transform: rotate(0deg);
							}
							75%{
								transform: rotate(-8deg);
							}
							100%{
								transform: rotate(0deg);
							}
						}
					}
					&__right{
						right: -20px;
						transform-origin: left bottom;
						animation: syachi-right 300ms infinite;
						@media screen and (max-width:768px){
							right: -8px;	
						}
						@keyframes syachi-right{
							0%{
								transform: rotate(0deg);
							}
							25%{
								transform: rotate(-8deg);
							}
							50%{
								transform: rotate(0deg);
							}
							75%{
								transform: rotate(8deg);
							}
							100%{
								transform: rotate(0deg);
							}
						}

					}
				}
				&:hover{
					transform: translateX(4px) translateY(4px);
					.syachi{
						img{
							opacity: 1;
						}
						.syachi__left{
							animation: syachi-left 100ms infinite;
						}
						.syachi__right{
							animation: syachi-right 100ms infinite;
						}
					}
					&:after{
						transform: translateX(0) translateY(0);
					}
				}
			}
		}
	}


	&.news{
		$height:240px;
		position: relative;
		z-index: 3;
		.flex{
		position: relative;
		z-index: 2;
		  @media screen and (min-width:769px){
				  @include flex();
		  }
		}
		.newsbox{
			@include flex();
			width: calc(100% - 320px);
			@media screen and (max-width:768px){
				width: auto;
				display: block;
			}
		}
		.twitterbox{
			width: 280px;
			@media screen and (max-width:768px){
				width: auto;
				margin: 24px 0 0;
			}
		}
		.list{
			padding:0 40px;
			height:$height;
			width: calc(100% - 130px);
			overflow:auto;
			background: rgba(#FFF,.80);
			padding: 24px;
			@media screen and (max-width:768px){
				padding: 12px;
				width: 100%;
				border-left: none;
				height: 200px;
			}
			li{
				list-style:none;
				border-bottom:$border 1px solid;
				padding:0 0 13px;
				margin:0 0 13px;
				@media screen and (max-width:768px){
					padding: 0 0 8px;
					margin: 0 0 8px;
				}
				a{
					color:$normal;
					&:hover{
						text-decoration:underline;
					}
				}
				time{
					background:$ocher;
					padding:5px 16px;
					font-size:10px;
					border-radius: 4px;
					margin:0 16px 0 0;
					display:inline-block;
					@media screen and (max-width:768px){
						display: block;
						width: 25%;
						padding: 2px 10px;
						text-align: center;
						margin-bottom: 3px;
					}
				}
				&:last-child{
					margin:0;
					padding:0;
					border:none;
				}
			}
		}
		.more{
			height:$height;
			width:110px;
			background: $blue;
			display:table;
			text-align:center;
			border-radius: 5px;
			cursor: pointer;
			color:#FFF;
			@include transition(200ms);
			  @media screen and (max-width:768px){
				width: 100%;
				height: auto;
				margin: 22px 0 0;
				padding: 20px 0;
			  }
			.icon{
				display:table-cell;
				vertical-align:middle;
				.icon_list{
					background: url("../../../images/index/news_icon.png") no-repeat center top;
					padding:24px 0 0;
					background-size: 24px;
					display:block;
				  @media screen and (max-width:768px){
					display: inline-block;
					background-position: left center;
					padding: 0 0 0 34px;
				  }
				}
			}
			&:hover{
				background:rgba($blue,.85);
			}
		}
	}
}
