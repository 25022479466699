@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#000;
$link:#b33e23;
$border:#DDD;

$blue:#0d3b55;
$ocher:#e2deb1;
$sky:#3ec1d2;
$orange:#ff9a02;

/* FONT
------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:70px;
$sp-head:60px;


@media screen and (min-width:769px){
	@include default(
		$width, //サイト幅
		15.5px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:768px){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@mixin opensans {
	font-family: 'Open Sans', sans-serif;
}

@import "common/header";
@import "common/footer";
@import "page/firstview";
@import "page/index";
@import "page/recruit";
@import "page/aboutus";
@import "page/service";
@import "page/contact";
@import "page/single";
@import "page/privacy";
@import "page/lp";
@import "page/merit";
@import "page/vision";
@import "page/blog";
@import "page/edit";
@import "lp/style";

#WRAPPER,#FOOTER{
    position: relative;
}
.motion-animate{
	opacity: 0;
	transform: translateY(-40px);
	transition: all .8s cubic-bezier(0.645, 0.045, 0.355, 1);
	&.active{
		transform: translateY(0);
		opacity: 1;
	}
}


.MODULE{
	&.wrap {
		padding: 62px 0;
		@media screen and (max-width:768px){
			padding: 42px 12px;
		}
		&.no-top {
			padding-top: 0;
		}
	}
	&.bg {
		background: url("../../images/common/back_01.png") repeat center #214975;
	}
	&.bg2 {
		background: url("../../images/common/back_02.jpg") repeat center;
		position: relative;
		overflow: hidden;
	}
	&.bg4 {
		background: url("../../images/common/back_03.jpg") repeat center;
		position: relative;
		overflow: hidden;
	}
	&.bg3 {
		background: rgba(#97a0ac,.75);
		position: relative;
		overflow: hidden;
	}
	&.takeL {
		&:before {
			content: "";
			background: url("../../images/common/back_02_1.png") no-repeat;
			width: 226px;
			height: 182px;
			position: absolute;
			top: 0; left: 0;
		}
	}
	&.takeR {
		&:after {
			content: "";
			background: url("../../images/common/back_02_2.png") no-repeat;
			width: 182px;
			height: 546px;
			position: absolute;
			bottom: 0; right: 0;
			z-index: -1;
		}
	}
	h3 {
		margin: 0 0 32px;
		@media screen and (max-width:768px){
			margin: 0 0 24px;
		}
		&.center {
			text-align: center;
			font-size: 30px;
			font-weight: 700;
			padding: 0 0 32px;
			background: url("../../images/common/icon_sasa.png") no-repeat bottom center;
			background-size: 38px 24px;
			@media screen and (max-width:768px){
				font-size: 22px;
				padding: 0 0 24px;
			}
			&.white {
				color: #FFF;
				background: url("../../images/common/icon_sasa_w.png") no-repeat bottom center;
			}
			&.w {
				background: url("../../images/common/icon_sasa_w.png") no-repeat bottom center;
			}
			&.eng {
				font-size: 14px;
				&:before {
					content: attr(data-eng);
					display: block;
					font-size: 30px;
				}
			}
		}
		&.triangle {
			color: #FFF;
			font-size: 26px;
			font-weight: 600;
			padding: 10px 0 0 23px;
			position: relative;
			@media screen and (max-width:768px){
				font-size: 20px;
				padding: 2px 0 0 16px;
			}
			&:before {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 23px 23px 0 0;
				border-color: $blue transparent transparent transparent;
				position: absolute;
				top: 0; left: 0;
				@media screen and (max-width:768px){
					border-width: 16px 16px 0 0;
				}
			}
			&.num {
				&:after {
					content: attr(data-num);
					display: block;
					color: $blue;
					@include opensans;
					font-size: 18px;
					font-weight: 700;
					padding: 8px 0 0;
					@media screen and (max-width:768px){
						font-size: 14px;
						padding: 0;
						line-height: 1.2;
					}
				}
			}
		}
		&.left {
			color: $blue;
			font-weight: 700;
			border-bottom: 1px solid $border;
			@media screen and (max-width:768px){
				color: #FFF;
			}
			&:after {
				content: attr(data-eng);
				display: block;
				color: #FFF;
				font-size: 40px;
				line-height: 1.5;
				@media screen and (max-width:768px){
					font-size: 28px;
				}
			}
		}
	}
	.tableStyle {
		width:100%;
		border-collapse: collapse;
		text-align: left;
		th {
			padding:15px;
			vertical-align: middle; text-align: left;
			width: 25%;
		}
		td {
			padding:15px;
		}
		@media screen and (max-width:768px){
			th,td {
				padding: 10px 0;
				width: 100%;
				display: block;
			}
			th {
				border-bottom: 1px solid $border;
			}
		}
	}
	
}

#SecondView {
	width: 100%;
	height: 317px;
	@include flex;
	background: url("../../images/common/back_01.png") repeat center #214975;
	align-items: center;
	justify-content: center;
	position: relative;
	@media screen and (max-width:768px){
		height: 210px;
	}
	h2 {
		text-align: center;
		font-size: 14px;
		font-weight: 700;
		color: #FFF;
		text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.44);
		@media screen and (max-width:768px){
			font-size: 12px;
			padding-top: 28px;
		}
		&:before {
			content: attr(data-eng);
			font-size: 48px;
			display: block;
			text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.44);
			@media screen and (max-width:768px){
				font-size: 28px;
				line-height: 1.5;
			}
		}
	}
	nav {
		width: 100%;
		position: absolute;
		bottom: -32px; left: 0;
		z-index: 10;
		@media screen and (max-width:768px){
			bottom: -26px;
			padding: 0 12px;
		}
		ol {
			li{
				list-style:none;
				color:#FFF;
				background: url("../../images/common/arrow_2x.png") no-repeat right center;
				background-size: 4px;
				padding:0 16px 0 0;
				margin:0 12px 0 0;
				float:left;
				@media screen and (max-width:768px){
					font-size: 10px;
				}
				&:last-child{
					margin:0;
					padding:0;
					background:none;
				}
				a{
					color:#FFF;
					&:hover{
						opacity:.88;
					}
				}
			}
		}
	}
}
