@charset "utf-8";

#HEADER {
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: $pc-head;
	padding: 0 30px;
	z-index: 100;
	@media screen and (max-width:768px){
		height: $sp-head;
		padding: 0 12px;
	}
	.logo {
		float: left;
    	margin: 15px 0 0 24px;
		@media screen and (max-width:768px){
			margin: 12px 0 0;
		}
		img {
			width: 266px;
			height: auto;
		}
	}
	
	// .gnavi
	.fullscreenmenu {
		.menu, .menu span {
			display: inline-block;
			@include transition;
			box-sizing: border-box;
		}
		.menu {
			position: fixed;
			top: 20px;
			right: 30px;
			width: 37px;
			height: 22px;
			z-index: 10;
			background: none;
			border: none;
			cursor: pointer;
			@media screen and (max-width:768px){
				width: 28px;
				right: 15px;
			}
			span {
				position: absolute;
				left: 0;
				width: 100%;
				height: 2px;
				background: #FFF;
				z-index: 10;
				&:nth-of-type(1) {
					top: 0px;
				}
				&:nth-child(2) {
					top: 10px;
				}
				&:nth-child(3) {
					bottom: 0px;
				}
			}
			&.active {
				span {
					&:nth-of-type(1) {
						transform: translateY(10px) rotate(45deg);
					}
					&:nth-child(2) {
						opacity: 0;
					}
					&:nth-child(3) {
						transform: translateY(-10px) rotate(-45deg);
					}
				}
			}
		}
		.gnavi {
			position: fixed;
			top: 0;
			right: 0;
			z-index: 10;
			width: 500px;
			height: 100vh;
			transform: translateX(100%);
			background: url("../../../images/common/back_01.png") repeat center rgba($blue,.98);
			@include transition;
			visibility: hidden;
			@include flex();
			align-items: center;
			justify-content: center;
			@media screen and (max-width:768px){
				width: 100%;	
			}
			&.active {
				right: 0;
				opacity: 1;
				transform: translateX(0);
				visibility: visible;
			}
			ul {
				padding: 15% 0;
				>li {
					list-style: none;
					a {
						display: block;
						@include transition(200ms);
						text-align: center;
						text-decoration: none;
						color: #FFF;
						font-size: 25px;
						margin: 25px 0;
						@media screen and (max-width:768px){
							font-size: 18px;
							margin: 22px 0;
							white-space: nowrap;
						}
						span {
							background: url("../../../images/common/icon_sasa_w.png") no-repeat left center;
							background-size: 38px 24px;
							padding-left: 50px;
							display: inline-block;
							text-align: left;
							@media screen and (max-width:768px){
								width: auto;
								min-width: 50%;
								max-width: 100%;
							}
						}
						&:hover {
							span {
								background: url("../../../images/common/icon_sasa.png") no-repeat left center;
								background-size: 38px 24px;
							}
							
						}
					}
				}
			}
		}
	}
	
	&.lp {
		.logo {
			img {
				width: 210px;
				height: auto;
			}
		}
		.fullscreenmenu {
			.menu {
				span {
					background: #000;
				}
			}
			.gnavi {
				background: $sky;
			}
		}
		
		
	}
	
}