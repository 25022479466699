@charset "utf-8";
#FOOTER {
	position: relative;
	background: #0d3b55;
	color: #FFF;
	overflow: hidden;
	z-index: 3;
	&:after {
		content: "";
		background: url("../../../images/common/ft_logo.png") no-repeat;
		width: 362px; height: 370px;
		position: absolute;
		top: -25%; right: 10%;
		@media screen and (max-width:768px){
			width: 181px; height: 185px;
			background-size: cover;
			top: 0px;
			right: auto;
			left: -20px;
		}
	}
	.display {
		@media screen and (max-width:768px){
			position: static;
		}
	}
	.logo {
		position: absolute;
		top: 130px; left: 30px;
		@media screen and (max-width:768px){
			top: 12px;
			left: auto;
			right: 12px;
		}
		img {
			width: 110px;
			height: auto;
			@media screen and (max-width:768px){
				width: 90px;
			}
		}
	}
	ul.mushi{
		width: 215px;
		height: 240px;
		position: absolute;
		bottom: 20px; left: 0;
		transform-origin: bottom right;
		transform: rotate(0deg);
		animation: wind 3s cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite;
		@keyframes wind {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(-16deg);
			}
		}
		@media screen and (max-width:768px){
			width: 145px;
			height: 140px;
			bottom: 12px;
			z-index: 5;
		}
		li{
			$size: 12px;
			list-style: none;
			background: #FFF;
			border-radius: 50%;
			position: absolute;
			@for $i from 1 through 7{
				&:nth-child(#{$i}){
					animation: mushi#{$i} $i * 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045) alternate infinite;
					@keyframes mushi#{$i} {
						0% {
							transform: scale(1) translateX(0) translateY(0) skewX(0) skewY(0);
						}
						50% {
							transform: scale(1) translateX($i + 2px) translateY($i + 2px) skewX($i + -4deg) skewY($i + -4deg);
						}
						100% {
							transform: scale(.88) translateX($i + 2px) translateY($i + 2px) skewX($i + 4deg) skewY($i + 4deg);
						}
					}
				}
			}
			&:nth-child(1){
				width: 12px;
				height: 12px;
				right: 0;
				bottom: 0;
				background: #602a76;
				@media screen and (max-width:768px){
					width: 6px;
					height: 6px;
				}
			}
			&:nth-child(2){
				width: 19px;
				height: 19px;
				right: 15px;
				bottom: 2px;
				background: #003d64;
				@media screen and (max-width:768px){
					width: 9px;
					height: 9px;
					right: 9px;
				}
			}
			&:nth-child(3){
				width: 25px;
				height: 25px;
				right: 42px;
				bottom: 8px;
				background: #4894d2;
				@media screen and (max-width:768px){
					width: 12px;
					height: 12px;
					right: 24px;
				}
			}
			&:nth-child(4){
				width: 34px;
				height: 34px;
				right: 64px;
				bottom: 28px;
				background: #66b82a;
				@media screen and (max-width:768px){
					width: 17px;
					height: 17px;
					right: 38px;
					bottom: 18px;
				}
			}
			&:nth-child(5){
				width: 47px;
				height: 47px;
				right: 77px;
				bottom: 64px;
				background: #e3ed00;
				@media screen and (max-width:768px){
					width: 23px;
					height: 23px;
					right: 50px;
					bottom: 38px;
				}
			}
			&:nth-child(6){
				width: 61px;
				height: 61px;
				right: 91px;
				bottom: 113px;
				background: #dc6019;
				@media screen and (max-width:768px){
					width: 30px;
					height: 30px;
					right: 64px;
					bottom: 68px;
				}
			}
			&:nth-child(7){
				width: 82px;
				height: 82px;
				top: 0;
				left: 0;
				background: #d50424;
				@media screen and (max-width:768px){
					width: 41px;
					height: 41px;
				}
			}
		}
	}
	.kaza {
		padding-bottom: 55px;
		position: absolute;
		bottom: 0; right: 30px;
		z-index: 91;
		@media screen and (max-width:768px){
			padding-bottom: 30px;
			right: 0;
		}
		.kuruma {
			max-width: 125px;
			height: auto;
			animation: spin 3.5s linear infinite;
			@media screen and (max-width:768px){
				max-width: 90px;
			}
		}
		&:before {
			content: "";
			background: url("../../../images/common/illust_kazaguruma_b.png") no-repeat;
			background-size: 4px 84px;
			width: 4px;
			height: 84px;
			position: absolute;
			bottom: 0; left: 50%;
			margin-left: -3px;
			@media screen and (max-width:768px){
				height: 54px;
			}
		}
	}
	dl {
		padding: 88px 0;
		width: 30%;
		margin: 0 auto;
		@media screen and (max-width:768px){
			width: 100%;
			padding: 32px 12px;
		}
		dt {
			font-size: 20px;
			font-weight: 700;
			padding: 0 0 18px;
		}
		dd {
			font-size: 12px;
		}
	}
	
	.fnav {
		background: darken($blue,8%);
		position: relative;
		@media screen and (max-width:768px){
			padding: 12px 12px 0;
		}
		.piyo{
			$scale : .5;
			width: 80px;
			height: 90px;
			position: absolute;
			top: -88px;
			left: 100%;
			z-index: 90;
			transform-origin: 50% bottom;
			transform: scale($scale);
			animation: walk 12s linear infinite;
			@keyframes walk {
				0% {
					left: 100%;
				}
				49% {
					left: -80px;
					transform: rotateY(0deg) scale($scale);
				}
				50% {
					transform: rotateY(180deg) scale($scale);
				}
				51% {
					left: -80px;
				}
				99% {
					left: 100%;
					transform: rotateY(180deg) scale($scale);
				}
				100% {
					transform: rotateY(-0deg) scale($scale);
				}
			}
			&:before,
			&:after{
				content: '';
				position: absolute;
				width: 13px;
				height: 38px;
				background: url(../../images/common/piyo_back.png) no-repeat;
				transform-origin: top center;
				z-index: 1;
			}
			&:before{
				left: 44px;
				bottom: 0;
				animation: foot-a .2s linear infinite;
				@keyframes foot-a {
					0% {
						bottom: 2px;
						transform: rotate(10deg);
					}
					25% {
						bottom: 0;
						transform: rotate(0deg);
					}
					50% {
						bottom: 2px;
						transform: rotate(-10deg);
					}
					75% {
						bottom: 0;
						transform: rotate(0deg);
					}
					100% {
						bottom: 2px;
						transform: rotate(10deg);
					}
				}
			}
			&:after{
				left: 28px;
				bottom: 1px;
				animation: foot-b .2s linear infinite;
				@keyframes foot-b {
					0% {
						bottom: 1px;
						transform: rotate(-10deg);
					}
					25% {
						bottom: 3px;
						transform: rotate(0deg);
					}
					50% {
						bottom: 1px;
						transform: rotate(10deg);
					}
					75% {
						bottom: 3px;
						transform: rotate(0deg);
					}
					100% {
						bottom: 1px;
						transform: rotate(-10deg);
					}
				}

			}
			.piyo-body{
				background: url(../../images/common/piyo_body.png) no-repeat;
				width: 80px;
				height: 76px;
				z-index: 2;
				position: absolute;
				top: 0;
				left: 0;
				animation: body .1s linear alternate infinite;
				@keyframes body {
					0% {
						top: 0px;
					}
					100% {
						top: 4px;
					}
				}

			}
		}

		ul {
			padding: 16px 0;
			text-align: center;
			@media screen and (max-width:768px){
				background: #FFF;
				border-radius: 8px;
				padding: 0;
			}
			>li {
				display: inline-block;
				@media screen and (max-width:768px){
					display: block;
					border-bottom: 1px solid $border;
					&:last-child {
						border-bottom: none;
					}
				}
				a {
					color: #FFF;
					font-size: 12px;
					@media screen and (max-width:768px){
						color: $normal;
						display: block;
						padding: 12px;
						text-align: center;
					}
				}
				&:after {
					content: "/";
					display: inline-block;
					padding: 0 20px;
					@media screen and (max-width:768px){
						content: none;
					}
				}
				&:last-child {
					&:after {
						content: none;
					}
				}
			}
		}
		.copy {
			display: block;
			font-size: 9px;
			text-align: center;
			padding: 0 0 15px;
			@media screen and (max-width:768px){
				padding: 12px 0;
			}
		}
	}
	&.lp {
		background: #1b1b1b;
		text-align: center;
		@include font;
		&:after {
			content: none;
		}
		.pageup{
			background: #313131;
			padding: 16px 0;
			text-align: center;
			color: #fff;
			display: block;
			@include transition;
		}
		dl {
			dt {
				color: #FFF;
				font-size: 18px;
				padding: 0;
				span {
					font-size: 14px;
				}
				small {
					font-size: 12px;
				}
			}
			dd {
				padding: 12px 0;
				figure {
					img {
						width: 56px;
						height: auto;
					}
				}
			}
		}
		.copy {
			display: block;
			font-size: 9px;
			padding: 0 0 52px;
		}
	}
}


@-webkit-keyframes spin {
	0% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(360deg);}
}
@-moz-keyframes spin {
	0% {-moz-transform: rotate(0deg);}
	100% {-moz-transform: rotate(360deg);}
}
@-ms-keyframes spin {
	0% {-ms-transform: rotate(0deg);}
	100% {-ms-transform: rotate(360deg);}
}
@-o-keyframes spin {
	0% {-o-transform: rotate(0deg);}
	100% {-o-transform: rotate(360deg);}
}
@keyframes spin {
	0% {transform: rotate(0deg);}
	100% {transform: rotate(360deg);}
}