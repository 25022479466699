.ABOUTUS {
	&.first {
		.msg {
			width: 70%;
			margin: 0 auto;
			@media screen and (max-width:768px){
				width: 100%;
			}
			p {
				color: #FFF;
				margin: 0 0 24px;
			}
			dl {
				text-align: right;
				color: #FFF;
				dt {
					font-size: 16px;
					@media screen and (max-width:768px){
						font-size: 14px;
					}
				}
				dd {
					font-size: 24px;
					@media screen and (max-width:768px){
						font-size: 18px;
					}
				}
			}
			
		}
		
		.msg-L{
			position: relative;
			color: #FFF;
			padding: 0 0 10px;
			.syu{
				float: right;
				@media screen and (max-width:768px){
					float: none;
					text-align: center;
					margin: 24px 0 0;
				}
				img{
					max-width: 100%;
					@media screen and (max-width:768px){
						width: 72%;	
					}
				}
			}
			.text{
				width: 43%;
				text-align: justify;
                float: left;
				@media screen and (max-width:768px){
                    float: none;
					width: auto;	
				}
				p{
					margin: 0 0 24px;
					text-align: justify;
					font-size: 15px;
					@media screen and (max-width:768px){
						font-size: 14.5px;
						margin: 0 8px 20px;
					}
					&:last-of-type{
						margin: 0;
					}
				}
			}
			.name{
				position: absolute;
				right: 0;
				bottom: 0;
				font-size: 32px;
				text-align: right;
				line-height: 1;
				@media screen and (max-width:768px){
					position: relative;
					font-size: 24px;
					margin: 16px 0 0;
				}
				dt{
					font-size: 17px;
					margin: 0 0 10px;
					@media screen and (max-width:768px){
						font-size: 15px;	
					}
				}
			}
		}
	}
	
	&.second {
		dl {
			margin: 0 0 12px;
			background: #FFF;
			@include flex;
			dt {
				width: 20%;
				padding: 25px;
				background: $link;
				color: #FFF;
				text-align: center;
				position: relative;
				&:before {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 12px 12px 0 0;
					border-color: #ffffff transparent transparent transparent;
					position: absolute;
					top: 1px; left: 1px;
				}
			}
			dd {
				width: 80%;
				padding: 25px;
				.gmap,
				.map{
					margin: 24px 0 0;
					iframe{
						width: 100%;
						height: 300px;
						vertical-align: bottom;
						@media screen and (max-width:768px){
							height: 200px;	
						}
					}
				}
				p {
					margin: 0 0 16px;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
				ul {
					>li {
						list-style: none;
						float: left;
						margin: 0 20px 0 0;
						@media screen and (max-width:768px){
							float: none;
							margin: 0 0 8px;
						}
						&:last-child{
							margin: 0;
						}
						a{
							@include flex();
							align-items: center;
							justify-content: flex-start;
							color: $normal;
							img{
								width: auto;
								height: 28px;
							}
							span{
								display: inline-block;
								margin: 0 0 0 6px;
							}
						}
					}
				}
			}
			&.skel {
				background: #f9f9f9;
				>dt {
					background: #eed0ca;
					color: $normal;
					&:before {
						content: none;
					}
				}
			}
			&.no-bottom {
				margin-bottom: 0;
			}
		}
	}
	
	&.third {
		.btn {
			@include flex;
			align-items: center;
			width: 697px;
			height: 123px;
			background: url("../../../images/aboutus/btn_01.png") no-repeat;
			box-shadow: 0px 0px 28px 0.58px rgba(0, 0, 0, 0.17);
			margin: 0 auto;
			@media screen and (max-width:768px){
				width: 100%;
				height: auto;
				padding: 25px;
			}
			span {
				color: #FFF;
				background: url("../../../images/common/arw_02_2x.png") no-repeat right 32px center;
				background-size: 19px 6px;
				display: block;
				width: 100%;
				text-align: right;
				padding: 0 84px 0 0;
				font-size: 20px;
				@media screen and (max-width:768px){
					font-size: 14px;
				}
			}
		}
	}
}