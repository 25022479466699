.BLOG{
	.wrap{
		padding: 88px 0 56px;
		position: relative;
		@media screen and (max-width:768px){
			padding: 56px 15px 32px;
		}
		&:before{
			content: '';
			width: 100%;
			height: 36px;
			position: absolute;
			top: 0;
			left: 0;
			background: $blue;
		}
	}
    h2,h3,h4{
        &.borders{
            border-bottom: rgba(#000,.1) 1px solid;
            position: relative;
            padding: 12px;
            padding-left: 20px;
            font-size: 24px;
            line-height: 1;
            font-weight: 700;
            margin: 0 0 24px;
			@media screen and (max-width:768px){
				font-size: 17px;
				padding-left: 16px;
				margin: 0 0 16px;
			}
            &:after{
                content: '';
                width: 5px;
                height: 60%;
                background: $blue;
                border-radius: 4px;
                display: block;
                position: absolute;
                top: 20%;
                left: 0;
            }
        }
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 12px;
		}
    }

	.flex{
		@include flex();
		@media screen and (max-width:768px){
			display: block;
		}
	}
	.container{
		width: calc(100% - 360px);
		@media screen and (max-width:768px){
			width: auto;	
		}
	}
	.sidebar{
		width: 360px;
		padding: 0 0 0 64px;
		@media screen and (max-width:768px){
			width: auto;
			padding: 32px 0 0;
		}
		&__box{
			margin: 0 0 56px;
			@media screen and (max-width:768px){
				margin: 0 0 32px;	
			}
			&:last-of-type{
				margin: 0;
			}
		}
	}
	.bloglist{
		> li{
			@include flex();
			margin: 0 0 32px;
			padding: 0 0 32px;
			border-bottom: $border 1px solid;
			a{
				@include flex();
				width: 100%;
				color: $normal;
				position: relative;
			}
			figure{
				width: 220px;
				height: 140px;
				@media screen and (max-width:768px){
					width: 120px;
					height: 80px;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			dl{
				width: calc(100% - 220px);
				padding: 0 0 0 32px;
				@media screen and (max-width:768px){
					width: calc(100% - 120px);
					padding: 0 0 0 15px;
				}
				.meta{
					@include flex();
					align-items: center;
					justify-content: flex-start;
					font-size: 13px;
					.category{
						@include flex();
						align-items: center;
						justify-content: flex-start;
						@media screen and (max-width:768px){
							margin: 6px 0 0;	
						}
					}
					time,
					li{
						background: $blue;
						color: #FFF;
						padding: 2px 0;
						display: inline-block;
						margin: 0 10px 0 0;
						width: 120px;
						text-align: center;
						@media screen and (max-width:768px){
							width: 80px;
							font-size: 10px;
						}
					}
					li{
						background: #f2f2f2;
						color: $normal;
						padding: 2px 16px;
						width: auto;
						@media screen and (max-width:768px){
							font-size: 10px;	
						}
					}
				}
				dt{
					font-size: 20px;
					font-weight: 700;
					margin: 10px 0;
					@media screen and (max-width:768px){
						font-size: 15px;
						margin: 6px 0 0;
					}
				}
				dd.desc{
					text-align: justify;
					font-size: 15px;
					line-height: 1.66;
					@media screen and (max-width:768px){
						display: none;
					}
				}
			}
		}
        &.mini{
            > li{
				margin: 0 0 20px;
				padding: 0 0 20px;
				@media screen and (max-width:768px){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                a{
                    figure{
                        width: 80px;
						height: 50px;
                    }
                    dl{
                        width: calc(100% - 80px);
						padding: 0 0 0 20px;
						.meta{
							font-size: 10px;
						}
						dt{
							font-size: 14px;
							margin: 4px 0 0;
						}
                    }
                }
            }
        }
	}
	.newslist{
		> li{
			margin: 0 0 32px;
			padding: 0 0 32px;
			border-bottom: $border 1px solid;
			@media screen and (max-width:768px){
				margin: 0 0 16px;
				padding: 0 0 16px;
			}
			a{
				color: $normal;
				position: relative;
				padding: 0 32px 0 0;
				display: block;
				&:hover{
					opacity: .64;
				}
				&:after{
					content: "\f061";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					font-size: 11px;
				}
			}
			dl{
				@include flex();
				@media screen and (max-width:768px){
					align-items: flex-start;	
				}
				dd{
					border: $normal 1px solid;
					padding: 2px 0;
					display: inline-block;
					width: 120px;
					text-align: center;
					@media screen and (max-width:768px){
						width: 80px;	
						font-size: 10px;
						padding: 3px 0;
					}
				}
				dt{
					font-weight: 700;
					font-size: 16px;
					width: calc(100% - 120px);
					padding-left: 24px;
					@media screen and (max-width:768px){
						width: calc(100% - 80px);
						padding-left: 15px;
						font-size: 14px;
						line-height: 1.5;
					}
				}
			}
		}

	}
    .categorylist{
		transform: translateY(-15px);
        li{
			border-bottom: $border 1px solid;
            a{
                padding: 15px 4px;
                padding-right: 32px;
				display: block;
				color: $normal;
				position: relative;
				dl{
					dd{
						border: $normal 1px solid;
						padding: 1px 0;
						display: inline-block;
						width: 120px;
						text-align: center;
						margin: 0 0 4px;
					}
					dt{
						font-weight: 700;
						font-size: 14px;
					}
				}

				&:hover{
					opacity: .64;
				}
				&:after{
					content: "\f061";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					font-size: 11px;
				}
            }
        }
    }
	
	
	//SINGLE
	.single{
		&__title{
			border: $border 1px solid;
			padding: 24px;
			position: relative;
			@media screen and (max-width:768px){
				padding: 15px;	
			}
			&:before{
				content: '';
				width: calc(100% + 1px);
				height: 5px;
				background: $blue;
				position: absolute;
				top: -1px;
				left: -1px;
			}
			h2{
				font-weight: 700;
				font-size: 24px;
				line-height: 1.66;
				margin: 0 0 12px;
				@media screen and (max-width:768px){
					font-size: 16px;
					padding: 0 40px 0 0;
				}
			}
		}
		&__meta{
			@include flex();
			align-items: center;
			justify-content: flex-start;
			font-size: 13px;
			.category{
				@include flex();
				align-items: center;
				justify-content: flex-start;
			}
			time,
			li{
				border: $normal 1px solid;
				padding: 2px 16px;
				display: inline-block;
				margin: 0 10px 0 0;
				@media screen and (max-width:768px){
					font-size: 10px;	
				}
			}
			li{
				background: #f2f2f2;
				border-color: #f2f2f2;
			}
		}
		&__catch{
			margin: 24px 0 0;
			img{
				width: 100%;
			}
		}
		.index{
			background: darken(#f2f2f2, 1%);
			border-radius: 2px;
			padding: 24px;
			margin: 24px 0 0;
			@media screen and (max-width:768px){
				padding: 15px;	
			}
			h3{
				font-size: 20px;
				font-weight: 700;
				line-height: 1;
				padding: 0 0 16px;
				margin: 0 0 16px;
				border-bottom: rgba($normal,.08) 1px solid;
				@media screen and (max-width:768px){
					font-size: 16px;	
				}
				i{
					display: inline-block;
					margin: 0 12px 0 0;
				}
			}
			ol{
				li{
					list-style: inside decimal;
					font-size: 16px;
					margin: 0 0 4px;
					@media screen and (max-width:768px){
						font-size: 14px;	
					}
					&:last-child{
						margin: 0;
					}
					a{
						color: $normal;
						text-decoration: underline;
					}
				}
			}
		}
	}
	.pager{
		@include flex();
		align-items: center;
		justify-content: center;
		margin: 32px 0 0;
		a,
		span{
			width: 36px;
			height: 36px;
			background: #f2f2f2;
			color: $normal;
			@include flex();
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			margin: 0 6px;
		}
		a{
			&:hover{
				background: #FFF;
				box-shadow: inset 0 8px 32px rgba($normal,.24);
			}
		}
		span{
			color: #FFF;
			background: $blue;
		}
	}

}