@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,700;1,400&display=swap');


.site-lp{
	$fcolor: #323436;
	$fglay: #e8f1f5;
	$fborder: #e0e4e6;
	$fblue: #003f98;
	$fyellow: #fbf10c;
	$ease: cubic-bezier(0.85, 0, 0.15, 1);
	
	font-family: 'Noto Sans JP', "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
	font-weight:400;
	-webkit-font-feature-settings : "palt";
	font-feature-settings : "palt";
	letter-spacing:0.024em;
	color: $fcolor;
	line-height: 1.92;
	
	@media screen and (max-width:768px){
		font-size: 13.5px;	
	}
	.lpart{
		position: relative;
		padding: 100vh 0 0;
	}
	
	.lphead{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
		padding: 30px 60px;
		@include flex();
		align-items: center;
		@media screen and (max-width:768px){
			padding: 15px;	
		}
		&__logo{
			height: 36px;
			transition: opacity 200ms;
			@media screen and (max-width:768px){
				height: 32px;	
			}
			&.active{
				opacity: 0;
			}
			img{
				height: 100%;
			}
		}
		&__menu{
			.open{
				width: 42px;
				height: 42px;
				background: #FFF;
				border-radius: 50%;
				display: block;
				box-shadow: 0 0 0 8px rgba(#FFF,.32);
				animation: wave 2000ms linear infinite;
				position: relative;
				cursor: pointer;
				transition: transform 200ms;
				&:hover{
					transform: scale(1.1);
				}
				@keyframes wave{
					0%{
						box-shadow: 0 0 0 0 rgba(#FFF,0);
					}
					70%{
						box-shadow: 0 0 0 8px rgba(#FFF,.32);
					}
					100%{
						box-shadow: 0 0 0 12px rgba(#FFF,0);
					}
				}
				&:before,
				&:after{
					content: '';
					width: 16px;
					height: 2px;
					background: $fblue;
					position: absolute;
					left: 50%;
					top: calc(50% - 3.5px);
					transform: translateX(-50%);
					border-radius: 999px;
				}
				&:after{
					top: auto;
					bottom: calc(50% - 3.5px);
				}
			}
		}
	}
	.globalmenu{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		background: #FFF;
		@include flex();
		align-items: center;
		justify-content: center;
		visibility: hidden;
		&.active{
			visibility: visible;
		}
		&__close{
			position: absolute;
            width: 42px;
            height: 42px;
			right: 60px;
			top: 30px;
			z-index: 2;
			cursor: pointer;
			@media screen and (max-width:768px){
				right: 15px;
				top: 15px;
			}
			&:before,
			&:after{
				content: '';
				width: 36px;
				height: 1px;
				background: $fcolor;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
				transition: transform 200ms;
			}
			&:after{
				transform: translateX(-50%) translateY(-50%) rotate(-45deg);
			}
			&:hover{
				&:before,
				&:after{
					transform: translateX(-50%) translateY(-50%) rotate(0deg);
				}
			}
		}
		&__list{
			text-align: center;
			li{
				font-size: 17px;
				margin: 0 0 6vh;
				@media screen and (max-width:768px){
					font-size: 15px;	
				}
				&:last-child{
					margin: 0;
				}
				a{
					color: $fcolor;
					line-height: 1;
					display: block;
					position: relative;
					&:before{
						content: attr(data-eng);
						font-size: 26px;
						font-weight: 700;
						letter-spacing: .06em;
						display: block;
						margin: 0 0 6px;
						@media screen and (max-width:768px){
							font-size: 20px;	
						}
					}
					&:after{
						content: '';
						width: 0;
						height: 8px;
						border-radius: 4px;
						top: 50%;
						left: 50%;
						background: $fyellow;
						position: absolute;
						transform: translateY(-50%);
						z-index: -1;
						transition: width 200ms $ease, left 200ms $ease;
					}
					&:hover{
						&:after{
							width: 120%;
							left: -10%;
						}
					}
				}
			}
		}
	}
	.fv{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		@include flex();
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
		&:before{
			content: '';
			width: 100%;
			height: 100px;
			position: absolute;
			top: 0;
			left: 0;
			opacity: .24;
			background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.99) 1%, rgba(0,0,0,0) 100%);
			background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.99) 1%,rgba(0,0,0,0) 100%);
			background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.99) 1%,rgba(0,0,0,0) 100%);
			z-index: 2;
		}
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			z-index: 1;
			transform: scale(1.1);
            animation: bg 4000ms forwards;
            @keyframes bg{
                0%{
                    transform: scale(1.04);
                }
                100%{
                    transform: scale(1);
                }
            }
		}
		&__content{
			margin: 0 0 0 9.6vw;
			transform: translateY(-4%);
			position: relative;
			z-index: 2;
			color: #FFF;
			.eng,
			h1{
				opacity: 0;
				transform: translateY(20px);
			}
			.eng{
				font-family: 'Noto Serif', serif;
				font-weight: 700;
				letter-spacing: 0em;
				font-style: italic;
				line-height: 1.24;
				font-size: 88px;
				text-shadow: 0 0 32px rgba(#000,.24), 0 0 32px rgba(#000,.08);
				transition: opacity 800ms 400ms linear, transform 800ms 400ms ease-in;
				@media screen and (max-width:768px){
					font-size: 40px;	
				}
				.indent{
					text-indent: -.04em;
					display: inline-block;
					letter-spacing: .012em;
				}
			}
			h1{
				font-weight: 700;
				font-size: 18px;
				letter-spacing: .2em;
				margin: 24px 0 0;
				padding: 0 0 3px;
				border-bottom: #FFF 3px solid;
				display: inline-block;
				transform: translateY(10px);
				transition: opacity 800ms 600ms linear, transform 800ms 600ms ease-in;
				@media screen and (max-width:768px){
					font-size: 15px;
					margin: 16px 0 0;
				}
			}
		}
		&__copy{
			position: absolute;
			left: 60px;
			bottom: 30px;
			color: #FFF;
			font-size: 10px;
			letter-spacing: .08em;
			z-index: 2;
			@media screen and (max-width:768px){
				left: 15px;
				bottom: 15px;
			}
		}
		&__scroll{
            -moz-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            white-space: nowrap;
            font-size: 10px;
            letter-spacing: .24em;
            position: absolute;
            bottom: 0;
            right: 60px;
            line-height: 1;
            padding: 0 16px 52px 0;
			color: #FFF;
			z-index: 2;
			text-align: right;
			$size: 104px;
			@media screen and (max-width:768px){
				right: 15px;	
			}
            &:before,
            &:after{
                content: '';
                width: 1px;
                height: $size;
                background: #FFF;
                position: absolute;
                right: 0;
                bottom: 0;
                transform: translateX(-50%);
            }
            &:before{
                opacity: .64;
            }
            &:after{
                animation: sc 1600ms infinite;
                @keyframes sc{
                    0%{
                        height: $size;
                        bottom: 0;
                    }
                    33%{
                        height: 0;
                        bottom: 0;
                    }
                    65%{
                        height: 0;
                        bottom: $size;
                    }
                    66%{
                        height: 0;
                        bottom: $size;
                    }
                    100%{
                        height: $size;
                        bottom: 0;
                    }
                }
            }
		}

		&.active{
			.fv__content__catch{
				.eng,
				h1{
					transform: translateY(0);
					opacity: 1;
				}
			}
		}
	}
	
	// CONTENT AREA
	
	.lp-module{
		position: relative;
		z-index: 3;
		background: #FFF;
		&.wrap{
			padding: 88px 0;
			@media screen and (max-width:768px){
				padding: 40px 15px;	
			}
			&.no-top{
				padding-top: 0;
			}
			&.no-bottom{
				padding-bottom: 0;
			}
		}
		h2.large{
			text-align: center;
			font-weight: 700;
			margin: 0 0 28px;
			font-size: 17px;
			letter-spacing: .2em;
			@media screen and (max-width:768px){
				font-size: 15px;
				margin: 0 0 16px;
			}
			&:before{
				content: attr(data-eng);
				font-size: 42px;
				display: block;
				line-height: 1;
				margin: 0 0 6px;
				letter-spacing: .02em;
				@media screen and (max-width:768px){
					font-size: 28px;	
				}
			}
		}
		.marker{
			display: inline-block;
			background: linear-gradient(transparent 60%,$fyellow 60%);
			@media screen and (max-width:768px){
				background: none;	
			}
		}
		.tablestyle{
			border: $border 1px solid;
			border-collapse: collapse;
			width: 100%;
			font-size: 15.5px;
			@media screen and (max-width:768px){
				display: block;
				font-size: 13.5px;
			}
			tbody{
				@media screen and (max-width:768px){
					display: block;	
				}
				tr{
					border-bottom: $fborder 1px solid;
					@media screen and (max-width:768px){
						display: block;	
						&:last-child{
							border-bottom: none;
						}
					}
					th,
					td{
						padding: 24px;
						text-align: left;
						vertical-align: middle;
						@media screen and (max-width:768px){
							display: block;
							padding: 10px 15px;
						}
						a{
							color: $fblue;
							text-decoration: underline;
							font-weight: 700;
							i{
								display: inline-block;
								margin: 0 0 0 12px;
								font-size: 12px;
							}
						}
					}
					th{
						font-weight: 700;
						@media screen and (max-width:768px){
							border-bottom: $border 1px solid;
							background: $fglay;
						}
					}
					dl{
						margin: 0 0 24px;
						&:last-of-type{
							margin: 0;
						}
						dt{
							display: inline-block;
							width: 140px;
							background: $fblue;
							color: #FFF;
							text-align: center;
							border-radius: 2px;
						}
					}
				}
			}
		}
		.btnarea{
			text-align: center;
			margin: 32px 0 0;
			@media screen and (max-width:768px){
				margin: 24px 0 0;	
			}
			a{
				background: $fblue;
				border: $fblue 2px solid;
				padding: 16px 120px;
				display: inline-block;
				color: #FFF;
				font-size: 24px;
				font-weight: 700;
				border-radius: 999px;
				@include transition(200ms);
				@media screen and (max-width:768px){
					padding: 10px 0;
					display: block;
					font-size: 18px;
				}
				&:hover{
					background: #FFF;
					color: $fblue;
				}
				i{
					display: inline-block;
					margin: 0 12px 0 0;
				}
			}
		}
	}
	.first{
		overflow: hidden;
		position: relative;
		background: #FFF;
		&__bg{
			padding: 64px 0;
			position: relative;
			@media screen and (max-width:768px){
				padding: 0;	
			}
			&:before{
				content: '';
				width: 50%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: $fglay;
				z-index: -1;
				@media screen and (max-width:768px){
					top: -40px;
					left: -15px;
					width: calc(50% + 15px);
					height: 60vw;
				}
			}
			&__circle{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@media screen and (max-width:768px){
					display: none;
				}
				&:before,
				&:after{
					content: '';
					width: 120px;
					height: 120px;
					border-radius: 50%;
					background: $fglay;
					position: absolute;
				}
				&:before{
					right: 5%;
					top: -22px;
				}
				&:after{
					transform: scale(.64);
					right: 9.5%;
					top: 32px;
					opacity: .56;
					background: $fyellow;
				}
			}
		}
		&__clum{
			@include flex();
			align-items: center;
			@media screen and (max-width:768px){
				display: block;	
			}
			&__left{
				width: calc(56%);
				height: 470px;
				background-position: center;
				background-size: cover;
				box-shadow: 0 0 32px rgba(#000,.04);
				position: relative;
				@media screen and (max-width:768px){
					width: auto;
					height: 60vw;
				}
				img{
					display: none;
				}
			}
			&__right{
				width: calc(44%);
				@media screen and (max-width:768px){
					width: auto;
					padding: 24px 0 0;
				}
				&__box{
					max-width: 620px;
					padding: 0 64px;
					margin: 0 auto;
					@media screen and (max-width:768px){
						padding: 0;
						max-width: 100%;
					}
				}
				h2{
					font-size: 28px;
					letter-spacing: .08em;
					font-weight: 700;
					line-height: 1.5;
					@media screen and (max-width:768px){
						font-size: 20px;
						text-align: center;
					}
					&:before{
						content: attr(data-eng);
						color: $fblue;
						font-size: 14px;
						font-weight: 700;
						margin: 0 0 8px;
						display: block;
						letter-spacing: .12em;
					}
				}
				p.txt{
					margin: 16px 0 0;
					font-size: 15px;
					text-align: justify;
					@media screen and (max-width:768px){
						font-size: 13.5px;	
					}
				}
			}
		}
	}
	.second{
		background: lighten($fglay, 8%);
		position: relative;
		overflow: hidden;
		&__bg{
			content: '';
			position: absolute;
			top: -5%;
			left: -5%;
			width: 110%;
			height: 110%;
			z-index: 1;
			background: url(/"../../../images/sub/second_bg.jpg") center fixed;
			background-size: cover;
			opacity: .24;
			@media screen and (max-width:768px){
				background: url(/"../../../images/sub/second_bg.jpg") center;
				background-size: cover;
			}
		}
		.display{
			z-index: 2;
		}
		.context{
			text-align: center;
			font-size: 17px;
			font-weight: 700;
			opacity: .88;
			letter-spacing: .08em;
			margin: 0 0 16px;
			@media screen and (max-width:768px){
				font-size: 16px;	
			}
		}
		&__box{
			background: #FFF;
			padding: 48px;
			box-shadow: 0 0 32px rgba(#000,.08);
			position: relative;
			@include flex();
			@media screen and (max-width:768px){
				padding: 15px;
				display: block;
			}
			h3{
				width: 100%;
				color: $fblue;
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				margin: 0 0 24px;
				@media screen and (max-width:768px){
					font-size: 17px;	
				}
			}
			&__pic{
				width: 400px;
				@media screen and (max-width:768px){
					width: 100%;	
				}
				img{
					width: 100%;
				}
			}
			&__data{
				width: calc(100% - 400px);
				padding: 0 0 0 48px;
				@media screen and (max-width:768px){
					width: auto;
					padding: 15px 0 0;
				}
				dl{
					dt{
						background: $fblue;
						padding: 2px 24px;
						color: #FFF;
						display: inline-block;
						font-weight: 700;
						margin: 0 0 10px;
					}
					dd{
						margin: 2px 0 0;
						font-weight: 700;
						&:before{
							content: "\f00c";
							font-weight: 900;
							font-family: "Font Awesome 5 Free";
							display: inline-block;
							margin: 0 8px 0 0;
							font-size: 13px;
							color: $fblue;
						}
					}
				}
				.txt{
					margin: 15px 0 0;
					padding: 12px 0 0;
					border-top: $fborder 1px solid;
					font-size: 14.5px;
					@media screen and (max-width:768px){
						font-size: 13.5px;
						text-align: justify;
					}
				}
			}
		}
	}
	.third{
		background: url("../../../images/sub/texture.png")repeat;
		background-size: 17px;
		overflow: hidden;
		&__list{
			@include flex();
			position: relative;
			background: #FFF;
			padding: 40px 0;
			border: $fglay 2px solid;
			border-radius: 8px;
			@media screen and (max-width:768px){
				display: block;
				padding: 0;
				border-radius: 0;
			}
			&:before,
			&:after{
				content: '';
				width: 2px;
				height: 100%;
				background: $fglay;
				position: absolute;
				top: 0;
				@media screen and (max-width:768px){
					display: none;
				}
			}
			&:before{
				left: 33.3%;
			}
			&:after{
				right: 33.3%;
			}
			li{
				width: 33.3%;
				padding: 0 20px;
				position: relative;
				@media screen and (max-width:768px){
					width: auto;
					padding: 20px 15px;
					border-bottom: $fglay 2px solid;
					&:last-child{
						border: none;
					}
				}
				@for $i from 1 through 3{
					&:nth-child(#{$i}){
						&:before{
							content: '#{$i}';
							position: absolute;
							top: -32px;
							right: 10px;
							width: 40px;
							height: 40px;
							font-size: 17px;
							color: #FFF;
							background: $fblue;
							border-radius: 0 8px;
							@include flex();
							align-items: center;
							justify-content: center;
							z-index: 2;
							@media screen and (max-width:768px){
								top: 0;
								right: 0;
								border-radius: 0;
							}
						}
					}
				}
				i{
					width: 88px;
					height: 88px;
					background: lighten($fglay, 2%);
					border-radius: 50%;
					font-size: 32px;
					color: $fblue;
					display: block;
					text-align: center;
					margin: 0 auto 20px;
					@include flex();
					align-items: center;
					justify-content: center;
					position: relative;
					&:after{
						content: '';
						position: absolute;
						top: 5px;
						right: 5px;
						width: 16px;
						height: 16px;
						background: $fyellow;
						border-radius: 50%;
					}
				}
				dl{
					text-align: center;
					dt{
						text-align: center;
						display: inline-block;
						font-size: 18px;
						font-weight: 700;
						margin: 0 0 8px;
						position: relative;
					}
					dd{
						text-align: justify;
						font-size: 14.5px;
						margin: 0 15px;
					}
				}
			}
		}
	}
	.four{
		background: $fglay url("../../../images/sub/texture_w.png") repeat;
		background-size: 17px;
		&__list{
			li{
				background: #FFF;
				border-radius: 6px;
				padding: 24px 32px;
				margin: 0 0 20px;
				&:last-child{
					margin: 0;
				}
				dl{
					dt,dd{
						position: relative;
						padding: 0 0 0 40px;
						&:before{
							content: 'Q';
							position: absolute;
							top: -4px;
							left: 0;
							font-size: 20px;
							color: $fblue;
						}
					}
					dt{
						font-weight: 700;
						font-size: 17px;
						padding-bottom: 12px;
						margin: 0 0 12px;
						border-bottom: $fborder 1px dashed;
						@media screen and (max-width:768px){
							font-size: 15px;	
						}
					}
					dd{
						&:before{
							content: 'A';
							font-weight: 700;
							left: 2px;
							color: #c3a87b;
						}
					}
				}
			}
		}
	}
	.fifth{
		.twitter{
			margin: 40px 0 0;
			@include flex();
			align-items: center;
			border: $fglay 8px solid;
			padding: 24px;
			@media screen and (max-width:768px){
				margin: 24px 0 0;
				display: block;
				border-width: 2px;
				padding: 15px;
			}
			figure{
				width: 400px;
				@media screen and (max-width:768px){
					width: auto;	
				}
				img{
					width: 100%;
				}
			}
			dl{
				width: calc(100% - 400px);
				padding: 0 0 0 48px;
				@media screen and (max-width:768px){
					width: auto;
					padding: 16px 0 0;
				}
				dt{
					font-size: 16px;
					font-weight: 700;
					margin: 0 0 8px;
					@media screen and (max-width:768px){
						color: $fblue;	
					}
				}
				dd{
					font-size: 14px;
					@media screen and (max-width:768px){
						font-size: 13.5px;	
					}
				}
			}
		}
	}
	.six{
		iframe{
			vertical-align: bottom;
			height: 340px;
			filter: grayscale(1);
			width: 100%;
			@media screen and (max-width:768px){
				height: 200px;	
			}
		}
	}
	.lpfoot{
		background: $fblue;
		color: #FFF;
		position: relative;
		z-index: 10;
		padding: 0 0 48px;
		@media screen and (max-width:768px){
			padding: 0 0 24px;	
		}
		a{
			color: #FFF;
		}
		.pagetop{
			padding: 15px;
			display: block;
			text-align: center;
			background: darken($fblue, 4%);
		}
		&__menu{
			padding: 48px 0;
			@media screen and (max-width:768px){
				padding: 24px 15px;	
			}
			ul{
				@include flex();
				align-items: center;
				justify-content: center;
				@media screen and (max-width:768px){
					display: block;
					border: rgba(#FFF,.12) 1px solid;
					border-radius: 4px;
				}
				li{
					margin: 0 24px 0 0;
					padding: 0 24px 0 0;
					border-right: rgba(#FFF,.12) 1px solid;
					line-height: 1;
					@media screen and (max-width:768px){
						margin: 0;
						padding: 0;
						border: none;
						border-bottom: rgba(#FFF,.12) 1px solid;
						a{
							display: block;
							padding: 15px;
							position: relative;
							&:before{
								font-family: "Font Awesome 5 Free";
								font-weight: 900;
								content: "\f054";
								position: absolute;
								top: 50%;
								right: 15px;
								transform: translateY(-50%);
								font-size: 11px;
								opacity: .32;
							}
						}
					}
					&:last-child{
						margin: 0;
						padding: 0;
						border: none;
					}
				}
			}
		}
		.copy{
			padding: 0 15px;
			text-align: center;
			font-size: 9px;
			display: block;
		}
	}
	.scale-enter-active, .scale-leave-active {
		transition: 800ms $ease;
	}
	.scale-enter, .scale-leave-to {
		opacity: 0;
		transform: scale(1.04);
	}

}
