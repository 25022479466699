.RECRUIT {
	&.list {
		.flex {
			@include flex;
			>li {
				list-style: none;
				width: 49%;
				background: #FFF;
				padding: 15px;
				margin: 0 0 2%;
				border-radius: 10px;
				@include flex;
				align-items: center;
				@media screen and (max-width:768px){
					width: 100%;
					padding: 8px 12px;
				}
				span {
					width: 45%;
					font-size: 16px;
					font-weight: 700;
					padding-left: 10%;
					@media screen and (max-width:768px){
						padding-left: 0;
						font-size: 13px;
					}
				}
				a {
					width: 50%;
					display: block;
					border-radius: 6px;
					background: url("../../../images/common/arw_02_2x.png") no-repeat right 30px center #6c9dd3;
					background-size: 19px 6px;
					color: #FFF;
					padding: 8px;
					text-align: center;
				}
			}
		}
	}
	
	&.detail {
		.oneClum {
			>li {
				list-style: none;
				margin: 0 0 10px;
				.flex {
					@include flex;
					>dt {
						width: 20%;
						background: lighten($blue,65%);
						padding: 12px;
						text-align: center;
						@media screen and (max-width:768px){
							width: 100%;
							padding: 8px;
						}
					}
					>dd {
						width: 80%;
						background: #FFF;
						padding: 12px 24px;
						@media screen and (max-width:768px){
							width: 100%;
							padding: 8px 12px;
						}
						span {
							display: block;
							padding-left: 50px;
						}
					}
				}
			}
		}
	}
}
