.LP {
	@include font;
	h2 {
		margin: 0 0 42px;
		@media screen and (max-width:768px){
			margin: 0 0 24px;
			figure {
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
		&.center {
			text-align: center;
			font-family:"メイリオ", Meiryo,"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
			font-size: 46px;
			font-weight: 700;
			@media screen and (max-width:768px){
				font-size: 22px;
				figure {
					img {
						max-width: 100%;
						height: auto;
					}
				}
			}
			strong {
				font-size: 60px;
				@media screen and (max-width:768px){
					font-size: 28px;
				}
				&.line {
					position: relative;
					&:after {
						content: "";
						width: 100%;
						height: 9px;
						background: yellow;
						position: absolute;
						bottom: 17px; left: 0;
						z-index: -1;
						@media screen and (max-width:768px){
							bottom: 7px;
						}
					}
				}
			}
			&.w {
				color: #FFF;
			}
		}
	}
	a {
		display: inline-block;
		@media screen and (max-width:768px){
			display: block;
		}
		&.arw {
			background: url("../../images/lp/arw_01_2x.png") no-repeat right 23px center $orange;
			background-size: 9px 16px;
			color: #FFF;
			font-size: 20px;
			font-weight: 700;
			padding: 16px 82px;
			border-radius: 99px;
			@include transition;
			@media screen and (max-width:768px){
				padding: 12px;
				text-align: center;
				font-size: 15px;
			}
			&:hover {
				background-color: lighten($orange,10%);
			}
		}
	}
	&.view {
		width: 100%;
		height: 768px;
		background: url("../../../images/lp/firstview.jpg") no-repeat center;
		background-size: cover;
		@include flex;
		align-items: center;
		position: relative;
		@media screen and (max-width:768px){
			height: 460px;
		}
		&:before {
			content: "";
			background: linear-gradient(to top left, rgba(255,255,255,0) 50%, $sky 50.5%) no-repeat top left/100% 100%;
			width: 80%;
			height: 90px;
			position: absolute;
			top: 0; left: 0;
		}
		&:after {
			content: "";
			background: linear-gradient(to bottom right, rgba(255,255,255,0) 50%, $sky 50.5%) no-repeat top left/100% 100%;
			width: 100%;
			height: 115px;
			position: absolute;
			bottom: 0; right: 0;
		}
		.catch {
			position: relative;
			z-index: 10;
			@media screen and (max-width:768px){
				padding: 20% 12px 0;
			}
			>dt {
				figure {
					img {
						max-width: 774px;
						height: auto;
						@media screen and (max-width:768px){
							max-width: inherit;
							width: 100%;
							
						}
					}
				}
			}
			>dd {
				font-size: 18px;
				font-weight: 700;
				color: #FFF;
				&:first-of-type {
					padding: 42px 0;
					@media screen and (max-width:768px){
						padding: 22px 0;
					}
				}
				@media screen and (max-width:768px){
					font-size: 14px;
				}
				i {
					font-size: 32px;
					color: yellow;
					line-height: 1.4;
					@media screen and (max-width:768px){
						font-size: 26px;
					}
				}
			}
		}
		.contact {
			width: 40%;
			background: #FFF;
			border: 4px solid yellow;
			padding: 30px 45px;
			position: absolute;
			bottom: -45px; right: 0;
			z-index: 10;
			@media screen and (max-width:768px){
				display: none;
			}
			>dt {
				background: url("../../../images/lp/icon_tel.png") no-repeat left center;
				background-size: 54px;
				padding-left: 70px;
				margin: 0 0 12px;
				a.tel {
					display: block;
					color: $normal;
					@include opensans;
					font-size: 40px;
					font-weight: 700;
					line-height: 1.2;
				}
				span {
					display: block;
					font-size: 14px;
				}
			}
			>dd {
				a.line {
					display: block;
					background: url("../../../images/lp/icon_line.png") no-repeat left 20px center #00b900;
					font-size: 20px;
					font-weight: 700;
					color: #FFF;
					padding: 10px 24px 10px 85px;
				}
			}
		}
	}
	
	&.first{
		background: url("../../../images/lp/1st_back.png") no-repeat bottom center;
		background-size: cover;
		position: relative;
		.flex {
			@include flex;
			width: 100%;
			padding: 0 0 32px;
			position: relative;
			.list {
				width: 65%;
				background: #FFF;
				padding: 50px;
				@include flex;
				align-items: center;
				position: relative;
				@media screen and (max-width:768px){
					width: 100%;
					padding: 20px;
				}
				&:after {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 12.5px 0 12.5px 25px;
					border-color: transparent transparent transparent #ffffff;
					position: absolute;
					top: 50%; right: -25px;
					transform: translate(0,-50%);
					@media screen and (max-width:768px){
						content: none;
					}
				}
				>li {
					list-style: none;
					width: 46%;
					border-bottom: 1px solid #d6f0f3;
					font-size: 24px;
					font-weight: 700;
					background: url("../../../images/lp/icon_batu.png") no-repeat left center;
					background-size: 23px;
					padding:0 0 5px 32px;
					margin: 0 0 5px;
					@media screen and (max-width:768px){
						width: 100%;
						font-size: 18px;
					}
					span {
						color: #ff155d;
					}
				}
			}
			figure {
				width: 30%;
				@media screen and (max-width:768px){
					display: none;
				}
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
	} 
	
	&.second {
		position: relative;
		&:before {
			content: "";
			width: 100%;
			height: 49%;
			background: $sky;
			position: absolute;
			bottom: 0; left: 0;
			z-index: -1;
		}
		&:after {
			content: "";
			background: linear-gradient(to top left, rgba(255,255,255,0) 50%, $sky 50.5%) no-repeat top left/100% 100%;
			width: 100%;
			height: 80px;
			position: absolute;
			bottom: -80px; left: 0;
			@media screen and (max-width:768px){
				height: 40px;
				bottom: -40px;
			}
		}
		.flex {
			@include flex;
			>li {
				list-style: none;
				width: 32%;
				background: #FFF;
				box-shadow: 0 0 15px rgba(#000,.25);
				@media screen and (max-width:768px){
					width: 100%;
					margin: 0 0 2%;
				}
				figure {
					img {
						max-width: 100%;
						height: auto;
					}
				}
				dl {
					padding: 25px;
					position: relative;
					@media screen and (max-width:768px){
						padding: 20px;
					}
					span {
						background: url("../../../images/lp/2nd_illust_01.png") no-repeat;
						background-size: 94px;
						width: 94px; height: 94px;
						@include flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						line-height: 1.5;
						font-weight: 700;
						position: absolute;
						top: -47px; left: 8px;
					}
					dt {
						font-size: 36px;
						font-weight: 700;
						text-align: center;
						border-bottom: 1px solid $border;
						padding: 0 0 8px;
						margin: 0 0 16px;
						@media screen and (max-width:768px){
							font-size: 24px;
						}
					}
				}
			}
		}
		.btnArea {
			margin: 52px 0 0;
			text-align: center;
			@media screen and (max-width:768px){
				margin: 32px 0 0;
			}
		}
	}
	
	&.third {
		padding-top: 100px !important;
		position: relative;
		@media screen and (max-width:768px){
			padding-top: 50px !important;
		}
		&:before {
			content: "";
			background: url("../../../images/lp/3rd_step.png") no-repeat;
			width: 907px;
			height: 317px;
			position: absolute;
			top: 110px; left: 0;
			z-index: -2;
			@media screen and (max-width:768px){
				width: 100%;
				background-size: cover;
			}
		}
		&:after {
			content: "";
			background: url("../../../images/lp/3rd_illust_01.png") no-repeat;
			width: 412px; height: 412px;
			position: absolute;
			top: 3%; right: 0;
			@media screen and (max-width:768px){
				position: static;
				display: block;
				width: 350px;
				height: 350px;
				background-size: cover;
			}
		}
		.oneClum {
			>li {
				list-style: none;
				margin: 0 0 32px;
				@media screen and (max-width:768px){
					margin: 0 0 4%;
				}
				dl {
					position: relative;
					dt {
						width: 35%;
						color: #FFF;
						font-size: 36px;
						font-weight: 700;
						padding: 15px 40px;
						position: relative;
						@media screen and (max-width:768px){
							width: 100%;
							font-size: 24px;
							padding: 12px;
							background: $sky;
						}
						&:before {
							content: '';/*ボックス作成*/
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							left: 0;
							width: 100%;
							height: 100%;
							z-index: -1;
							transform: skew(-15deg);
							background: $sky;
							@media screen and (max-width:768px){
								content: none;
							}
						}
						span {
							display: block;
							@include opensans;
							font-size: 10px;
							font-weight: 700;
							border-bottom: 1px solid rgba($border,.25);
							padding: 0 0 8px;
							margin: 0 0 12px;
							@media screen and (max-width:768px){
								display: inline-block;
								margin: 0 16px 0 0;
								border-bottom: none;
								padding: 0;
							}
							i {
								display: block;
								color: $orange;
								font-size: 22px;
								line-height: 1.2;
							}
						}
					}
					dd {
						width: 75%;
						min-height: 160px;
						padding: 30px 50px;
						position: absolute;
						top: 20px; right: 0;
						z-index: 1;
						@media screen and (max-width:768px){
							width: 100%;
							position: static;
							padding: 15px;
						}
						&:before {
							content: '';/*ボックス作成*/
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							left: 0;
							width: 100%;
							height: 100%;
							z-index: -1;
							transform: skew(-15deg);
							background: #FFF;
							box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.22);
							@media screen and (max-width:768px){
								content: none;
							}
						}
					}
				}
			}
		}
	}
	
	&.four {
		position: relative;
		padding-bottom: 120px !important;
		&:before {
			content: "";
			background: url("../../../images/lp/4th_faq.png") no-repeat;
			width: 660px; height: 267px;
			position: absolute;
			top: 0; right: 0;
			z-index: -1;
			@media screen and (max-width:768px){
				width: 100%;
				height: 200px;
				background-size: cover;
			}
		}
		.faq {
			>li {
				list-style: none;
				margin: 0 0 24px;
				&:last-child {
					margin-bottom: 0;
				}
				dl{
					@include transition(120ms);
					&:hover{
						transform: scale(1.02);
						box-shadow: 0 4px 4px #DDD;
						border-radius: 99px;
						@media screen and (max-width:768px){
							border-radius: 0;
						}
						dt{
							background: #e5e5e5;
						}
					}
					dt{
						background: #fff3de;
						background-size: 24px 14px;
						font-size: 18px;
						font-weight: 700;
						position: relative;
						border-radius: 99px;
						cursor: pointer;
						@media screen and (max-width:768px){
							font-size: 15px;
						}
						&:before {
							content: "Q";
							width: 56px;
							height: 56px;
							display: inline-block;
							border-radius: 50%;
							font-size: 28px;
							text-align: center;
							padding-top: 3px;
							margin-right: 12px;
							background: $orange;
							color: #FFF;
							font-family: Meiryo, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "sans-serif";
							@media screen and (max-width:768px){
								font-size: 22px;
								padding-top: 8px;
							}
						}
						&:after {
							content: "";
							background: url("../../../images/lp/arw_faq.png") no-repeat 0 0;
							width: 24px;
							height: 14px;
							position: absolute;
							top: 50%; right: 24px;
							transform: translate(0,-50%);
							@media screen and (max-width:768px){
								width: 12px; height: 7px;
								background-size: cover;
							}
						}
						&.active {
							&:after {
								background-position: 0 -14px;
								@media screen and (max-width:768px){
									background-position: 0 -7px;
								}
							}
						}
					}
					dd{
						display: none;
						padding: 15px 15px 15px 35px;
						font-size: 16px;
						@media screen and (max-width:768px){
							padding: 15px;
							font-size: 12px;
						}
						&:before {
							content: "A";
							width: 35px;
							height: 35px;
							display: inline-block;
							border: 1px solid $orange;
							border-radius: 50%;
							font-size: 18px;
							font-weight: 700;
							text-align: center;
							padding-top: 3px;
							margin-right: 12px;
							color: $orange;
							font-family: Meiryo, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "sans-serif"
							
						}
					}
				}				
			}
		}
		.btnArea {
			margin: 52px 0 0;
			text-align: center;
			a.arw {
				background: url("../../../images/lp/arw_02_2x.png") no-repeat right 22px center $sky;
				background-size: 16px 9px;
				&:hover {
					opacity: .8;
				}
			}
		}
	}
	&.five {
		background: $sky;
		position: relative;
		&:before {
			content: "";
			background: linear-gradient(to bottom right, rgba(255,255,255,0) 50%, $sky 50.5%) no-repeat top left/100% 100%;
			width: 100%;
			height: 120px;
			position: absolute;
			top: -120px; left: 0;
		}
		&:after {
			content: "";
			background: url("../../../images/lp/5th_contct.png") no-repeat;
			background-size: cover;
			width: 100%;
			height: 390px;
			position: absolute;
			top: -115px; left: 0;
		}
		.display {
			z-index: 2;
		}
		.illust {
			position: absolute;
			top: -200px; left: 0;
			@media screen and (max-width:768px){
				top: -100px;
				left: -10px;
				img {
					max-width: 100px;
					height: auto;
				}
			}
		}
		.flex {
			@include flex;
			margin: 0 0 52px;
			@media screen and (max-width:768px){
				margin: 0;
			}
			dl {
				width: 48%;
				@media screen and (max-width:768px){
					width: 100%;
					margin: 0 0 4%;
				}
			}
		}
		.form {
			dl {
				dd {
					padding: 52px 52px 32px;
					@media screen and (max-width:768px){
						padding: 52px 25px 25px;
					}
				}
			}
			.btnArea {
				margin-top: 24px;
			}
			.tableStyle {
				th {
					&.hiss {
						position: relative;
						&:after {
							content: "必須";
							font-size: 12px;
							color: #FFF;
							background: #ff155c;
							display: inline-block;
							border-radius: 3px;
							padding: 0 8px;
							position: absolute;
							top: 50%; right: 0;
							transform: translate(0,-50%);
						}
					}
				}
				td {
					span.tel {
						background: none;
						padding: 0;
					}
				}
			}
			label {
				display: inline-block;
				margin-right: 18px;
			}
			input[type=text],
			input[type=tel],
			input[type=email],
			textarea {
				font-size: 15px;
				background: #f5f5f5;
				width: 100%;
				border: none;
				padding: 5px 10px;
				line-height: 1.8;
				box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
				@media screen and (max-width:768px){
					display: block;
					margin: 0 0 5px;
					width: 100%;
					background: none;
					border: 1px solid $border;
				}
				&.s {
					width: 30%;
					@media screen and (max-width:768px){
						display: inline-block;
						margin-right: 10px;
					}
				}
			}
			input[type=date] {
				width: 40%;
				background: #f5f5f5;
				padding: 5px 10px;
				border: none;				
			}
			select {
				width: 40%;
				background: #f5f5f5;
				padding: 5px 10px;
				border: none;
			}
			textarea {
				min-height: 100px;
			}
			input[type="submit"],input[type="reset"] {
				border: none;
				color: #ffffff;
				@include font;
				font-size: 18px;
				display: inline-block;
				padding: 15px 0;
				margin: 0 1%;
				width: 30%;
				border-radius: 99px;
				cursor: pointer;
				background: $orange;
				@include transition(200ms);
				&:hover{
					background: lighten($orange,10%);
				}
				@media screen and (max-width:768px){
					display: block;
					padding: 12px 0;
					margin: 0 0 10px;
					font-size: 17px;
					font-weight: 700;
					width: 100%;
					text-align: center;
				}
			}				
			
			
		}
		dl {
			background: #FFF;
			text-align: center;
			position: relative;
			z-index: 2;
			dt {
				background: yellow;
				font-size: 20px;
				font-weight: 700;
				padding: 12px 12px 0;
				position: relative;
				@media screen and (max-width:768px){
					font-size: 18px;
				}
				&:after {
					content: "";
					background:linear-gradient(to top right, rgba(255,255,255,0) 50%, yellow 50%) no-repeat top left/50% 100%,linear-gradient(to top left, rgba(255,255,255,0) 50%, yellow 50%) no-repeat top right/50% 100%;
					width: 100%;
					height: 21px;
					position: absolute;
					bottom: -21px; left: 0;
				}
			}
			dd {
				padding: 52px 12px 32px;
				@media screen and (max-width:768px){
					padding: 32px 12px 24px;
				}
				
				.line {
					background: url("../../../images/lp/icon_line.png") no-repeat left center;
					font-size: 30px;
					padding-left: 48px;
					@media screen and (max-width:768px){
						font-size: 22px;
						background-size: 28px;
					}
				}
				.tel {
					background: url("../../../images/lp/icon_tel.png") no-repeat left center;
					font-family: 'Open Sans', sans-serif;
					font-size: 40px;
					font-weight: 700;
					line-height: 1;
					padding-left: 65px;
					@media screen and (max-width:768px){
						background-size: 40px;
						font-size: 30px;
					}
					small {
						display: block;
						font-size: 14px;
						padding-right: 6%;
					}
				}
			}
		}
	}
	
}