.SERVICE {
	&.first {
		position: relative;
		&:before {
			content: "";
			background: url("../../../images/service/back_01.png") no-repeat;
			background-size: cover;
			width: 300px; height: 300px;
			position: absolute;
			top: 42px; left: 0;
		}
		.inBox {
			@media screen and (max-width:768px){
				background: rgba(#000,.65);
				padding: 25px;
				margin: 12px 0 32px;
			}
		}
		p {
			margin: 0 0 62px;
			font-size: 16px;
			@media screen and (max-width:768px){
				font-size: 13px;
				margin: 0;
				color: #FFF;
			}
		}
		.flex {
			@include flex;
			>li {
				list-style: none;
				width: 24%;
				background: url("../../../images/index/back_01.png") no-repeat center;
				background-size: cover;
				padding: 60px 0 0;
				@include flex();
				@for $i from 1 through 4{
					&:nth-child(#{$i}){
						background: url("../../../images/index/back_0#{$i}.png") no-repeat center;
						background-size: cover;
						dl {
							&:before {
								content: url("../../../images/service/icon_0#{$i}.png");
								position: absolute;
								top: -25px; left: 50%;
								transform: translate(-50%,0);
							}
						}
					}
				}
				@media screen and (max-width:768px){
					width: 100%;
					margin: 0 0 2%;
					&:last-child {
						margin-bottom: 0;
					}
				}
				dl {
					background: #FFF;
					padding: 60px 30px 50px;
					position: relative;
					width: 100%;
					@media screen and (max-width:768px){
						padding: 40px 25px 25px;
					}
					dt {
						text-align: center;
						color: $blue;
						font-size: 20px;
						font-weight: 700;
						border-bottom: 1px solid $border;
						padding: 0 0 8px;
						margin: 0 0 18px;
						@media screen and (max-width:768px){
							font-size: 18px;
						}
					}
					dd{
						font-size: 16px;
						text-align: justify;
						@media screen and (max-width:768px){
							font-size: 13px;	
						}
					}
				}
			}
		}
	}
	&.second {
		.flex {
			@include flex;
			>li {
				width: 496px;
				height: 504px;
				@include flex;
				justify-content: center;
				@media screen and (max-width:768px){
					width: 100%;
					height: auto;
					min-height: 342px;
					margin: 0 0 4%;
				}
				&:first-child {
					background: url("../../../images/service/sec_01.png") no-repeat center;
					@media screen and (max-width:768px){
						background-size: 350px 342px;
					}
					dl {
						dt {
							color: $blue;
						}
					}
				}
				&:last-child {
					background: url("../../../images/service/sec_02.png") no-repeat center;
					@media screen and (max-width:768px){
						background-size: 370px 361px;
					}
				}
				dl {
					width: 50%;
					padding-top: 25%;
					text-align: center;
					dt {
						font-size: 30px;
						font-weight: 700;
						margin: 0 0 24px;
						border-bottom: 1px solid $border;
						@media screen and (max-width:768px){
							font-size: 20px;
							line-height: 1.5;
							padding: 0 0 6px;
							margin: 0 0 12px;
						}
						&:before {
							content: attr(data-eng);
							font-size: 10px;
							display: block;
						}
					}
					dd {
						@media screen and (max-width:768px){
							font-size: 11px;
						}
					}
				}
			}
		}
	}
	&.third {
		@extend .first;
		&:before {
			content: "";
			background: url("../../../images/service/back_02.png") no-repeat;
			background-size: cover;
		}
		h3.left {
			color: $link;
			@media screen and (max-width:768px){
				color: #FFF;
			}
		}
		.flex {
			counter-reset: num;
			>li {
				width: 32%;
				background: #FFF;
				padding-top: 60px;
				@media screen and (max-width:768px){
					width: 100%;
					margin: 0 0 2%;
					padding-top: 20px;
				}
				dl {
					&:before {
						content: "plan" !important;
						color: $link;
						font-size: 13px;
						top: 12px !important;
					}
					&:after {
						counter-increment: num;
						content: counter(num);
						color: $link;
						font-size: 36px;
						font-weight: 700;
						position: absolute;
						top: 24px; left: 50%;
						transform: translate(-50%,0);
						@media screen and (max-width:768px){
							font-size: 28px;
						}
					}
					dt {
						color: $link;
						padding-top: 24px;
						@media screen and (max-width:768px){
							padding-top: 32px;	
						}
					}
				}
			}
		}
	}
	&.four {
		@extend .second;
		.flex {
			justify-content: center;
			>li {
				width: 574px;
				height: 550px;
				@media screen and (max-width:768px){
					width: 100%;
					height: auto;
					min-height: 335px;
				}
				&:first-child {
					background: url("../../../images/service/sec_03.png") no-repeat center;
					@media screen and (max-width:768px){
						background-size: 350px 335px;
					}
				}
				dl {
					dt {
						color: $link !important;
					}
				}
			}
		}
	}
}